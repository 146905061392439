import React, { useState } from 'react';
import { Grid, Paper, MenuItem, Button, FormControl, TextField, Stack } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CancelIcon from '@mui/icons-material/Cancel';
import { AppSMService } from '../../../network/useAxios';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '../../molecules/Alert/Alert';
import { BREAD_CRUMBS, ROUTING_CONSTANTS, TEXT_CONSTANTS, useDataValidation } from '../../../utils';
import { useAppSelector } from '../../../hooks';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { StudySpecialFormState } from './types';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
const StudySpecialForm = () => {
  const { HOME, STUDY_MANAGEMENT, SPECIAL_FORM } = BREAD_CRUMBS;
  const { DASHBOARD, STUDY: RSTUDY } = ROUTING_CONSTANTS;

  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { study, client } = appDataContext;
  const { checkIsEmpty, split } = useDataValidation();
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: STUDY_MANAGEMENT, link: `${RSTUDY}${client.id}/${study.id}` },
    { title: SPECIAL_FORM, link: '' }
  ];

  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SOMETHING_WENT_WRONG, SUCCESS },
      STUDY_PAGE: { SPECIAL_FORM_CREATE_SUCCESS, SPECIAL_FORM_UPDATE_SUCCESS }
    }
  } = TEXT_CONSTANTS;

  const [studySpecialForm, setStudySpecialFormState] = useState<StudySpecialFormState>({
    formList: [],
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: '',
    assignForm: [],
    selectedForm: [],
    selectedSpecialForm: [],
    specialFormID: ''
  });
  const {
    assignForm,
    formList,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    selectedForm,
    selectedSpecialForm,
    specialFormID
  } = studySpecialForm;

  const { FORMS, VISIT_FORM } = NETWORK_CONSTANTS.END_POINTS;
  React.useEffect(() => {
    loadSpecialForm();
    loadForm();
  }, []);
  const updateState = (data: any) => {
    setStudySpecialFormState((prevState: StudySpecialFormState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadForm = () => {
    AppSMService.axios
      .get(`${FORMS}?studyId=${study.id}&specialForm=true`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          updateState({ formList: data });
        }
      });
  };

  const loadSpecialForm = () => {
    AppSMService.axios
      .get(`${VISIT_FORM}/special-form?studyId=${study.id}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          const _updatedState = { specialFormID, assignForm, selectedSpecialForm };
          _updatedState.specialFormID = data.id;
          if (data?.forms?.length > 0) {
            _updatedState.specialFormID = data.forms;
            const updateAssignForm: any = data.forms.map((item: any) => {
              return `${item.formName}|${item.formId}|${item.logForm}`;
            });
            _updatedState.assignForm = updateAssignForm;
          }
          updateState(_updatedState);
        }
      });
  };
  const handleTextValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formIndex: number
  ) => {
    const value = event.target.value;

    updateTempConfig(value, formIndex);
  };

  const addTempConfig = () => {
    const updateForms: any = selectedSpecialForm.length > 0 ? selectedSpecialForm : [];
    const assign_form = assignForm;
    selectedForm.map((item: string) => {
      const splitForm = split('|', item);
      assign_form.push(item);
      updateForms.push({
        formId: splitForm[1],
        formName: splitForm[0],
        formOrder: '',
        logForm: splitForm[2]
      });
    });
    updateState({ selectedSpecialForm: updateForms, selectedForm: [], assignForm: assign_form });
  };
  const updateTempConfig = (value: string, formIndex: number) => {
    const updatedSpecialForms: any = [];
    if (selectedSpecialForm.length > 0) {
      selectedSpecialForm.map((item: string, index: number) => {
        const updForms: any = item;
        if (formIndex === index) {
          updForms['formOrder'] = value;
        }
        updatedSpecialForms.push(updForms);
      });
    }
    updateState({ selectedSpecialForm: updatedSpecialForms });
  };
  const removeForm = (formIndex: number) => {
    const updatedSpecialForms: any = [];
    const updateAssignForm: any = [];
    selectedSpecialForm.map((item: any, index: number) => {
      if (formIndex !== index) {
        updatedSpecialForms.push(item);
        updateAssignForm.push(item.item?.formName + '|' + item?.formId + '|' + item?.logForm);
      }
    });
    updateState({ selectedSpecialForm: updatedSpecialForms, assignForm: updateAssignForm });
  };
  const saveSpecialForm = async () => {
    const postData = {
      studyId: study.id,
      forms: selectedSpecialForm
    };
    let response: any = { status: HTTP_STATUS_CODE.OK };
    if (!checkIsEmpty(specialFormID)) {
      response = await AppSMService.axios.patch(
        `${VISIT_FORM}/special-form/${specialFormID}`,
        postData
      );
    } else {
      response = await AppSMService.axios.post(`visit-form/special-form`, postData);
    }
    if (HTTP_STATUS_CODE.OK === response.status) {
      updateState({
        openAlert: true,
        openAlertStatus: SUCCESS,
        openAlertMsg: !checkIsEmpty(specialFormID)
          ? SPECIAL_FORM_UPDATE_SUCCESS
          : SPECIAL_FORM_CREATE_SUCCESS
      });
    } else {
      updateState({
        openAlert: true,
        openAlertStatus: ERROR,
        openAlertMsg: SOMETHING_WENT_WRONG
      });
    }
    AutoCloseAlert();
  };

  const handleCloseAlert = () => {
    updateState({
      openAlert: false,
      openAlertStatus: '',
      openAlertMsg: ''
    });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert: false,
        openAlertStatus: '',
        openAlertMsg: ''
      });
    }, 3000);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2
          }}>
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="demo-simple-select-label">Form</InputLabel>
            <Select
              multiple
              value={selectedForm}
              onChange={(e) => updateState({ selectedForm: e.target.value })}
              input={<OutlinedInput label="Multiple Select" />}>
              {formList.map((item: any, index: number) => {
                if (assignForm.indexOf(item.name + '|' + item.id + '|' + item.logForm) < 0) {
                  return (
                    <MenuItem key={index} value={item.name + '|' + item.id + '|' + item.logForm}>
                      {item.name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: 2, minWidth: 100 }}>
            <Button variant="contained" onClick={addTempConfig}>
              Add
            </Button>
          </FormControl>
        </Paper>
        {selectedSpecialForm.length > 0 && (
          <>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column'
              }}>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 380,
                  bgcolor: 'background.paper'
                }}>
                {selectedSpecialForm.map((formItem: any, formIndex: number) => {
                  return (
                    <ListItem
                      sx={{
                        paddingLeft: '50px'
                      }}
                      key={formIndex}>
                      <ListItemText primary={formItem.formName} />
                      <TextField
                        label="Position"
                        variant="standard"
                        sx={{
                          width: 100
                        }}
                        value={formItem.formOrder}
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(event) => handleTextValueChange(event, formIndex)}
                      />
                      <CancelIcon
                        sx={{ marginTop: '25px', marginLeft: 1 }}
                        onClick={() => removeForm(formIndex)}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
            <Stack
              direction="row"
              alignItems="right"
              justifyContent="right"
              spacing={2}
              pt={4}
              pb={2}
              pr={5}>
              <Button variant="contained" onClick={saveSpecialForm}>
                Save
              </Button>
            </Stack>
          </>
        )}
      </Grid>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default StudySpecialForm;
