import { useRef, useEffect, useState, Fragment } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  TextField,
} from "@mui/material";

import { COMPARE_DATA_COLUMN, COMPARE_DATA_VISIBILITY } from "./ActionConfig";
import { DataGrid } from "@mui/x-data-grid";
import "./styles.css";
import AlertMessage from "../../molecules/Alert/Alert";
import Loader from "../../molecules/Loader/Loader";
import { AppUMService } from "../../../network/useAxios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CompareData({
  meta: { show, name, code },
  showModal,
}: any) {
  const [stateObj, setStateObject] = useState({
    open: show,
    fileExt: ".csv",
    progress: 0,
    fileObj: { name: "" },
    snackbar: {
      show: false,
      isError: false,
      message: "",
    },
    loader: {
      show: false,
    },
    comparedData: {
      value: "",
      data: [],
    },
  });
  const refHiddenFileInput: any = useRef(null);
  const refFileInput: any = useRef(null);
  useEffect(() => {
    setStateObject({
      ...stateObj,
      open: show,
      fileObj: { name: "" },
    });
  }, [show]);

  const handleClose = () => {
    setStateObject({
      ...stateObj,
      open: false,
    });
    showModal(code, false);
  };

  const onHandleSubmit = () => {
    const formData = new FormData();
    formData.append("file", stateObj.fileObj as any);
    formData.append("entity", "study");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    showLoader(true);
    AppUMService.axios
      .post("datatransition/compare", formData, config)
      .then(({ status }) => {
        console.log("Status ", status);

        handleErrorMessageUI("Data file comparison completed", false, true);
      })
      .finally(() => {
        setTimeout(() => {
          showLoader(false);
          handleClose();
        }, 2000);
      });
  };

  const onHandleBrowseFile = () => {
    if (
      refHiddenFileInput.current != undefined &&
      refHiddenFileInput.current.click != undefined
    ) {
      refHiddenFileInput.current.click();
    }
  };
  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (refFileInput.current) {
      refFileInput.current.focus();
    }
    setStateObject({
      ...stateObj,
      fileObj: fileObj,
    });
  };
  const handleErrorMessageUI = (
    message: string,
    isError: boolean,
    showSnackbar: boolean
  ) => {
    setStateObject({
      ...stateObj,
      snackbar: {
        ...stateObj.snackbar,
        message: message,
        isError: isError,
        show: showSnackbar,
      },
    });
  };
  const showLoader = (showLoader: boolean) => {
    setStateObject({
      ...stateObj,
      loader: {
        ...stateObj.loader,
        show: showLoader,
      },
    });
  };

  return (
    <Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={stateObj.open}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Container
            style={{ display: "flex", alignItems: "center", width: "80%" }}
          >
            <TextField
              type="text"
              value={stateObj.fileObj?.name}
              style={{ width: "80%" }}
              label="File Name"
              variant="outlined"
              autoFocus
              inputRef={refFileInput}
            />
            <TextField
              type="file"
              onChange={handleFileChange}
              inputRef={refHiddenFileInput}
              style={{ display: "none" }}
              inputProps={{ accept: stateObj.fileExt }}
            />

            <Button
              variant="contained"
              color="primary"
              component="span"
              onClick={onHandleBrowseFile}
              style={{ marginLeft: "30px" }}
            >
              Browse
            </Button>
          </Container>
          {/* {fileObj?.name && (
            <Box>
              <Typography variant="subtitle1">Comparing</Typography>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )} */}
          {stateObj.comparedData.data.length > 0 && (
            <DataGrid
              rows={stateObj.comparedData.data}
              columns={COMPARE_DATA_COLUMN}
              columnVisibilityModel={COMPARE_DATA_VISIBILITY}
              pagination={undefined}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
            />
          )}
          {stateObj.loader.show && (
            <Loader
              open={stateObj.loader.show}
              handleClose={() => showLoader(false)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onHandleSubmit}>
            Compare
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {stateObj.snackbar.show && (
        <AlertMessage
          openAlert={stateObj.snackbar.show}
          openAlertMsg={stateObj.snackbar.message}
          openAlertStatus={stateObj.snackbar.isError ? "error" : "success"}
          handleCloseAlert={() => handleErrorMessageUI("", false, false)}
        />
      )}
    </Fragment>
  );
}
