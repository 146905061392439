export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}
export enum NETWORK_TYPE {
  SM = 'SM',
  UM = 'UM'
}
export const NETWORK_CONSTANTS= {
  END_POINTS: {
    UM: 'https://um.medistatshytech.net/',
    SM: 'https://sm.medistatshytech.net/api/v1/',
    AUTH_TOKEN: 'auth/token',
    USER_ME: 'users/me',
    AUTH_FORGOT_PASSWORD: 'auth/forgotPassword',
    AUTH_REST_PASSWORD: 'auth/user444/resetPassword?token=',
    STUDY_STATS: `study/stats`,
    USERS: `users/`,
    USER_ACCESS: `useraccess`,
    PROJECT: 'project',
    STUDY: 'study',
    ACCESS_MANAGEMENTS: 'accessmanagements',
    ACCESS_RIGHTS: 'accessrights',
    ACCESS_RIGHTS_MANAGEMENTS_MAPPING: 'accessrightsmanagementsmapping',
    FORMS: 'forms',
    FIELDS: 'fields',
    VISIT_FORM: 'visit-form',
    META_DATA: 'metadata',
    SUBJECT: 'subject',
    STUDY_ROLES: 'studyroles',
    STUDY_ACCESS_RIGHTS: 'studyaccessrights',
    FORM_REVIEW:'form-review',
    QUERY:'query',
    DE_FORMS:"de-forms",
    QUERY_ASSIGN:"query/assign/",
    FIELD_REVIEW:'field-review',
    DE_FIELDS:"de-fields",
    FIELD_REVIEWS:"field-reviewss"
  }
};

export const HTTP_STATUS_CODE = {
  OK: 200,
  PROCESSING: 202
};
