import { GridColDef } from "@mui/x-data-grid";

export const PROJECT_GRID_COLUMN_CLIENT: GridColDef[] =  [
  {
    field: "id",
    headerName: "Project ID",
    width: 250,
    editable: false,
  },
  {
    field: "label",
    headerName: "Project Name",
    width: 250,
    editable: false,
  }]
