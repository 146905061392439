import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { configure, makeUseAxios, UseAxios } from "axios-hooks";
import { NETWORK_CONSTANTS } from "./networkConstants";
export interface IAppService {
  axios: AxiosInstance;
  axiosHooks: UseAxios;
}
const AppUMService: IAppService = {} as IAppService;
const AppSMService: IAppService = {} as IAppService;
const headers = { "Content-Type": "application/json" };
const axiosUmInstance = axios.create({
  baseURL: NETWORK_CONSTANTS.END_POINTS.UM,
  headers,
});
const requestHeadersConfig = async (config: InternalAxiosRequestConfig) => {
  if (config && config.headers) {
    config.headers["authorization"] = `Bearer ` + localStorage.getItem("token");
  }
  return config;
};
const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};
axiosUmInstance.interceptors.request.use(requestHeadersConfig);
axiosUmInstance.interceptors.response.use(onResponse, onResponseError);

const axiosSmInstance = axios.create({
  baseURL: NETWORK_CONSTANTS.END_POINTS.SM,
  headers,
});
axiosSmInstance.interceptors.request.use(requestHeadersConfig);
axiosSmInstance.interceptors.response.use(onResponse, onResponseError);

configure({ axios });
const axiosUmHooks = makeUseAxios({
  axios: axiosUmInstance,
});
const axiosSmHooks = makeUseAxios({
  axios: axiosSmInstance,
});
AppUMService.axios = axiosUmInstance;
AppUMService.axiosHooks = axiosUmHooks;
AppSMService.axios = axiosSmInstance;
AppSMService.axiosHooks = axiosSmHooks;
export { AppUMService, AppSMService };
