import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { IComment } from "../../../models/Common";

interface Iprops {
  comments: IComment[];
}

function Comments(props: Iprops) {
  return <>{props.comments.map((item: IComment, index) => {
    return (
      <Grid container spacing={2} ml={5} key={index}>
        {/* <Grid item xs={1}>
          <Avatar
            sx={{
              bgcolor: "#eaddff",
              color: "#21005d",
            }}
          >
            {item.type === "reviewer" ? "R" : "B"}
          </Avatar>
        </Grid> */}
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            {item.commentedBy}
          </Typography>
          <Typography>{item.comments}</Typography>
          <Typography
            sx={{ fontStyle: "italic" }}
            variant="caption"
            display="block"
            gutterBottom
          >
            {item.timestamp}
          </Typography>
        </Grid>
      </Grid>
    );
  })}</>;
}

export default Comments;
