import { Button, FormControl, Grid, Paper } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from 'react';
import { AppSMService } from '../../../network/useAxios';
import Alert from '../../molecules/Alert/Alert';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { BREAD_CRUMBS, ROUTING_CONSTANTS, TEXT_CONSTANTS, useDataValidation } from '../../../utils';
import { InitialStateTypes, updateStudyData } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { StudyDetailState } from './types';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
const Studyconfig = () => {
  const { HOME, STUDY_MANAGEMENT, DETAILS } = BREAD_CRUMBS;
  const { FORMS, STUDY, VISIT_FORM } = NETWORK_CONSTANTS.END_POINTS;
  const dispatch: any = useAppDispatch();
  const { split, checkIsEmpty } = useDataValidation();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { study, client } = appDataContext;
  const { DASHBOARD, STUDY: RCSTUDY } = ROUTING_CONSTANTS;
  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SUCCESS, SOMETHING_WENT_WRONG },
      STUDY_PAGE: { STUDY_UPDATE_SUCCESS, CONFIG_UPDATE_SUCCESS, CONFIG_CREATE_SUCCESS }
    }
  } = TEXT_CONSTANTS;
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: STUDY_MANAGEMENT, link: `${RCSTUDY}${client.id}/${study.id}` },
    { title: DETAILS, link: '' }
  ];
  const [studyDetailState, setStudyDetailState] = useState<StudyDetailState>({
    formList: [],
    visitList: [],
    options: [],
    visitForm: [],
    visitName: '',
    selectedForm: [],
    assignForm: [],
    visitFormID: '',
    visitposition: '',
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: ''
  });
  const {
    assignForm,
    formList,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    options,
    selectedForm,
    visitForm,
    visitFormID,
    visitList,
    visitName,
    visitposition
  } = studyDetailState;

  React.useEffect(() => {
    loadVisit();
    loadForm();
  }, []);
  React.useEffect(() => {
    loadVisitFormList();
  }, [visitName]);
  const updateState = (data: any) => {
    setStudyDetailState((prevState: StudyDetailState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadForm = () => {
    AppSMService.axios.get(`${FORMS}?studyId=${study.id}`).then(({ status, data }) => {
      if (status === HTTP_STATUS_CODE.OK) {
        updateState({ formList: data });
      }
    });
  };
  const loadVisit = () => {
    AppSMService.axios.get(`${STUDY}/${study.id}/config?type=VISIT`).then(({ status, data }) => {
      if (status === HTTP_STATUS_CODE.OK) {
        if (data.length > 0) {
          const keysList = Object.keys(data[0].data);
          const updatedVisit: any = [...data];
          updatedVisit.map((item: any) => {
            return { id: item.id, name: item.data[keysList[0]] };
          });
          updateState({ visitList: updatedVisit });
        }
      }
    });
  };

  const handleTextValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formIndex: number
  ) => {
    const { name, value } = event.target;

    if (name === 'visitposition') {
      updateState({ visitposition: value });
    } else {
      updateTempConfig(value, formIndex);
    }
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { value, name }
    } = event;
    if (name === 'form') {
      updateState({ options: typeof value === 'string' ? value.split(',') : value });
    } else {
      updateState({ visitName: value });
    }
  };
  const loadVisitFormList = () => {
    if (visitName !== '') {
      const splitVisitName = split('|', visitName);
      AppSMService.axios
        .get(`${VISIT_FORM}?visitId=${splitVisitName[0]}`)
        .then(({ status, data }) => {
          if (data.length > 0 && status === HTTP_STATUS_CODE.OK) {
            updateState({
              visitFormID: data[0].id,
              visitForm: [
                {
                  visitId: splitVisitName[0],
                  visitName: splitVisitName[1],
                  forms: data.forms
                }
              ],
              visitposition: data.order
            });

            const updateFormList: any = data.forms;
            if (data.forms.length > 0) {
              updateFormList.map((item: any) => {
                return `${item.formName}|${item.formId}|${item.logForm}`;
              });
            }
            updateState({
              visitFormID: data[0].id,
              visitForm: [
                {
                  visitId: splitVisitName[0],
                  visitName: splitVisitName[1],
                  forms: data.forms
                }
              ],
              visitposition: data.order,
              assignForm: data.forms.length > 0 ? updateFormList : assignForm
            });
          } else {
            updateState({
              visitFormID: '',
              visitForm: [],
              visitposition: '',
              assignForm: []
            });
          }
        })
        .catch(() => {
          updateState({
            visitFormID: '',
            visitForm: [],
            visitposition: '',
            assignForm: []
          });
        });
    }
  };
  const updateFreeze = () => {
    updateStudy('freeze');
  };
  const updateActive = () => {
    updateStudy('active');
  };
  const updateStudy = (type: any) => {
    let freeze = study.unfreeze;
    if (type === 'freeze') {
      freeze = !study.unfreeze;
    }
    let active = study.active;
    if (type === 'active') {
      active = !study.active;
    }

    AppSMService.axios
      .patch(`${STUDY}/${study.id}`, {
        name: study.name,
        reviewMinPercentage: study.reviewMinPercentage,
        reviewers: study.reviewers,
        unfreeze: freeze,
        active: active
      })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(
            updateStudyData({
              id: study.id,
              mode: study.moder,
              name: study.name,
              reviewers: study.reviewers,
              reviewMinPercentage: study.reviewMinPercentage,
              unfreeze: freeze,
              active: active
            })
          );
          updateState({
            openAlert: true,
            openAlertStatus: SUCCESS,
            openAlertMsg: STUDY_UPDATE_SUCCESS,
            options: []
          });
        } else {
          updateState({
            openAlert: true,
            openAlertStatus: ERROR,
            openAlertMsg: SOMETHING_WENT_WRONG
          });
        }
        AutoCloseAlert();
        // setBtnLoading(false);
      })
      .catch(() => {
        updateState({
          openAlert: true,
          openAlertStatus: ERROR,
          openAlertMsg: SOMETHING_WENT_WRONG
        });
        AutoCloseAlert();
      });
  };
  const updateTempConfig = (value: string, formIndex: number) => {
    const visitFormList = visitForm;
    const updatedForms: any = [];
    if (visitFormList.length > 0) {
      if (visitFormList[0].forms.length > 0) {
        visitFormList[0].forms.map((item: string, index: number) => {
          const updForms: any = item;
          if (formIndex === index) {
            updForms['formOrder'] = value;
          }
          updatedForms.push(updForms);
        });
      }
    }
    const splitVisitName = split('|', visitName);
    const visit_Form_List = [
      {
        visitId: splitVisitName[0],
        visitName: splitVisitName[1],
        forms: updatedForms
      }
    ];
    updateState({ visitForm: visit_Form_List });
  };

  const removeForm = (formIndex: number) => {
    const visitFormList = visitForm;
    const updatedForms: any = [];
    if (visitFormList.length > 0) {
      if (visitFormList[0].forms.length > 0) {
        visitFormList[0].forms.map((item: string, index: number) => {
          if (formIndex !== index) {
            updatedForms.push(item);
          }
        });
      }
    }
    const visit_Form_List = [
      {
        visitId: visitFormList[0].visitId,
        visitName: visitFormList[0].visitName,
        forms: updatedForms
      }
    ];
    updateState({ visitForm: visit_Form_List });
  };
  const saveVisitForm = async () => {
    const visitFormList = visitForm;
    const visitSplit = visitFormList[0].visitId;
    const postData = {
      studyId: study.id,
      visitId: visitSplit,
      order: visitposition,
      forms: visitFormList[0].forms
    };
    let response: any = { status: HTTP_STATUS_CODE.OK };
    if (!checkIsEmpty(visitFormID)) {
      response = await AppSMService.axios.patch(`${VISIT_FORM}/${visitFormID}`, postData);
    } else {
      response = await AppSMService.axios.post(`${VISIT_FORM}/`, postData);
    }

    updateState({
      openAlert: true,
      openAlertStatus: HTTP_STATUS_CODE.OK === response.status ? SUCCESS : ERROR,
      openAlertMsg:
        HTTP_STATUS_CODE.OK === response.status
          ? !checkIsEmpty(visitFormID)
            ? CONFIG_UPDATE_SUCCESS
            : CONFIG_CREATE_SUCCESS
          : SOMETHING_WENT_WRONG
    });
    AutoCloseAlert();
  };

  const handleCloseAlert = () => {
    updateState({
      openAlert: true,
      openAlertStatus: '',
      openAlertMsg: ''
    });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert: true,
        openAlertStatus: '',
        openAlertMsg: ''
      });
    }, 3000);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2
          }}>
          <List>
            <ListItem>
              <b>Study Name:</b> {study.name}
            </ListItem>
            <ListItem>
              <b>Review Min Percentage:</b>
              {study.reviewMinPercentage}
            </ListItem>
            <ListItem>
              <b>Reviewers:</b>
              {study.reviewers.length > 0 ? study.reviewers.join(',') : ''}
            </ListItem>
          </List>

          <FormControl sx={{ marginTop: 2, minWidth: 100 }}>
            <Button variant="contained" onClick={updateFreeze}>
              {study.unfreeze === true ? 'Set Freeze' : 'Set UnFreeze'}
            </Button>
          </FormControl>
          <FormControl sx={{ marginTop: 2, minWidth: 100, marginLeft: 3 }}>
            <Button variant="contained" onClick={updateActive}>
              {study.active === true ? 'Set In Active' : 'Set Active'}
            </Button>
          </FormControl>
        </Paper>
      </Grid>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Studyconfig;
