

import { Backdrop, CircularProgress } from "@mui/material";

type Props = {
  open: boolean;
  handleClose?: () => void;
};
const Loader = (props: Props) => {
  

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
        onClick={props.handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loader;
