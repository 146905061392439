import React, { useState } from 'react';
import { Grid, Paper, MenuItem, Button, FormControl, TextField, Stack } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CancelIcon from '@mui/icons-material/Cancel';
import { AppSMService } from '../../../network/useAxios';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '../../molecules/Alert/Alert';
import { BREAD_CRUMBS, ROUTING_CONSTANTS, TEXT_CONSTANTS, useDataValidation } from '../../../utils';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { useAppSelector } from '../../../hooks';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { StudyDetailState } from './types';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
const Studyconfig = () => {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { client, study } = appDataContext;
  const { HOME, STUDY_MANAGEMENT, CONFIG } = BREAD_CRUMBS;

  const { DASHBOARD, STUDY: RSTUDY } = ROUTING_CONSTANTS;
  const { FORMS, STUDY, VISIT_FORM } = NETWORK_CONSTANTS.END_POINTS;
  const { checkIsEmpty, split } = useDataValidation();
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: STUDY_MANAGEMENT, link: `${RSTUDY}${client.id}/${study.id}` },
    { title: CONFIG, link: '' }
  ];
  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SOMETHING_WENT_WRONG, SUCCESS },
      STUDY_PAGE: { CONFIG_CREATE_SUCCESS, CONFIG_UPDATE_SUCCESS }
    }
  } = TEXT_CONSTANTS;

  const [studyConfigState, setStudyConfigState] = useState<StudyDetailState>({
    formList: [],
    visitList: [],
    options: [],
    visitForm: [],
    visitName: '',
    selectedForm: [],
    assignForm: [],
    visitFormID: '',
    visitposition: '',
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: ''
  });
  const {
    assignForm,
    formList,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    options,
    selectedForm,
    visitForm,
    visitFormID,
    visitList,
    visitName,
    visitposition
  } = studyConfigState;

  React.useEffect(() => {
    loadVisit();
    loadForm();
  }, []);
  React.useEffect(() => {
    loadVisitFormList();
  }, [visitName]);
  const updateState = (data: any) => {
    setStudyConfigState((prevState: StudyDetailState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadForm = () => {
    AppSMService.axios.get(`${FORMS}?studyId=${study.id}`).then(({ status, data }) => {
      updateState({ formList: status === HTTP_STATUS_CODE.OK ? data : [] });
    });
  };

  const loadVisit = () => {
    AppSMService.axios.get(`${STUDY}/${study.id}/config?type=VISIT`).then(({ status, data }) => {
      if (status === 200) {
        if (data.length > 0) {
          const keysList = Object.keys(data[0].data);
          const updatedVisit: any = [];
          data.map((item: any) => {
            updatedVisit.push({ id: item.id, name: item.data[keysList[0]] });
          });
          updateState({ visitList: updatedVisit });
        }
      }
    });
  };
  const handleTextValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formIndex: number
  ) => {
    const { name, value } = event.target;
    if (name === 'visitposition') {
      updateState({ visitposition: value });
    } else {
      updateTempConfig(value, formIndex);
    }
  };
  const handleSelectFieldChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { value, name }
    } = event;
    if (name === 'form') {
      updateState({ options: typeof value === 'string' ? split(',', value) : value });
    } else {
      updateState({ visitName: value });
    }
  };
  const loadVisitFormList = () => {
    if (visitName !== '') {
      const splitVisitName = visitName.split('|');
      AppSMService.axios
        .get(`${VISIT_FORM}?visitId=${splitVisitName[0]}`)
        .then(({ data }) => {
          if (data.length > 0) {
            const result = data[0];
            const _stateData = { visitForm, visitposition, assignForm, visitFormID };
            _stateData.visitFormID = result.id;
            const splitVisitName = split('|', visitName);
            const visit_Form_List = [
              {
                visitId: splitVisitName[0],
                visitName: splitVisitName[1],
                forms: result.forms
              }
            ];
            _stateData.visitForm = visit_Form_List;
            _stateData.visitposition = result.order;
            const updateFormList: any = [];
            if (result.forms.length > 0) {
              result.forms.map((item: any) => {
                updateFormList.push(item.formName + '|' + item.formId + '|' + item.logForm);
              });
              _stateData.assignForm = updateFormList;
            }
            updateState(_stateData);
          } else {
            updateState({ visitForm: [], visitFormID: '', visitposition: '', assignForm: [] });
          }
        })
        .catch(() => {
          updateState({ visitForm: [], visitFormID: '', visitposition: '', assignForm: [] });
        });
    }
  };
  const addTempConfig = () => {
    const updateForms: any = visitForm.length > 0 ? visitForm[0].forms : [];
    const assign_form = assignForm;
    selectedForm.map((item: string) => {
      const splitForm = split('|', item);
      assign_form.push(item);
      updateForms.push({
        formId: splitForm[1],
        formName: splitForm[0],
        formOrder: '',
        logForm: splitForm[2]
      });
    });
    const splitVisitName = split('|', visitName);
    const visit_Form_List = [
      {
        visitId: splitVisitName[0],
        visitName: splitVisitName[1],
        forms: updateForms
      }
    ];
    updateState({ visitForm: visit_Form_List, selectedForm: [], assignForm: assign_form });
  };
  const updateTempConfig = (value: string, formIndex: number) => {
    const visitFormList = visitForm;
    const updatedForms: any = [];
    if (visitFormList.length > 0) {
      if (visitFormList[0].forms.length > 0) {
        visitFormList[0].forms.map((item: string, index: number) => {
          const updForms: any = item;
          if (formIndex === index) {
            updForms['formOrder'] = value;
          }
          updatedForms.push(updForms);
        });
      }
    }
    const splitVisitName = split('|', visitName);
    const visit_Form_List = [
      {
        visitId: splitVisitName[0],
        visitName: splitVisitName[1],
        forms: updatedForms
      }
    ];
    updateState({ visitForm: visit_Form_List });
  };
  const removeForm = (formIndex: number) => {
    const visitFormList = visitForm;
    const updatedForms: any = [];
    if (visitFormList.length > 0) {
      if (visitFormList[0].forms.length > 0) {
        visitFormList[0].forms.map((item: string, index: number) => {
          if (formIndex !== index) {
            updatedForms.push(item);
          }
        });
      }
    }
    const visit_Form_List = [
      {
        visitId: visitFormList[0].visitId,
        visitName: visitFormList[0].visitName,
        forms: updatedForms
      }
    ];
    updateState({ visitForm: visit_Form_List });
  };
  const saveVisitForm = async () => {
    const visitFormList = visitForm;
    const visitSplit = visitFormList[0].visitId;
    const postData = {
      studyId: study.id,
      visitId: visitSplit,
      order: visitposition,
      forms: visitFormList[0].forms
    };
    let response: any = { status: HTTP_STATUS_CODE.OK };
    if (!checkIsEmpty(visitFormID)) {
      response = await AppSMService.axios.patch(`${VISIT_FORM}/${visitFormID}`, postData);
    } else {
      response = await AppSMService.axios.post(`${VISIT_FORM}/`, postData);
    }
    if (HTTP_STATUS_CODE.OK === response.status) {
      updateState({
        openAlert: true,
        openAlertStatus: SUCCESS,
        openAlertMsg: !checkIsEmpty(visitFormID) ? CONFIG_UPDATE_SUCCESS : CONFIG_CREATE_SUCCESS
      });
    } else {
      updateState({
        openAlert: true,
        openAlertStatus: ERROR,
        openAlertMsg: SOMETHING_WENT_WRONG
      });
    }
    AutoCloseAlert();
  };

  const handleCloseAlert = () => {
    updateState({
      openAlert: true,
      openAlertStatus: ERROR,
      openAlertMsg: SOMETHING_WENT_WRONG
    });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert: true,
        openAlertStatus: ERROR,
        openAlertMsg: SOMETHING_WENT_WRONG
      });
    }, 3000);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2
          }}>
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="demo-simple-select-helper-label">Visit</InputLabel>

            <Select
              labelId="demo-simple-select-helper-label"
              onChange={handleSelectFieldChange}
              name="visit">
              {visitList.map((item: any, index: number) => {
                return (
                  <MenuItem value={item.id + '|' + item.name} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="demo-simple-select-label">Form</InputLabel>
            <Select
              multiple
              value={selectedForm}
              onChange={(e) => updateState({ selectedForm: e.target.value })}
              input={<OutlinedInput label="Multiple Select" />}>
              {formList.map((item: any, index: number) => {
                if (assignForm.indexOf(item.name + '|' + item.id + '|' + item.logForm) < 0) {
                  return (
                    <MenuItem key={index} value={item.name + '|' + item.id + '|' + item.logForm}>
                      {item.name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: 2, minWidth: 100 }}>
            <Button variant="contained" onClick={addTempConfig}>
              Add
            </Button>
          </FormControl>
        </Paper>
        {visitForm.length > 0 && (
          <>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column'
              }}>
              {visitForm.map((item: any, index: number) => {
                if (item.forms.length > 0) {
                  return (
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 380,
                        bgcolor: 'background.paper'
                      }}
                      subheader={<ListSubheader>{item.visitName}</ListSubheader>}
                      key={index}>
                      <TextField
                        label="Visit Position"
                        variant="standard"
                        sx={{
                          width: 100,
                          ml: 5
                        }}
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        name="visitposition"
                        value={visitposition}
                        onChange={(event) => handleTextValueChange(event, 0)}
                      />
                      {item.forms.map((formItem: any, formIndex: number) => {
                        return (
                          <ListItem
                            sx={{
                              paddingLeft: '50px'
                            }}
                            key={formIndex}>
                            <ListItemText primary={formItem.formName} />
                            <TextField
                              label="Position"
                              variant="standard"
                              sx={{
                                width: 100
                              }}
                              value={formItem.formOrder}
                              type="number"
                              InputProps={{ inputProps: { min: 1 } }}
                              onChange={(event) => handleTextValueChange(event, formIndex)}
                            />
                            <CancelIcon
                              sx={{ marginTop: '25px', marginLeft: 1 }}
                              onClick={() => removeForm(formIndex)}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  );
                }
              })}
            </Paper>
            <Stack
              direction="row"
              alignItems="right"
              justifyContent="right"
              spacing={2}
              pt={4}
              pb={2}
              pr={5}>
              <Button variant="contained" onClick={saveVisitForm}>
                Save
              </Button>
            </Stack>
          </>
        )}
      </Grid>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Studyconfig;
