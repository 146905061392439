import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
type InlineMenu = {
  key: string;
  label: string;
};
type Props = {
  options: InlineMenu[];
  handleSelectedMenu?: (parentId: string, key: string) => void;
  parentId: string;
};
const InlineMenus = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleSelectedMenu = (menu: InlineMenu) => {
    props?.handleSelectedMenu?.(props.parentId, menu.key);
    handleClose();
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        onClick={handleClick}>
        <MoreIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        {props.options &&
          props.options.length > 0 &&
          props.options.map((menu: InlineMenu) => (
            <MenuItem key={menu.key} onClick={() => handleSelectedMenu(menu)}>{menu.label}</MenuItem>
          ))}
      </Menu>
    </>
  );
};
export default InlineMenus;
