import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Box,
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import Filters from "../../organisms/Filters/Filters";
import axios from "axios";
import { BKUP_MANGE_GRID_COLUMN_CLIENT } from "./utils";
import { BackupMangementGridState } from "./types";
const breadCrumbList = [
  { title: "Home", link: "/clinicalApp/dashboard" },
  { title: "Backup Management", link: "" },
];

const BackupmanagementGrid = () => {
  const [backupMangementGridState, setBackupMangementGridState] =
    useState<BackupMangementGridState>({
      userList: [],
      filterList: [],
    });
  const { userList } = backupMangementGridState;
  useEffect(() => {
    loadDataEntry();
  }, []);
  const updateState = (data: any) => {
    setBackupMangementGridState((prevState) => {
      const _updatedState:any={...prevState};
      Object.keys(data).forEach(function(key:string) {
        _updatedState[key]=data[key]
      });
      return _updatedState;
    });
  };

  const loadDataEntry = () => {
    axios
      .get("http://localhost:3000/assets/backupmanagement.json")
      .then(({ data }) => {
        updateState({userList:data});
      })
      .then(() => {
        loadFilterList();
      });
  };

  const loadFilterList = () => {
    axios
      .get("http://localhost:3000/assets/backupfilterlist.json")
      .then(({ data }) => {
        updateState({filterList:data});
      });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setAnchorEl(null);
  };

  const columns: GridColDef[] = [
    ...BKUP_MANGE_GRID_COLUMN_CLIENT,
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, params)}
            >
              <MoreIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>Download</MenuItem>
              <MenuItem>Mark as Verified</MenuItem>
              <MenuItem>Cancel</MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        {/*  <Grid item xs={12} md={8} mb={4} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
           <Filters
              filterlist={filterList}
              buttonlabel={"Create manual Backup"}
            /> 
          </Paper>
        </Grid> */}
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              component="h3"
              variant="subtitle1"
              color="primary"
              gutterBottom
            >
              History
            </Typography>
          </Box>

          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={userList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
            />
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default BackupmanagementGrid;
