import React, { useState, useEffect } from 'react';
import { Grid, Paper, Box, Typography, Button, Stack, TextField } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import TabHeader from '../../organisms/TabHeader/TabHeader';
import AccessRights from '../../organisms/AccessRights/AccessRights';
import AccessRightsSelect from '../../organisms/AccessRightsSelect/AccessRightsSelect';
import { AppUMService as AppService } from '../../../network/useAxios';
import Dialog from '../../molecules/Dialog/Dialog';
import Alert from '../../molecules/Alert/Alert';
import {
  BREAD_CRUMBS,
  ROUTING_CONSTANTS,
  TEXT_CONSTANTS,
  useDataValidation,
  USER_TYPE
} from '../../../utils';
import { AccessManagementState, IAccess, TabPanelProps } from './types';
import { useAppSelector } from '../../../hooks';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { AppUMService } from '../../../network/useAxios';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
import { AxiosError } from 'axios';

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function AccessManagement() {
  const breadCrumbList = [
    { title: BREAD_CRUMBS.HOME, link: ROUTING_CONSTANTS.DASHBOARD },
    { title: BREAD_CRUMBS.ACCESS_MANAGEMENT, link: '' }
  ];

  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { user } = appDataContext;
  const { checkIsEmpty } = useDataValidation();
  const [accessRights, setAccessRights] = useState<IAccess[]>([]);
  const [selectedAccessID, setSelectedAccessID] = useState<any>('');

  const [accessManageState, setAccessManageState] = useState<AccessManagementState>({
    accessManagemnet: [],
    name: '',
    description: '',
    editable: false,
    btnloading: false,
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: '',
    selectedAccess: [],
    selectedAllAccess: [],
    selectedAccessMappingID: '',
    openDialog: false,
    activeTab: 0,
    selectedTab: 0,
    removeItem: 0,
    open: false,
    options: []
  });
  const {
    accessManagemnet,
    activeTab,
    btnloading,
    description,
    editable,
    name,
    open,
    openAlert,
    openAlertStatus,
    openAlertMsg,
    openDialog,
    options,
    selectedAccess,
    selectedAccessMappingID,
    selectedAllAccess,
    selectedTab
  } = accessManageState;

  const updateState = (data: any) => {
    setAccessManageState((prevState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };

  useEffect(() => {
    loadaccessRights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadAccessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessRights]);

  useEffect(() => {
    if (selectedAllAccess.length > 0) { 
      if (selectedAccessID === '0') {
        updateState({ selectedAccess: [], selectedAccessMappingID: '' });
      } else {
        let availAccess = 'No';

        selectedAllAccess.map((item: any) => {
          if (item.accessManagementId === selectedAccessID) {
            availAccess = 'Yes';
            updateState({
              selectedAccess: item.accessRightsIds,
              selectedAccessMappingID: item.id
            });
          }
        });
        if (availAccess === 'No') {
          updateState({ selectedAccess: [], selectedAccessMappingID: '' });
        }
      }
    } else {
      loadAccessRightMaping();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccessID,selectedAllAccess]);

  const loadAccessData = () => {
    const accessGroupId =
      user?.type === USER_TYPE.CLIENT ? '65892129dc0bda701538e161' : '65892117dc0bda701538e160';
    AppUMService.axios
      .get(
        `${NETWORK_CONSTANTS.END_POINTS.ACCESS_MANAGEMENTS}?display=show&accessGroupId=${accessGroupId}`
      )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.length > 0) {
            const { id, name, description, editable } = data[activeTab];

            updateState({
              accessManagemnet: data,
              name: name,
              description: description,
              editable: editable
            });
            setSelectedAccessID(id);
          }
        }
      })
      .catch((e) => {
        console.log(`Error in AccessManagement - ${e}`);
      });
  };
  const loadaccessRights = () => {
    const accessGroupId =
      user?.type === USER_TYPE.CLIENT ? '65892129dc0bda701538e161' : '65892117dc0bda701538e160';
    AppUMService.axios
      .get(`${NETWORK_CONSTANTS.END_POINTS.ACCESS_RIGHTS}?accessGroupId=${accessGroupId}`)
      .then(({ status, data }) => {
        if (status === 200) {
          if (data.length > 0) {
            setAccessRights(data);
          }
        }
      })
      .catch((e) => {
        console.log(`Error in AccessRights - ${e}`);
      });
  };
  const loadAccessRightMaping = (selectedAccess_ID?:string) => {
    AppService.axios
      .get(`${NETWORK_CONSTANTS.END_POINTS.ACCESS_RIGHTS_MANAGEMENTS_MAPPING}`)
      .then(({ status, data }) => {
        if (status === 200) {
          if (data.length > 0) {
           

            updateState({
              selectedAllAccess: data
            });
            
              setSelectedAccessID(selectedAccess_ID || selectedAccessID);
          
           
          }
        }
      })
      .catch((e) => {
        console.log(`Error in AccessRights - ${e}`);
      });
  };

  const handleSetActiveTab = (value: number, data?: any) => {
    const { id, name, description, editable } = !checkIsEmpty(data)
      ? data[value]
      : accessManagemnet[value];

    updateState({
      activeTab: value,
      name,
      description,
      editable
    });
    setSelectedAccessID(id);
  };

  const loadTab = () => {
    if (accessManagemnet.length > 0) {
      const tabHeader: Array<any> = [];
      accessManagemnet.forEach((item: any) => {
        tabHeader.push(item.name);
      });

      return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabHeader
            headerlist={tabHeader}
            handleSetActiveTab={handleSetActiveTab}
            selectedTab={selectedTab}
          />
        </Box>
      );
    }
  };

  const selectAccess = () => {
    const updatedAccess = [...selectedAccess];
    if (options.length > 0) {
      accessRights.forEach((item: any) => {
        if (options.indexOf(item.name) >= 0) {
          updatedAccess.push(item.id);
        }
      });
    }
    updateState({ selectedAccess: updatedAccess, open: false });
  };

  const handleSetOptions = (value: string[]) => {
    updateState({ options: value });
  };
  const handleOpen = () => updateState({ open: true });
  const handleClose = () => updateState({ open: false });

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    const value = event.target.value;
    if (type === 'name') {
      updateState({ name: value.toLocaleUpperCase() });
    } else if (type === 'description') {
      updateState({ description: value });
    }
  };

  const handleSelectAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _selectedAccess = selectedAccess;
    const selectedVal = event.target.value;
    const index = _selectedAccess.indexOf(selectedVal);
    if (index > -1) {
      _selectedAccess.splice(index, 1);
    }
    updateState({ selectedAccess: _selectedAccess, removeItem: Math.random() });
  };

  const saveChanges = async () => {
    updateState({ btnloading: true });
    const accessGroupId =
      user?.type === USER_TYPE.CLIENT ? '65892129dc0bda701538e161' : '65892117dc0bda701538e160';
    let requestURL = `${NETWORK_CONSTANTS.END_POINTS.ACCESS_MANAGEMENTS}`;
    if (accessManagemnet[activeTab].id !== '0') {
      requestURL = `${requestURL}/${accessManagemnet[activeTab].id}`;
    }
    const requestData = {
      name: name,
      description: description,
      editable: true,
      display: 'show',
      accessGroupId: accessGroupId
      /* authority: localStorage.getItem("authority"), */
    };
    let response = { data: {id:""}, status: 400 };
    if (accessManagemnet[activeTab].id === '0') {
      response = await AppUMService.axios.post(requestURL, requestData);
    } else {
      response = await AppUMService.axios.put(requestURL, requestData);
    }
    if (response.status === 200) {
      updateState({
        openAlert: true,
        openAlertStatus: TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.SUCCESS,
        openAlertMsg: TEXT_CONSTANTS.VALIDATION_TEXT.ACCESS_MANAGEMENT.ACCESS_MANAGEMENT_UPDATE
      });
      loadAccessData();
      assignAccessRights(response?.data?.id);
    } else {
      updateState({
        openAlert: true,
        openAlertStatus: TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.ERROR,
        openAlertMsg:
          TEXT_CONSTANTS.VALIDATION_TEXT.ACCESS_MANAGEMENT.ACCESS_MANAGEMENT_UPDATE_FAILED
      });
    }
    AutoCloseAlert();
    updateState({ btnloading: false });
  };
  const assignAccessRights = async (selectedAccess_ID: any) => {
    let URL = `${NETWORK_CONSTANTS.END_POINTS.ACCESS_RIGHTS_MANAGEMENTS_MAPPING}`;
    let response = { data: {}, status: 0 };
    if (!checkIsEmpty(selectedAccessMappingID)) {
      URL = `${URL}/${selectedAccessMappingID}`;
      response = await AppService.axios.put(URL, {
        accessRightsIds: selectedAccess,
        accessManagementId: selectedAccess_ID
      });
    } else {
      response = await AppService.axios.post(URL, {
        accessRightsIds: selectedAccess,
        accessManagementId: selectedAccess_ID
      });
    }
    if (response.status === HTTP_STATUS_CODE.OK) {
      loadAccessRightMaping(selectedAccess_ID);
    }
  };
  const handleCloseAlert = () => {
    updateState({
      openAlert: false,
      openAlertStatus: '',
      openAlertMsg: ''
    });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert: false,
        openAlertStatus: '',
        openAlertMsg: ''
      });
    }, 3000);
  };
  const handleRemove = () => {
    updateState({
      openDialog: true
    });
  };
  const handleCloseDialog = () => {
    updateState({
      openDialog: false
    });
  };
  const handleAcceptDialog = () => {
    if (selectedAccessID === '0') {
      loadAccessData();
      handleCloseDialog();
      handleSetActiveTab(0);
      updateState({
        selectedTab: 0
      });
    } else {
      AppUMService.axios
        .delete(`${NETWORK_CONSTANTS.END_POINTS.ACCESS_MANAGEMENTS}/${selectedAccessID}`)
        .then(({ status, data }) => {
          if (status === HTTP_STATUS_CODE.OK) {
            removeAcceessMaping();
            updateState({
              selectedTab: 0
            });
          } else {
            handleCloseDialog();
            updateState({
              openAlert: true,
              openAlertStatus: TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.ERROR,
              openAlertMsg: data.Message
            });
          }
        })
        .catch((e:AxiosError) => {
          const {response}=e;
          // eslint-disable-next-line no-unsafe-optional-chaining
          const {Code,Message}:any=e?.response?.data;
          handleCloseDialog();
          updateState({
            openAlert: true,
            openAlertStatus: TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.ERROR,
            openAlertMsg:
              checkIsEmpty(Code)?TEXT_CONSTANTS.VALIDATION_TEXT.ACCESS_MANAGEMENT.ACCESS_MANAGEMENT_DELETE_FAILED:Message
          });
          console.log(`Error in AccessRightsMapping - ${e}`);
        });
    }
  };
  const removeAcceessMaping = () => {
    AppUMService.axios
      .delete(
        `${NETWORK_CONSTANTS.END_POINTS.ACCESS_RIGHTS_MANAGEMENTS_MAPPING}/${selectedAccessMappingID}`
      )
      .then(({ status }) => {
        if (status === 200) {
          loadAccessData();
          handleCloseDialog();
        }
      })
      .catch((e) => {
        loadAccessData();
        handleCloseDialog();
        console.log(`Error in AccessRightsMapping - ${e}`);
      });
  };
  const addCustom = () => {
    const access_Managemnet = [
      ...accessManagemnet,
      {
        id: '0',
        name: 'Custom',
        description: '',
        authority: '',
        editable: true
      }
    ];

    setSelectedAccessID('0');
    updateState({
      name: 'Custom',
      description: '',
      editable: true,
      accessManagemnet: access_Managemnet,
      activeTab: access_Managemnet.length - 1,
      selectedTab: access_Managemnet.length - 1
    });
    handleSetActiveTab(access_Managemnet.length - 1, access_Managemnet);
  };
  return (
    <>
      <Box>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Grid container>
            <Breadcrumb breadCrumbList={breadCrumbList} />
            <Paper sx={{ width: 1 }}>
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                sx={{ marginTop: '10px', marginRight: '10px' }}>
                <Button variant="contained" onClick={addCustom}>
                  Create New
                </Button>
              </Stack>

              {loadTab()}
              <CustomTabPanel value={activeTab} index={activeTab}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    value={name}
                    variant="standard"
                    sx={{ marginRight: '20px', width: '25%' }}
                    disabled={!editable}
                    onChange={(event) => handleTextFieldChange(event, 'name')}
                  />
                  <TextField
                    label="Description"
                    value={description}
                    onChange={(event) => handleTextFieldChange(event, 'description')}
                    sx={{ width: '70%' }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '10px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h5">Access Rights</Typography>
                    </Grid>
                    {editable && (
                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="right" justifyContent="right">
                          <Button variant="contained" onClick={handleOpen}>
                            Add
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>

                  {selectedAccess.length > 0 && (
                    <AccessRightsSelect
                      selectedAccess={selectedAccess}
                      masteraccessRights={accessRights}
                      selectedAccessID={selectedAccessID}
                      handleSelectAccess={handleSelectAccess}
                      disablerights={editable}
                    />
                  )}
                </Grid>
              </CustomTabPanel>
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                spacing={2}
                pt={4}
                pb={2}
                mr={4}>
                {editable && (
                  <Button variant="outlined" endIcon={<DeleteIcon />} onClick={handleRemove}>
                    Remove
                  </Button>
                )}
                <LoadingButton
                  onClick={saveChanges}
                  loading={btnloading}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                  variant="contained">
                  Save
                </LoadingButton>
              </Stack>
            </Paper>
          </Grid>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <AccessRights
          accessRights={accessRights}
          selectedAccess={selectedAccess}
          selectedAccessID={selectedAccessID}
          handleSetOptions={handleSetOptions}
          handleClose={handleClose}
          selectAccess={selectAccess}
        />
      </Modal>
      <Dialog
        openDialog={openDialog}
        dialogMsg="Are you sure want to remove this Access Management?"
        dialogHeader={'Alert'}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </>
  );
}
export default AccessManagement;
