import { useNavigate } from 'react-router-dom';
import FormBuilder from '../../organisms/FormBuilder/FormBuilder';
import { IClinicalForm } from '../../../models/ClinicalForm';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { InitialStateTypes, updateFormsData } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { ROUTING_CONSTANTS } from '../../../utils';
const CreateClinicalForm = () => {
  const navigate = useNavigate();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { forms } = appDataContext;
  const { FORM_LIST } = ROUTING_CONSTANTS;
  const dispatch = useAppDispatch();
  const handleFormData = (formObj: any) => {
    const currTime = new Date().getTime();

    const existingForms = [...(forms || [])];
    existingForms.push({
      id: currTime.toString(),
      name: `Form - ${existingForms?.length + 1}`,
      data: formObj,
      lastUpdated: currTime,
      version: '1.0'
    } as IClinicalForm);
    dispatch(updateFormsData(forms));
    navigate(FORM_LIST);
  };
  return (
    <>
      <h2>Form Builder</h2>
      <FormBuilder handleFormData={handleFormData} />
    </>
  );
};
export default CreateClinicalForm;
