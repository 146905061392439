
export function useDataUtils() {
  const getRandomNumber = function () {
    return Math.random().toString().replace(".", "");
  };

  return {
    getRandomNumber,
  };
}
