import { Alert, Button, Modal, Snackbar } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../../../common/util';
import { FORMSTATUS, IFormMetadata } from '../../../models/ClinicalForm';
import { AppSMService } from '../../../network/useAxios';
import FormStatus from '../../molecules/FormStatus/FormStatus';
import InlineMenus from '../../molecules/InlineMenus/InlineMenus';
import CreateForm from '../../organisms/CreateForm/CreateForm';
import TableList from '../../organisms/TableList/TableList';
import { useStudyAccessRights } from '../../../utils/useStudyAccessRights';
import { FormListPageState, INLINE_MENU_KEY, INLINE_MENUS_LIST, SCOPE } from './types';
import { FORM_STATUS, TEXT_CONSTANTS } from '../../../utils';
import { InitialStateTypes, updateMetaData } from '../../../store/slices/globalSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { STORE_CONFIG } from '../../../store/storeConstant';
const FormsListPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch: any = useAppDispatch();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { client, project, study, forms, metaData } = appDataContext;
  const { APPROVED } = FORM_STATUS;
  const { ASSIGN_CLIENT, ASSIGN_GLOBAL, ASSIGN_PROJECT } = INLINE_MENU_KEY;
  const { CLIENT, GLOBAL, PROJECT } = SCOPE;
  const {
    VALIDATION_TEXT: {
      FORM_LIST_PAGE: { GLOBAL_LIBRARY_CREATED, CLIENT_LIBRARY_CREATED, PROJECT_LIBRARY_CREATED }
    }
  } = TEXT_CONSTANTS;
  const [formListPageState, setFormListPageState] = useState<FormListPageState>({
    formsList: [],
    initLoad: false,
    isCreateFormOpen: false,
    isError: false,
    isFormValid: false,
    open: false,
    updateMessage: ''
  });
  const { formsList, initLoad, isCreateFormOpen, isError, isFormValid, open, updateMessage } =
    formListPageState;
  const updateState = (data: any) => {
    setFormListPageState((prevState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const params = new URLSearchParams(search);
  const isReviewer = params?.get?.('isReviewer');
  const { createFormAccessRights, formReviewAccessRights } = useStudyAccessRights();
  const columns = [
    // { field: "id", headerName: "Form Id", width: 250 },
    { field: 'name', headerName: 'Form Name', width: 250 },
    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return <FormStatus status={params?.row?.status ?? FORMSTATUS.PENDING} />;
      }
    },
    { field: 'version', headerName: 'Form Version', width: 100 },
    {
      field: 'modifiedOn',
      headerName: 'Last updated',
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return formatDate(params?.row.modifiedOn);
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return params?.row?.status === APPROVED ? (
          <InlineMenus
            options={INLINE_MENUS_LIST}
            handleSelectedMenu={handleSelectedInlineMenu}
            parentId={params?.row?.id.toString()}
          />
        ) : (
          ''
        );
      }
    }
  ];
  const handleSelectedInlineMenu = async (parentId: string, key: string) => {
    let data = {};
    let message = '';
    const url = `forms/library/${parentId}`;
    if (key === ASSIGN_GLOBAL) {
      data = { scope: GLOBAL, formId: parentId };
      message = GLOBAL_LIBRARY_CREATED;
    } else if (key === ASSIGN_CLIENT) {
      data = {
        scope: CLIENT,
        clientId: client?.id,
        formId: parentId
      };
      message = CLIENT_LIBRARY_CREATED;
    } else if (key === ASSIGN_PROJECT) {
      data = {
        scope: PROJECT,
        projectId: project?.id,
        formId: parentId
      };
      message = PROJECT_LIBRARY_CREATED;
    }
    AppSMService.axios
      .post(url, data)
      .then(() => {
        updateState({ updateMessage: message, open: true });
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  const loadForms = () => {
    let url = `forms?studyId=${study?.id}`;
    if (isFormReviewer()) {
      url += `&status=${FORMSTATUS.PUBLISHED},${FORMSTATUS.REJECTED},${FORMSTATUS.APPROVED}`;
    }
    AppSMService.axios
      .get(url)
      .then(({ status, data }) => {
        updateState({
          formsList: data && data.length > 0 ? data : [],
          initLoad: false
        });
      })
      .catch((e: any) => {
        console.log(`Error in fetching forms list - ${e}`);
        updateState({ formsList: forms || [], initLoad: false });
      });
  };
  const handleFormMetaData = (metaData: IFormMetadata) => {
    dispatch(updateMetaData(metaData));
    updateState({ sFormValid: true });
    loadForms();
  };
  const isFormReviewer = () => {
    return params?.get?.('isReviewer') === 'true';
  };
  const openCreateForm = () => {
    updateState({ isCreateFormOpen: true });
  };
  const handleClose = () => {
    updateState({ isCreateFormOpen: false, open: false });
  };
  useEffect(() => {
    if (!initLoad) {
      loadForms();
    }
  }, [isReviewer]);
  useEffect(() => {
    if (metaData) {
      handleFormMetaData(metaData);
    }
    if (client.id && project.id && study.id) {
      updateState({ sFormValid: true });
    }
  }, []);

  return (
    <>
      <div>
        {/*  <QuickFilter handleFormMetaData={handleFormMetaData} metaData={appContext?.metaData}/> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: createFormAccessRights ? 'pointer' : 'none'
          }}>
          <p>Forms list</p>
          {isFormValid && !isFormReviewer() && createFormAccessRights && (
            <Button variant="contained" style={{ height: '38px' }} onClick={openCreateForm}>
              Create New Form
            </Button>
          )}
        </div>
        <div>
          <TableList
            columns={columns}
            data={formsList}
            rowClick={(event: any) => {
              if (!createFormAccessRights && !isFormReviewer()) {
                return false;
              } else if (isFormReviewer() && formReviewAccessRights) {
                console.log("Else block formReviewAccessRights",formReviewAccessRights)
              }
              if (isFormReviewer()) {
                navigate(`/clinicalApp/forms/${event.id}/review`);
              } else {
                navigate(`/clinicalApp/forms/${event.id}`);
              }
            }}
          />
        </div>
      </div>
      <Modal
        open={isCreateFormOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}>
        <CreateForm />
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}>
        <Alert
          onClose={handleClose}
          severity={isError === true ? 'error' : 'success'}
          sx={{ width: '100%' }}>
          {updateMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default FormsListPage;
