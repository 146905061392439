import { GridRenderCellParams } from "@mui/x-data-grid";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import { FORMSTATUS } from "../../../models/ClinicalForm";


export const DATA_ENTRY_FORM_COLUMNS = [
  {
    field: "id",
    headerName: "Form Id",
    width: 210,
    editable: false,
    hideable: true
  },
  {
    field: "formName",
    headerName: "Form Name",
    width: 210,
    editable: false
  },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    renderCell: (params: GridRenderCellParams): React.ReactNode => {
      return (
        <FormStatus status={params?.row?.status ?? FORMSTATUS.PENDING} />
      );
    },
  },
  {
    field: "dbMode",
    headerName: "Mode",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 200
  },
  {
    field: "createdOn",
    headerName: "Created On",
    width: 250,
    editable: false
  },
  {
    field: "modifiedOn",
    headerName: "Modified On",
    width: 210,
    editable: false
  },
];

export const COLUMNS_VISIBILITY={
  
    // Hide columns status and traderName, the other columns will remain visible
    id: false,
  
}
