import React, { useState } from "react";
import { Link, Location, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { USER_TYPE } from "../utils/appConstants";
import { useDataValidation } from "../utils/useDataValidation";
import { useAppSelector } from "../hooks";
import { STORE_CONFIG } from "../store/storeConstant";
import { InitialStateTypes } from "../store/slices/globalSlice";

export default function ListItems() {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { user, study, client, project, accessRights } = appDataContext;

  const { checkIsEmpty } = useDataValidation();
  const { pathname, search }: Location = useLocation();
  const [listItemState, setListItemState] = useState({
    open: pathname.indexOf("onboarding") >= 0,
    openStudy:
      pathname.indexOf("study/") >= 0 || pathname.indexOf("metadata/") >= 0,
  });
  const updateState = (data: any) => {
    setListItemState((prevState)=>{
      const _updatedState:any={...prevState};
      Object.keys(data).forEach(function(key:string) {
        _updatedState[key]=data[key]
      });
      return _updatedState;
    });
  };
  React.useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = () => {
    updateState({ open: !listItemState.open });
  };

  const handleStudyClick = () => {
    updateState({ openStudy: !listItemState.openStudy });
  };

  const checkLogin = () => {
    setTimeout(() => {
      if (user?.username === "") {
        checkLogin();
      }
    }, 500);
  };
  const StudyManageMenu = () => {
    return (
      <>
        <ListItemButton
          onClick={handleStudyClick}
          className={pathname.indexOf("study") >= 0 ? "menu-active" : ""}
        >
          <ListItemIcon>
            <img src="/images/study.png" alt="Study Mgmt" />
          </ListItemIcon>
          <ListItemText primary="Study Mgmt" />
          {listItemState.openStudy ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={listItemState.openStudy} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {study.mode !== "STUDY SETTINGS" && (
              <>
                <Link to="study/details">
                  <ListItemButton>
                    <ListItemIcon>
                      <img src="/images/info.png" alt="Details" />
                    </ListItemIcon>
                    <ListItemText primary="Details" />
                  </ListItemButton>
                </Link>
                {user?.type !== USER_TYPE.ADMIN && (
                  <Link to={`metadata/${client.id}/${project.id}/${study.id}`}>
                    <ListItemButton>
                      <ListItemIcon>
                        <img src="/images/metadata.png" alt="Metadata" />
                      </ListItemIcon>
                      <ListItemText primary="Metadata" />
                    </ListItemButton>
                  </Link>
                )}
              </>
            )}
            {study.mode === "STUDY SETTINGS" && (
              <Link to="study/access">
                <ListItemButton>
                  <ListItemIcon>
                    <img src="/images/access.png" alt="Access" />
                  </ListItemIcon>
                  <ListItemText primary="Access" />
                </ListItemButton>
              </Link>
            )}
            {study.mode !== "STUDY SETTINGS" &&
              user?.type !== USER_TYPE.ADMIN && (
                <>
                  <Link to="study/config">
                    <ListItemButton>
                      <ListItemIcon>
                        <img src="/images/config.png" alt="Config" />
                      </ListItemIcon>
                      <ListItemText primary="Config" />
                    </ListItemButton>
                  </Link>
                  <Link to="study/specialform">
                    <ListItemButton>
                      <ListItemIcon>
                        <img src="/images/config.png" alt="Special Form" />
                      </ListItemIcon>
                      <ListItemText primary="Special Form" />
                    </ListItemButton>
                  </Link>
                  <Link to="study/subject">
                    <ListItemButton>
                      <ListItemIcon>
                        <img src="/images/subject.png" alt="Subject" />
                      </ListItemIcon>
                      <ListItemText primary="Subject" />
                    </ListItemButton>
                  </Link>
                </>
              )}
          </List>
        </Collapse>
      </>
    );
  };
  const DashboardMenu = () => (
    <Link
      to="dashboard"
      className={pathname.indexOf("dashboard") >= 0 ? "menu-active" : ""}
      title="Dashboard"
    >
      <ListItemButton>
        <ListItemIcon>
          <img src="/images/dashboard.png" alt="Dashboard" />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>
  );

  return (
    <React.Fragment>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        className="menu-list-item"
      >
        {checkIsEmpty(study.id) && <DashboardMenu />}

        {study.id !== "" && user?.type !== USER_TYPE.ADMIN ? (
          <>
            {study.mode !== "STUDY SETTINGS" && (
              <>
                <Link
                  to="forms/list"
                  title="Form Builder"
                  className={
                    pathname.indexOf("forms/list") >= 0 &&
                    search.indexOf("isReviewer") < 0
                      ? "menu-active"
                      : ""
                  }
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img src="/images/form-build.png" alt="Form Builder" />
                    </ListItemIcon>
                    <ListItemText primary="Form Builder" />
                  </ListItemButton>
                </Link>
                <Link
                  to="forms/list?isReviewer=true"
                  title="Form Reviewer"
                  className={
                    search.indexOf("isReviewer") >= 0 ? "menu-active" : ""
                  }
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img src="/images/form-review.png" alt="Form Reviewer" />
                    </ListItemIcon>
                    <ListItemText primary="Form Reviewer" />
                  </ListItemButton>
                </Link>
                <Link
                  to="querymanagement"
                  title="Query Management"
                  className={
                    pathname.indexOf("querymanagement") >= 0
                      ? "menu-active"
                      : ""
                  }
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img src="/images/query-mgt.png" alt="Query Management" />
                    </ListItemIcon>
                    <ListItemText primary="Query Management" />
                  </ListItemButton>
                </Link>
                <Link
                  to="dataentry"
                  title="Data Entry Operator"
                  className={
                    pathname.indexOf("dataentry") >= 0 ? "menu-active" : ""
                  }
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img src="/images/data-entry.png" alt="Data entry" />
                    </ListItemIcon>
                    <ListItemText primary="Data entry" />
                  </ListItemButton>
                </Link>
                <Link
                  to="dataEntryReviewer"
                  title="Data Entry Reviewer"
                  className={
                    pathname.indexOf("dataEntryReviewer") >= 0
                      ? "menu-active"
                      : ""
                  }
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img src="/images/data-review.png" alt="Data Reviewer" />
                    </ListItemIcon>
                    <ListItemText primary="Data Reviewer" />
                  </ListItemButton>
                </Link>
              </>
            )}

            {!checkIsEmpty(study.id) && (
              <>
                <StudyManageMenu />
              </>
            )}
          </>
        ) : (
          <>
            {(user?.type === "Admin" || user?.type === "client") && (
              <>
                {" "}
                <Link
                  to="accessmanagement"
                  className={
                    pathname.indexOf("accessmanagement") >= 0
                      ? "menu-active"
                      : ""
                  }
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img
                        src="/images/access-mgt.png"
                        alt="Access Management"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Access Management" />
                  </ListItemButton>
                </Link>
                {!checkIsEmpty(study.id) && (
                  <>
                    <StudyManageMenu />
                  </>
                )}
              </>
            )}
            {user?.type !== "sponsor" &&
              user?.authority !== "DATA_ENTRY_OPERATOR" &&
              ((user?.type === USER_TYPE.ADMIN && checkIsEmpty(study.id)) ||
                user?.type === USER_TYPE.CLIENT) && (
                <ListItemButton
                  onClick={handleClick}
                  className={
                    pathname.indexOf("onboarding-") >= 0 ? "menu-active" : ""
                  }
                >
                  <ListItemIcon>
                    <img src="/images/onboarding.png" alt="Onboarding" />
                  </ListItemIcon>
                  <ListItemText primary="Onboarding" />
                  {listItemState.open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              )}
            {user?.type !== "sponsor" && (
              <Collapse in={listItemState.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ pl: 3 }}>
                  {(user?.type === "Admin" || user?.type === "user") && (
                    <Link to="onboarding-user">
                      <ListItemButton>
                        <ListItemIcon>
                          <img src="/images/users.png" alt="User" />
                        </ListItemIcon>
                        <ListItemText primary="User" />
                      </ListItemButton>
                    </Link>
                  )}
                  {(user?.type === "Admin" ||
                    accessRights.indexOf("View Client Details") >= 0) && (
                    <Link to="onboarding-client">
                      <ListItemButton>
                        <ListItemIcon>
                          <img src="/images/client.png" alt="Client" />
                        </ListItemIcon>
                        <ListItemText primary="Client" />
                      </ListItemButton>
                    </Link>
                  )}
                  {(user?.type === "client" ||
                    user?.type === "client_user") && (
                    <Link to="onboarding-client-user">
                      <ListItemButton>
                        <ListItemIcon>
                          <img src="/images/users.png" alt="User" />
                        </ListItemIcon>
                        <ListItemText primary="User" />
                      </ListItemButton>
                    </Link>
                  )}
                  {(user?.type === "client" ||
                    (user?.type === "client_user" &&
                      accessRights.indexOf("View Client Details") >= 0)) && (
                    <Link to="onboarding-sponsor">
                      <ListItemButton>
                        <ListItemIcon>
                          <img src="/images/client.png" alt="Sponsor" />
                        </ListItemIcon>
                        <ListItemText primary="Sponsor" />
                      </ListItemButton>
                    </Link>
                  )}
                </List>
              </Collapse>
            )}
            {(user?.type === "Admin" || user?.type === "client") && (
              <Link
                to="backupmanagement"
                className={
                  pathname.indexOf("backupmanagement") >= 0 ? "menu-active" : ""
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <img src="/images/backup-mgt.png" alt="Backup Management" />
                  </ListItemIcon>
                  <ListItemText primary="Backup Management" />
                </ListItemButton>
              </Link>
            )}
          </>
        )}
        <Link to="changepassword">
          <ListItemButton>
            <ListItemIcon>
              <img src="/images/changepassword.jpeg" alt="Change Password" />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
          </ListItemButton>
        </Link>
        <Link to="logout">
          <ListItemButton>
            <ListItemIcon>
              <img src="/images/logout.png" alt="Logout" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </Link>
      </List>
    </React.Fragment>
  );
}
