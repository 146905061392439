import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AlertMessage from "../../molecules/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AppSMService } from '../../../network/useAxios';
import { useDataValidation } from "../../../utils/useDataValidation";
interface IFormProps {
  fieldId?: string;
  formFields: any;
  show: any;
  handleClose: any;
  currentLinkId: string;
  form: any;
  currentOption: string;
  fieldChildrenIds:any;
  getFormDetails?:(formId:string)=>void;
  formId?:string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const ConditionalFieldLink = (props: IFormProps) => {
  const { formFields, show, handleClose, currentLinkId, currentOption,fieldChildrenIds,getFormDetails,formId="" } = {
    ...props,
  };
  const { split, find ,checkIsEmpty} = useDataValidation();
  const [stateObj, setStateObject] = React.useState({
    open: show,
    snackbar: {
      show: false,
      isError: false,
      message: "",
    },
    fieldChildren: [],
  });
  useEffect(() => {
    if(!checkIsEmpty(currentLinkId))
    {
      getFieldChildren();
    }
    else{
      setStateObject({ ...stateObj, open: show });
    }
  
  }, [show]);
  function getFieldChildren() {
    AppSMService.axios
      .get(`/fields/${currentLinkId}/children`)
      .then(({data}) => {
        setStateObject({ ...stateObj, fieldChildren: data,open: show });
      });
  }
  const modalClose = () => {
    setStateObject({ ...stateObj, open: false });
    handleClose();
  };

  function filterFormList() {
    return formFields.filter((value: any) => {
      return value?.name.indexOf(currentLinkId) === -1;
    });
  }
  const checkBoxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked)
    {
      AppSMService.axios.patch(`/fields/${event.target.id}`, {
        parentId: currentLinkId,
        condition: currentOption,
      }).then(({data})=>{
        const _fieldChildren:any=[...stateObj.fieldChildren];
        _fieldChildren.push(data);
        setStateObject({ ...stateObj, fieldChildren:_fieldChildren});
        getFormDetails?.(formId);
      });
    }
    else{
      AppSMService.axios.delete(`/fields/${event.target.id}/parent`).then(()=>{
        const _fieldChildren:any =stateObj.fieldChildren.filter((value:any)=>{
          return value.id!==event.target.id
        })
        setStateObject({ ...stateObj, fieldChildren: _fieldChildren});
        getFormDetails?.(formId);
      });

    }
    
  };
  function renderFieldList() {
    return filterFormList().map((value: any) => {
      const fieldId = split("-", value?.name)[3];
      const fieldObject = find(stateObj.fieldChildren, fieldId);
      const isFieldChecked=fieldObject?.id===fieldId && fieldObject?.condition===currentOption;
      const isDisabled=fieldChildrenIds.includes(fieldId) && !isFieldChecked;
      return (
        <FormGroup key={value?.name}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isFieldChecked}
                onChange={checkBoxOnChange}
                disabled={isDisabled}
                inputProps={{
                  id: fieldId,
                }}
              />
            }
            label={value?.label}
          />
        </FormGroup>
      );
    });
  }
  const handleErrorMessageUI = (
    message: string,
    isError: boolean,
    showSnackbar: boolean
  ) => {
    setStateObject({
      ...stateObj,
      snackbar: {
        ...stateObj.snackbar,
        message: message,
        isError: isError,
        show: showSnackbar,
      },
    });
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={modalClose}
        aria-labelledby="customized-dialog-title"
        open={stateObj.open}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Conditional Field Linking
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={modalClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {renderFieldList()}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={modalClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={modalClose}>
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {stateObj.snackbar.show && (
        <AlertMessage
          openAlert={stateObj.snackbar.show}
          openAlertMsg={stateObj.snackbar.message}
          openAlertStatus={stateObj.snackbar.isError ? "error" : "success"}
          handleCloseAlert={() => handleErrorMessageUI("", false, false)}
        />
      )}
    </React.Fragment>
  );
};
export default ConditionalFieldLink;
