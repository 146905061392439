import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Box, Snackbar } from "@mui/material";
import { FORMSTATUS } from "../../../models/ClinicalForm";
import { AppSMService } from '../../../network/useAxios';
import FormBuilder from "../../organisms/FormBuilder/FormBuilder";
import FormsList from "../../organisms/FormsList/FormsList";
import FormReviewerStatus from "../../organisms/FormReviewerStatus/FormReviewerStatus";
import { useDataValidation } from "../../../utils/useDataValidation";
import { InitialStateTypes } from "../../../store/slices/globalSlice";
import { useAppSelector } from "../../../hooks";
import { STORE_CONFIG } from "../../../store/storeConstant";
import { FormViewerPageState } from "./types";
import { NETWORK_CONSTANTS } from "../../../network/networkConstants";
import { ROUTING_CONSTANTS, TEXT_CONSTANTS } from "../../../utils";
interface IFormViewer {
  formId?: string;
  formData?: string;
}
const FormViewerPage = (props: IFormViewer) => {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const params = useParams();
  const navigate = useNavigate();
  const formFieldPrefix = "form-field-name-";
  const { map } = useDataValidation();
  const {  metaData } = appDataContext;
  const { FORMS,FIELDS } = NETWORK_CONSTANTS.END_POINTS;
  const {FORM_LIST}=ROUTING_CONSTANTS;
  const{PUBLISHED,SAVED}=FORMSTATUS;
  const {VALIDATION_TEXT:{FORM_VIEWER_PAGE:{NEED_ATLEAST_ONE_FIELD,FORM_UPDATED_SUCCESS,FORM_SAVE_ERROR,SELECT_ATLEAST_ONE_FIELD_TO_SAVE}}}=TEXT_CONSTANTS;
  const [formViewerPageState, setFormViewerPageState] =
    useState<FormViewerPageState>({
      fieldChildrenIds: [],
      formDetails: {
        data: [],
        logForm: false,
        fields: [],
        id: "",
        lastUpdated: 0,
        metaData,
        name: "",
        status: FORMSTATUS.PENDING,
        studyId: "",
        version: "",
      },
      formFields: [],
      isError: false,
      open: false,
      updateMessage: "",
    });
  const {
    fieldChildrenIds,
    formDetails,
    formFields,
    isError,
    open,
    updateMessage,
  } = formViewerPageState;
  const updateState = (data: any) => {
    setFormViewerPageState((prevState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };

  useEffect(() => {
    getFieldChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formViewerPageState.formDetails]);
  const getFormDetails = (formId: string) => {
    try {
      if (formId) {
        AppSMService.axios
          .get(`${FORMS}/${params.formId}`)
          .then(({ data }) => {
            const formFields: any[] = [];
            const fields = data?.fields || [];
            if (fields && fields.length > 0) {
              fields.forEach((field: any) => {
                formFields.push({
                  ...field.definition,
                  name: `${formFieldPrefix}${field.id}`,
                });
              });
              updateState({
                formDetails: data,
                fieldChildrenIds: [],
                formFields: formFields,
              });
            }
          })
          .catch((e: any) => {
            console.log(`Error in fetching forms list - ${e}`);
          });
      }
    } catch (error) {
      console.log(`Error in fetching form details - ${error}`);
    }
  };

  function getFieldChildren() {
    formDetails?.fields?.forEach((value: any) => {
      if (value?.hasChildren) {
        AppSMService.axios
          .get(`/${FIELDS}/${value.id}/children`)
          .then(({ data }) => {
            updateState({            
              fieldChildrenIds: [...fieldChildrenIds,...map(data, "id")],
            });
          });
      }
    });
  }
  const publishForm = (formObj: any) => {
  
    updateState({            
      isError: false,
      open:false,
      updateMessage:""
    });
    if (formObj && formObj.length > 0) {
      AppSMService.axios
        .patch(`${FORMS}/${params.formId}`, { status: PUBLISHED })
        .then((res) => {
          console.log(res);
          setTimeout(() => {
            navigate(FORM_LIST);
          }, 1000);
        })
        .catch((e) => {
          console.log(`Error in updating form ${params.formId} - ${e}`);
        });
    } else {
      updateState({            
        isError: true,
        open:true,
        updateMessage:NEED_ATLEAST_ONE_FIELD
      });
    }
  };
  const handleFormData = (formObj: any) => {
    updateState({            
      isError: false,
      open:false,
      updateMessage:""
    });

    if (formObj && formObj.length > 0) {
      AppSMService.axios.patch(`${FORMS}/${params.formId}`, {
        status: SAVED,
        logForm: formDetails?.logForm,
      });

      const prevFormFields = [...formFields];
      const promises: any[] = [];
      const prevFieldNames = prevFormFields.map((field) => field.name);
      const currentFieldNames: string[] = [];
      formObj.map((field: any) => {
        if (field.name.indexOf(formFieldPrefix) >= 0) {
          currentFieldNames.push(field.name);
        }
      });
      const fieldsToDelete = prevFieldNames.filter(
        (fieldName) => !currentFieldNames.includes(fieldName)
      );
      formObj?.length > 0 &&
        formObj.forEach((field: any) => {
          const fieldId = field?.name?.split(formFieldPrefix)[1] || null;
          const prevData = prevFormFields.find(
            (savedField) => savedField.name === field.name
          );
          if (fieldId && prevData) {
            if (JSON.stringify(prevData) !== JSON.stringify(field)) {
              const patchFormField = AppSMService.axios
                .patch(`${FIELDS}/${fieldId}`, {
                  definition: field,
                  formId: params.formId,
                  id: fieldId,
                })
                .catch((e) => {
                  console.log(`Error in updating field ${fieldId} - ${e}`);
                });
              promises.push(patchFormField);
            } else {
              console.log(`No change in form field - ${field.id}`);
            }
          } else {
            const createFormField = AppSMService.axios
              .post(FIELDS, { formId: params.formId, definition: field })
              .catch((e) => {
                console.log(`Error in creating new field - ${e}`);
              });
            promises.push(createFormField);
          }
        });
      if (fieldsToDelete && fieldsToDelete.length > 0) {
        fieldsToDelete.forEach((fieldId) => {
          const formFieldId = fieldId.split(formFieldPrefix)[1];
          const deleteFormField = AppSMService.axios
            .delete(`${FIELDS}/${formFieldId}`)
            .catch((e) => {
              console.log(`Error in creating new field - ${e}`);
            });
          promises.push(deleteFormField);
        });
      }
      axios
        .all(promises)
        .then(
          checkFailed((response: any) => {
            console.log("all api calls succeeded", response);
            updateState({            
              open:true,
              updateMessage:FORM_UPDATED_SUCCESS
            });
          })
        )
        .catch((e) => {
          console.log(`Error in updating the fields - ${e}`);
          updateState({       
            isError: true,     
            open:true,
            updateMessage:FORM_SAVE_ERROR
          });
        })
        .finally(() => {
          setTimeout(() => {
            navigate(FORM_LIST);
          }, 2000);
        });
    } else {
      updateState({       
        isError: false,     
        open:true,
        updateMessage:SELECT_ATLEAST_ONE_FIELD_TO_SAVE
      });
    }
  };
  function checkFailed(then: any) {
    return function (responses: any) {
      responses.forEach((response: any) => {
        if (response?.error) {
          throw response;
        }
        return then(response);
      });
    };
  }
  const handleSelectedForm = (formId: string) => {
    formId && navigate(`/clinicalApp/forms/${formId}`);
  };
  const handleClose = () => {
    updateState({ 
          
      open:false,
     
    });
  };
  useEffect(() => {
    getFormDetails(params.formId || "");
  }, ["", params.formId]);

  return (
    <>
      {/* <p>Form Editor - {formDetails?.name}</p> */}
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            width: "200px",
            maxWidth: "200px",
            overflowX: "auto",
            border: "1px #ccc solid",
            height: "100%",
          }}
        >
          <FormsList
            selectedFormId={params.formId}
            handleSelectedItem={handleSelectedForm}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <FormReviewerStatus
            studyId={formDetails?.studyId || ""}
            formId={params.formId || ""}
          />

          <FormBuilder
            handleFormData={handleFormData}
            formFields={formFields}
            handlePublishForm={publishForm}
            formStatus={formDetails?.status}
            form={formDetails}
            fieldChildrenIds={fieldChildrenIds}
            getFormDetails={getFormDetails}
            formId={params.formId}
          />
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          onClose={handleClose}
          autoHideDuration={3000}
        >
          <Alert
            onClose={handleClose}
            severity={isError === true ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {updateMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};
export default FormViewerPage;
