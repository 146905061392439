export enum CUSTOM_DATE_KEY {
    FULL_YEAR='FULL_YEAR'
  }

export enum FIELD_MAPPER_KEY{
    HEADER='header',
    PARAGRAPH ='paragraph',
    TEXT='text',
    NUMBER='number',
    SELECT='select',
    RADIO_GROUP='radio-group',
    DATE='date',
    TEXTAREA='textarea',
    CHECKBOX_GROUP='checkbox-group',
    STRING='String',
}  

export enum FORM_TYPE{
  SAVE='save',
  UPDATE ='update'
}  
