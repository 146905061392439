import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Container, TextField } from '@mui/material';
import AlertMessage from '../../molecules/Alert/Alert';
import Loader from '../../molecules/Loader/Loader';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { useAppSelector } from '../../../hooks';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { AppSMService } from '../../../network/useAxios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function SignOff({ meta: { show, name, code }, showModal }: any) {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { study, user } = appDataContext;
  const [stateObj, setStateObject] = React.useState({
    open: show,
    password: '',
    snackbar: {
      show: false,
      isError: false,
      message: ''
    },
    loader: {
      show: false
    }
  });

  React.useEffect(() => {
    setStateObject({ ...stateObj, open: show });
  }, [show]);

  const handleClose = () => {
    setStateObject({ ...stateObj, open: false });
    showModal(code, false);
  };
  const handleErrorMessageUI = (message: string, isError: boolean, showSnackbar: boolean) => {
    setStateObject({
      ...stateObj,
      snackbar: {
        ...stateObj.snackbar,
        message: message,
        isError: isError,
        show: showSnackbar
      }
    });
  };
  const showLoader = (showLoader: boolean) => {
    setStateObject({
      ...stateObj,
      loader: {
        ...stateObj.loader,
        show: showLoader
      }
    });
  };
  const onTextFieldChange = (event: any, key: string) => {
    setStateObject({
      ...stateObj,
      [key]: event.target.value as string
    });
  };
  const onHandleSubmit = () => {
    showLoader(true);
    AppSMService.axios
      .post(`study/${study?.id}/signoff`, {
        user: user?.username
      })
      .then(() => {
        handleErrorMessageUI('SignOff successful ', false, true);
      })
      .finally(() => {
        setTimeout(() => {
          showLoader(false);
          handleClose();
        }, 2000);
      });
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={stateObj.open}
        fullWidth={true}
        maxWidth={'md'}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Container style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
            <TextField
              type="text"
              style={{ width: '80%' }}
              label="Enter Password"
              variant="outlined"
              autoFocus
              value={stateObj.password}
              onChange={(e) => onTextFieldChange(e, 'password')}
            />
          </Container>
          {stateObj.loader.show && (
            <Loader open={stateObj.loader.show} handleClose={() => showLoader(false)} />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onHandleSubmit}>
            Sign off
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {stateObj.snackbar.show && (
        <AlertMessage
          openAlert={stateObj.snackbar.show}
          openAlertMsg={stateObj.snackbar.message}
          openAlertStatus={stateObj.snackbar.isError ? 'error' : 'success'}
          handleCloseAlert={() => handleErrorMessageUI('', false, false)}
        />
      )}
    </React.Fragment>
  );
}
