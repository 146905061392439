import React from "react";
import { useNavigate } from "react-router-dom";
import { InitialStateTypes, resetStateData } from "../../../store/slices/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { STORE_CONFIG } from "../../../store/storeConstant";

function Logout() {
  const navigate = useNavigate();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const dispatch=useAppDispatch();
  const { study, } = appDataContext;
  console.log("Study",study)
  React.useEffect(() => {
    logoutStudy();
    localStorage.removeItem("token");
    navigate("/");
  }, []);

  const logoutStudy = () => {
    dispatch(resetStateData());
  };

  return <div>logging out...</div>;
}

export default Logout;
