import React, { useState, useRef } from 'react';
import {
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  FormControl,
  Box,
  Modal,
  AppBar,
  Typography,
  TextField,
  Stack
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Toolbar from '@mui/material/Toolbar';
import MoreIcon from '@mui/icons-material/MoreVert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import Alert from '../../molecules/Alert/Alert';
import { useStudyAccessRights } from '../../../utils/useStudyAccessRights';
import { BREAD_CRUMBS, ROUTING_CONSTANTS, TEXT_CONSTANTS, useDataValidation } from '../../../utils';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { StudySubjectState } from './types';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
import { useAppSelector } from '../../../hooks';
import { AppSMService } from '../../../network/useAxios';
const Studysubject = () => {
  const editID = useRef(0);
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { client, study } = appDataContext;
  const { HOME, SUBJECT: BSUBJECT, STUDY_MANAGEMENT } = BREAD_CRUMBS;
  const { DASHBOARD, STUDY: RSTUDY } = ROUTING_CONSTANTS;
  const { META_DATA, SUBJECT, STUDY } = NETWORK_CONSTANTS.END_POINTS;
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: STUDY_MANAGEMENT, link: `${RSTUDY}${client.id}/${study.id}` },
    { title: BSUBJECT, link: '' }
  ];
  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SOMETHING_WENT_WRONG, SUCCESS },
      STUDY_PAGE: { SUBJECT_CREATE_SUCCESS, SUBJECT_UPDATE_SUCCESS }
    }
  } = TEXT_CONSTANTS;
  const { checkIsEmpty } = useDataValidation();
  const [studySubjectState, setStudySubjectState] = useState<StudySubjectState>({
    siteList: [],
    siteID: '',
    options: [],
    metaDataMainList: [],
    open: false,
    fieldName: [],
    fieldValue: [],
    metaDataList: [],
    openDataModle: false,
    randUpdate: false,
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: '',
    anchorEl: null
  });
  const {
    anchorEl,
    fieldName,
    fieldValue,
    metaDataList,
    metaDataMainList,
    open,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    openDataModle,
    options,
    randUpdate,
    siteID,
    siteList
  } = studySubjectState;

  const { createSubjectMetaDataAccessRights } = useStudyAccessRights();
  React.useEffect(() => {
    loadSite();
    loadMetadata();
  }, []);
  React.useEffect(() => {
    loadStudyConfigData();
  }, [siteID]);
  React.useEffect(() => {
    loadDatas(metaDataMainList, 'No');
  }, [randUpdate]);
  const updateState = (data: any) => {
    setStudySubjectState((prevState: StudySubjectState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadMetadata = () => {
    AppSMService.axios
      .get(`${META_DATA}?type=SUBJECT&studyId=${study.id}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          updateState({ metaDataMainList: data });
          loadDatas(data, 'Yes');
        }
      });
  };

  const loadDatas = (fielddata: any, type: string) => {
    const existFieldList: any = [];
    const field_Name: any = [];
    if (fielddata.length > 0) {
      if (fielddata[0].data.length > 0) {
        fielddata[0].data.map((item: any) => {
          field_Name.push({
            field: item.name,
            headerName: item.label,
            width: 200,
            editable: true
          });
          existFieldList.push({
            name: item.name,
            errorname: false,
            label: item.label,
            errorlabel: false,
            type: item.type,
            errortype: false,
            value: '',
            errorvalue: false
          });
        });
      }
      field_Name.push({
        field: 'action',
        headerName: 'Action',
        width: 250,
        renderCell: (params: GridRenderCellParams): React.ReactNode => {
          return (
            <>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => handleClick(event, params)}>
                <MoreIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}>
                <MenuItem onClick={handleEditSubject}>Edit</MenuItem>
                <MenuItem /* onClick={handleRemove} */>Remove</MenuItem>
              </Menu>
            </>
          );
        }
      });
    }
    updateState({
      fieldName: field_Name,
      fieldValue: type === 'yes' ? existFieldList : fieldValue
    });
  };
  const handleEditSubject = () => {
    AppSMService.axios.get(`${SUBJECT}/${editID.current}`).then(({ status, data }) => {
      let updFieldList: any = [];
      if (status === HTTP_STATUS_CODE.OK) {
        const metaResult = { ...data.data };
        updFieldList = fieldValue.map((item: any) => {
          return (item['value'] = !checkIsEmpty(metaResult[item.name])
            ? metaResult[item.name]
            : '');
        });
      }
      updateState({ fieldValue: updFieldList, open: alert, randUpdate: Math.random() });

      handleopenDataPopup();
      loadDatas(metaDataMainList, 'No');
    });
  };
  const updateValue = (value: any, updIndex: number) => {
    const _fieldValue: any = fieldValue.map((item: any, index: number) => {
      if (index === updIndex) {
        item['value'] = value;
        item['errorvalue'] = value === '' ? true : false;
      }
      return item;
    });
    updateState({ fieldValue: _fieldValue });
  };
  const handleopenDataPopup = () => {
    updateState({ openDataModle: true });
  };

  const addSiteValue = async () => {
    let _fieldValue = '{';
    fieldValue.forEach((item: any, index: number) => {
      _fieldValue += `"${item.name}":"${item.value}"`;
      if (index !== fieldValue.length - 1) {
        _fieldValue += ',';
      }
    });
    _fieldValue += '}';
    let response: any = { status: HTTP_STATUS_CODE.OK };
    const requestData = {
      studyId: study.id,
      siteId: siteID,
      data: JSON.parse(_fieldValue),
      dbMode: study.mode
    };
    if (editID.current !== 0) {
      response = await AppSMService.axios.patch(`subject/${editID.current}`, requestData);
    } else {
      response = await AppSMService.axios.post(`subject`, requestData);
    }
    if (HTTP_STATUS_CODE.OK === response.status) {
      updateState({
        openAlert: true,
        openAlertStatus: SUCCESS,
        openAlertMsg: !checkIsEmpty(editID.current)
          ? SUBJECT_UPDATE_SUCCESS
          : SUBJECT_CREATE_SUCCESS
      });
      loadStudyConfigData();
      handlePopupClose();
      AutoCloseAlert();
    } else {
      updateState({
        openAlert: true,
        openAlertStatus: ERROR,
        openAlertMsg: SOMETHING_WENT_WRONG
      });
    }
  };
  const handlePopupClose = () => {
    updateState({
      openDataModle: false
    });
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    editID.current = params.row.id;
    updateState({
      open: !open,
      anchorEl: event.currentTarget,
      randUpdate: Math.random()
    });
  };

  const loadSite = () => {
    AppSMService.axios.get(`${STUDY}/${study.id}/config?type=SITE`).then(({ status, data }) => {
      if (status === HTTP_STATUS_CODE.OK) {
        if (data.length > 0) {
          const keysList = Object.keys(data[0].data);
          const updatedSite: any = [...data].map((item: any) => {
            return { id: item.id, name: item.data[keysList[0]] };
          });
          updateState({ siteList: updatedSite });
        }
      }
    });
  };
  const handleTextValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    updIndex: number
  ) => {
    const value = event.target.value;
    updateValue(value, updIndex);
  };
  const handleSelectFieldChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { value }
    } = event;
    updateState({ siteID: value });
  };

  const loadStudyConfigData = () => {
    AppSMService.axios
      .get(`${SUBJECT}?siteId=${siteID}&dbMode=${study.mode}`)
      .then(({ data, status }) => {
        const updatedMetaData: any = [];
        if (status === HTTP_STATUS_CODE.OK) {
          if (data.length > 0) {
            data.map((item: any) => {
              const metadataList = item.data;
              metadataList['id'] = item.id;
              updatedMetaData.push(metadataList);
            });
          }
        }
        updateState({ metaDataList: updatedMetaData });
      });
  };

  const handleCloseAlert = () => {
    updateState({ openAlert: false, openAlertStatus: '', openAlertMsg: '' });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({ openAlert: false, openAlertStatus: '', openAlertMsg: '' });
    }, 3000);
  };
  const columns: GridColDef[] = fieldName;
  const rowsUpdated = metaDataList;
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2
          }}>
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="demo-simple-select-helper-label">Site</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              onChange={handleSelectFieldChange}
              name="site">
              {siteList.map((item: any, index: number) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {siteID !== '' && (
            <>
              {createSubjectMetaDataAccessRights && (
                <Box mb={2} mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
                  <Button variant="contained" onClick={handleopenDataPopup}>
                    Add Subject
                  </Button>
                </Box>
              )}
              <div style={{ height: 400, width: '100%', marginTop: '5px' }}>
                <DataGrid
                  rows={rowsUpdated}
                  columns={columns}
                  editMode="row"
                  disableRowSelectionOnClick
                  disableColumnSelector
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 }
                    }
                  }}
                  pageSizeOptions={[5, 10]}
                />
              </div>
            </>
          )}
        </Paper>
      </Grid>
      <Modal
        open={openDataModle}
        /*  onClose={handleopenPopup} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24
          }}>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {editID.current !== 0 ? 'Update' : 'Add'} Subject
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'scroll',
              overflowX: 'none',
              height: '70%'
            }}>
            <FormControl
              sx={{
                m: 1,
                width: '90%',
                minHeight: '80px',
                marginTop: '10px',
                marginLeft: '20px',
                paddingRight: '50px'
              }}>
              {fieldValue.length > 0 &&
                fieldValue.map((item: any, index: number) => {
                  return (
                    <Grid item xs={12} key={index} sx={{ mb: 2 }}>
                      {(item.type == 'Text' || item.type == 'Number') && (
                        <TextField
                          label={item.label}
                          value={item.value}
                          type={item.type == 'Number' ? 'number' : 'text'}
                          onChange={(event) => handleTextValueChange(event, index)}
                          sx={{ width: '100%' }}
                          variant="standard"
                          error={item.errorname}
                          helperText={item.errorname === true && 'This field is required'}
                        />
                      )}
                      {item.type === 'Date' && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            onChange={(date) => {
                              updateValue(new Date(date), index);
                            }}
                            value={item.value}
                          />
                        </LocalizationProvider>
                      )}
                      {item.type === 'Time' && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Basic time picker"
                            sx={{ width: '100%' }}
                            onChange={(date) => {
                              updateValue(new Date(date), index);
                            }}
                            value={item.value}
                          />
                        </LocalizationProvider>
                      )}
                    </Grid>
                  );
                })}
            </FormControl>
          </Box>
          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            spacing={2}
            pt={4}
            pb={2}
            pr={5}>
            <Button variant="outlined" onClick={handlePopupClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={addSiteValue}>
              {editID.current !== 0 ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Studysubject;
