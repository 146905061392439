import React from "react";
import { Box } from "@mui/system";
import { FORMFIELDSTATUS} from "../../../models/Common";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import "./FormViewerFieldStatus.css";
interface IProps{
    currentStatus: string;
    handleStatusChange: (status: FORMFIELDSTATUS) => void;
    showPointer?:boolean;
}
const FormViewerFieldStatus = ({currentStatus,handleStatusChange,showPointer=true}: IProps) => {
    const updateFormFieldStatus = (status: FORMFIELDSTATUS) => {
      handleStatusChange(status);
    }
    return (
        <Box sx={{width: "10%", display: "flex", justifyContent:"space-evenly", padding: "10px",  alignItems:"center"}} data-selected-status={currentStatus} className="statusIcon">
            <Box title="Approve" sx={showPointer ? {cursor: "pointer"}:{}} onClick={() => updateFormFieldStatus(FORMFIELDSTATUS.APPROVED)}><CheckCircleOutlineOutlinedIcon className={`approved ${currentStatus === FORMFIELDSTATUS.APPROVED  ? 'active' : ''}`}/></Box>
            <Box title="Reject" sx={showPointer ? {cursor: "pointer"}:{}} onClick={() => updateFormFieldStatus(FORMFIELDSTATUS.REJECTED)}><CancelOutlinedIcon className={`rejected ${currentStatus === FORMFIELDSTATUS.REJECTED  ? 'active' : ''}`}/></Box>
        </Box>
    )
}

export default FormViewerFieldStatus;