import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  Box,
  Typography,
  FormControl,
  TextField,
  Stack,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";
import MoreIcon from "@mui/icons-material/MoreVert";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import { AppSMService } from '../../../network/useAxios';
import Alert from "../../molecules/Alert/Alert";
import Dialog from "../../molecules/Dialog/Dialog";
import {
  BREAD_CRUMBS,
  ROUTING_CONSTANTS,
  TEXT_CONSTANTS,
  useDataValidation,
} from "../../../utils";
import { useAppDispatch } from "../../../hooks";
import {
  updateProjectData,
} from "../../../store/slices/globalSlice";
import { ProjectState } from "./types";
import { NETWORK_CONSTANTS } from "../../../network/networkConstants";
import { PROJECT_GRID_COLUMN_CLIENT } from "./utils";

const Projects = () => {
  const navigate = useNavigate();
  const { sponsorId } = useParams();
  const { HOME, PROJECT_MANAGEMENT, SPONSOR } = BREAD_CRUMBS;
  const { DASHBOARD, SPONSORS, STUDY } = ROUTING_CONSTANTS;
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: SPONSOR, link: SPONSORS },
    { title: PROJECT_MANAGEMENT, link: "" },
  ];
  const dispatch: any = useAppDispatch();
  
  const { checkIsEmpty } = useDataValidation();
  const {
    VALIDATION_TEXT: {
      PROJECT_PAGE: {
        PROJECT_UPDATE_SUCCESS,
        PROJECT_CREATE_SUCCESS,
        PROJECT_DELETION_SUCCESS,
        PROJECT_DELETION_ERROR,
        DIALOG_CONFIRM_TO_REMOVE_STUDY,
      },
      COMMON: { SUCCESS, ERROR, SOMETHING_WENT_WRONG },
    },
  } = TEXT_CONSTANTS;
  const [projectState, setProjectState] = useState<ProjectState>({
    gridLoading: false,
    openAlert: false,
    openAlertMsg: "",
    openAlertStatus: "",
    openDialog: false,
    btnloading: false,
    projectList: [],
    projectName: "",
    projectNameError: false,
    type: "Add",
    openModal: false,
  });
  const {
    btnloading,
    gridLoading,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    openDialog,
    projectList,
    projectName,
    projectNameError,
    openModal,
  } = projectState;
  const { PROJECT } = NETWORK_CONSTANTS.END_POINTS;

  const editID = useRef(0);
  const selectprojectName = useRef("");

  React.useEffect(() => {
    loadProject();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateState = (data: any) => {
    setProjectState((prevState:ProjectState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadProject = () => {
    AppSMService.axios
      .get(`${PROJECT}?sponsorId=${sponsorId}`)
      .then(({ status, data }) => {
        updateState({
          projectList: data.length > 0 && status === 200 ? data : [],
          gridLoading: false,
        });
      })
      .catch((e) => {
        updateState({ gridLoading: false });
      });
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    if (name === "projectName") {
      updateState({
        projectName: value,
        projectNameError: false,
      });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    editID.current = params.row.id;
    selectprojectName.current = params.row.label;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleopenPopup = () => {
    updateState({
      openModal: !openModal,
    });
  };

  const handleopenNewPopup = () => {
    editID.current = 0;
    selectprojectName.current = "";
    updateState({
      projectName: "",
      type: "Add",
      projectNameError: false,
      openModal: true,
    });
  };

  const handleClickStudy = () => {
    dispatch(
      updateProjectData({
        id: editID.current,
        name: selectprojectName.current,
      })
    );

    navigate(`${STUDY}${sponsorId}/${editID.current}`);
  };
  const columns: GridColDef[] = [
    ...PROJECT_GRID_COLUMN_CLIENT,

    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, params)}
            >
              <MoreIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClickOpen}>Edit</MenuItem>
              <MenuItem onClick={handleRemove}>Remove</MenuItem>
              <MenuItem onClick={(event) => handleClickStudy()}>Study</MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const handleClickOpen = () => {
    AppSMService.axios
      .get(`${PROJECT}/${editID.current}`)
      .then(({ status, data }) => {
        if (status === 200) {
          updateState({
            projectName: data.label,
            type: "Edit",
            projectNameError: false,
            openModal: true,
          });
          handleopenPopup();
          handleClose();
        }
      });
  };
  const handleRemove = () => {
    updateState({
      openDialog: true,
    });
    setAnchorEl(null);
  };
  function checkPreValidationForSubmitProject() {
    if (checkIsEmpty(projectNameError)) {
      updateState({ projectNameError: true });
      return true;
    }
    return false;
  }
  const submitProject = () => {
    if (checkPreValidationForSubmitProject()) {
      updateState({ btnloading: true });
      if (editID.current !== 0) {
        AppSMService.axios
          .patch(`${PROJECT}/${editID.current}`, {
            sponsorId: sponsorId,
            label: projectName,
          })
          .then(({ status }) => {
            if (status === 200) {
              updateState({
                openAlert: true,
                openAlertStatus: SUCCESS,
                openAlertMsg: PROJECT_UPDATE_SUCCESS,
                btnloading: false,
              });

              loadProject();
              handleopenPopup();
            } else {
              updateState({
                openAlert: true,
                openAlertStatus: ERROR,
                openAlertMsg: SOMETHING_WENT_WRONG,
                btnloading: false,
              });
            }
            AutoCloseAlert();
          })
          .catch((e) => {
            updateState({
              openAlert: true,
              openAlertStatus: ERROR,
              openAlertMsg: SOMETHING_WENT_WRONG,
              btnloading: false,
            });
            AutoCloseAlert();
          });
      } else {
        AppSMService.axios
          .post(PROJECT, {
            sponsorId: sponsorId,
            label: projectName,
          })
          .then((res) => {
            if (res.status === 200) {
              updateState({
                openAlert: true,
                openAlertStatus: SUCCESS,
                openAlertMsg: PROJECT_CREATE_SUCCESS,
                btnloading: false,
              });
              loadProject();
              handleopenPopup();
            } else {
              updateState({
                openAlert: true,
                openAlertStatus: ERROR,
                openAlertMsg: SOMETHING_WENT_WRONG,
                btnloading: false,
              });
            }
            AutoCloseAlert();
          })
          .catch((e) => {
            updateState({
              openAlert: true,
              openAlertStatus: ERROR,
              openAlertMsg: SOMETHING_WENT_WRONG,
              btnloading: false,
            });
            AutoCloseAlert();
          });
      }
    }
  };

  const handleCloseDialog = () => {
    updateState({
      openDialog: false,
    });
  };
  const handleAcceptDialog = () => {
    AppSMService.axios
      .delete(`project/${editID.current}`)
      .then((res) => {
        if (res.status === 202) {
          loadProject();
          updateState({
            openDialog: false,
            openAlert: true,
            openAlertStatus: SUCCESS,
            openAlertMsg: PROJECT_DELETION_SUCCESS,
            gridLoading: false,
          });
        }
        AutoCloseAlert();
      })
      .catch((e) => {
        updateState({
          openDialog: false,
          openAlert: true,
          openAlertStatus: ERROR,
          openAlertMsg: PROJECT_DELETION_ERROR,
          gridLoading: false,
        });
        AutoCloseAlert();
      });
  };

  const handleCloseAlert = () => {
    updateState({
      openAlert: false,
      openAlertStatus: "",
      openAlertMsg: "",
    });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert: false,
        openAlertStatus: "",
        openAlertMsg: "",
      });
    }, 3000);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            mb={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button variant="contained" onClick={handleopenNewPopup}>
              Add Project
            </Button>
          </Box>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={projectList}
              columns={columns}
              disableRowSelectionOnClick
              disableColumnSelector
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              loading={gridLoading}
              localeText={{
                noRowsLabel:
                  gridLoading === false && projectList.length === 0
                    ? `No Study Found`
                    : "",
              }}
            />
          </div>
        </Paper>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleopenPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Add New Project
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <FormControl
            sx={{
              m: 1,
              width: "80%",
              marginTop: "30px",
              marginLeft: "20px",
            }}
          >
            <Grid item xs={12}>
              <TextField
                label="Project Name"
                value={projectName}
                variant="standard"
                name="projectName"
                sx={{ marginRight: "20px", width: "60%" }}
                onChange={handleTextFieldChange}
                error={projectNameError}
                helperText={projectNameError && "This field is required"}
              />
            </Grid>
          </FormControl>
          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            spacing={2}
            pt={4}
            pb={2}
            pr={5}
          >
            <Button variant="outlined" onClick={handleopenPopup}>
              Cancel
            </Button>
            <LoadingButton
              onClick={submitProject}
              loading={btnloading}
              loadingPosition="start"
              startIcon={<SaveOutlined />}
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      <Dialog
        openDialog={openDialog}
        dialogMsg={DIALOG_CONFIRM_TO_REMOVE_STUDY}
        dialogHeader={"Alert"}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Projects;
