export interface IActionItem {
  id: number;
  name: string;
  code: string;
  show: boolean;
  pathName:string;
}

export enum TAB_NAME {
  OND = "On-Demand",
  SCH = "Scheduled",
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface IExtractData {
  id: number;
  name: string;
  value: string;
}
export interface IHistoryItem {
  id: string | number;
  fieldId: string;
  formId: string;
  reviewedBy: string;
  reviewStatus: string;
  studyId: string;
  dbMode: string;
  createdOn: string;
  modifiedOn: string;
  formName:string;
  fieldName :string
}

