import React, { useState } from "react";
import { Grid, Paper, Button, TextField, Box } from "@mui/material";

import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import Alert from "../../molecules/Alert/Alert";
import {
  BREAD_CRUMBS,
  ROUTING_CONSTANTS,
  TEXT_CONSTANTS,
  useDataValidation,
} from "../../../utils";
import { ChangePasswordState } from "./types";
import { AppUMService } from "../../../network/useAxios";
import { NETWORK_CONSTANTS } from "../../../network/networkConstants";
import { InitialStateTypes } from "../../../store/slices/globalSlice";
import { STORE_CONFIG } from "../../../store/storeConstant";
import { useAppSelector } from "../../../hooks";
const Changepassword = () => {
  const { checkIsEmpty, checkIsEqual } = useDataValidation();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { user} = appDataContext;
  const breadCrumbList = [
    { title: BREAD_CRUMBS.HOME, link: ROUTING_CONSTANTS.DASHBOARD },
    { title: BREAD_CRUMBS.CHANGE_PASSWORD, link: "" },
  ];
  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SUCCESS, FIELD_REQUIRED, SOMETHING_WENT_WRONG },
      LOGIN: { PASSWORD_NOT_MATCHING, PASSWORD_UPDATED_SUCCESSFULLY },
    },
    COMMON: {
      FORM: {
        ELEMENT_TYPE: { TYPE_PASSWORD },
      },
    },
    FORM: {
      CHANGE_PASSWORD: {
        CONFIRM_PASSWORD,
        OLD_PASSWORD,
        RESET_PASSWORD,
        CONFIRM_PASSWORD_LABEL,
        OLD_PASSWORD_LABEL,
        PASSWORD_LABEL,
      },
    },
  } = TEXT_CONSTANTS;
  const { USERS } = NETWORK_CONSTANTS.END_POINTS;
  const [changePasswordState, setChangePasswordState] =
    useState<ChangePasswordState>({
      openAlert: false,
      openAlertMsg: "",
      openAlertStatus: "",
      oldPWDError: false,
      resetConfPWDError: "",
      resetPWDError: false,
    });
  const {
    oldPWDError,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    resetConfPWDError,
    resetPWDError,
  } = changePasswordState;

  const updateState = (data: any) => {
    setChangePasswordState((prevState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    if (name === RESET_PASSWORD) {
      updateState({
        resetPWDError: checkIsEmpty(value) ? true : false,
      });
    } else if (name === CONFIRM_PASSWORD) {
      updateState({
        resetConfPWDError: checkIsEmpty(value) ? FIELD_REQUIRED : "",
      });
    } else if (name === OLD_PASSWORD) {
      updateState({
        oldPWDError: checkIsEmpty(value) ? true : false,
      });
    }
  };
  function checkPreValidationForChangePassword(formData: FormData) {
    const restPassword = formData.get(RESET_PASSWORD);
    const oldPassword = formData.get(OLD_PASSWORD);
    const confirmPassword = formData.get(CONFIRM_PASSWORD);
    if (checkIsEmpty(oldPassword)) {
      updateState({
        oldPWDError: !checkIsEmpty(oldPassword) ? true : false,
      });
      return false;
    } else if (checkIsEmpty(restPassword)) {
      updateState({
        resetPWDError: !checkIsEmpty(oldPassword) ? true : false,
      });
      return false;
    } else if (checkIsEmpty(confirmPassword)) {
      updateState({
        resetConfPWDError: FIELD_REQUIRED,
      });
      return false;
    } else if (!checkIsEqual(restPassword, confirmPassword)) {
      updateState({
        resetConfPWDError: PASSWORD_NOT_MATCHING,
      });
      return false;
    }
    return true;
  }
  const handleSubmitResetPwd = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const resetPassword = formData.get(RESET_PASSWORD);
    if (checkPreValidationForChangePassword(formData)) {
      AppUMService.axios
        .put(`${USERS}${user?.id}`, {
          password: resetPassword,
          firstLogin: false,
        })
        .then(() => {
          updateState({
            openAlert: true,
            openAlertStatus: SUCCESS,
            openAlertMsg: PASSWORD_UPDATED_SUCCESSFULLY,
          });

          AutoCloseAlert();
        })
        .catch((e) => {
          updateState({
            openAlert: true,
            openAlertStatus: ERROR,
            openAlertMsg: SOMETHING_WENT_WRONG,
          });
          AutoCloseAlert();
        });
    }
  };

  const handleCloseAlert = () => {
    updateState({
      openAlert: false,
      openAlertStatus: "",
      openAlertMsg: "",
    });

    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert: false,
        openAlertStatus: "",
        openAlertMsg: "",
      });
    }, 3000);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="login-form"
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmitResetPwd}
              sx={{ mt: 1 }}
              className="login-form"
            >
              <TextField
                label={OLD_PASSWORD_LABEL}
                variant="outlined"
                name={OLD_PASSWORD}
                id={OLD_PASSWORD}
                type={TYPE_PASSWORD}
                sx={{
                  width: "100%",
                  marginTop: "20px",
                  background: "#FFFFFF",
                }}
                onChange={handleTextFieldChange}
                error={oldPWDError}
                helperText={oldPWDError === true && FIELD_REQUIRED}
              />
              <TextField
                label={PASSWORD_LABEL}
                variant="outlined"
                name={RESET_PASSWORD}
                id={RESET_PASSWORD}
                type={TYPE_PASSWORD}
                sx={{
                  width: "100%",
                  marginTop: "20px",
                  background: "#FFFFFF",
                }}
                onChange={handleTextFieldChange}
                error={resetPWDError}
                helperText={resetPWDError === true && FIELD_REQUIRED}
              />

              <TextField
                label={CONFIRM_PASSWORD_LABEL}
                variant="outlined"
                name={CONFIRM_PASSWORD}
                id={CONFIRM_PASSWORD}
                type={TYPE_PASSWORD}
                sx={{ width: "100%", marginTop: "20px" }}
                onChange={handleTextFieldChange}
                error={resetConfPWDError !== "" ? true : false}
                helperText={resetConfPWDError}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Change Password
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Changepassword;
