import { List, ListItem, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";

import { FORMSTATUS } from "../../../models/ClinicalForm";
import { AppSMService } from '../../../network/useAxios';
import "./FormsList.css";
import { useAppSelector } from "../../../hooks";
import { InitialStateTypes } from "../../../store/slices/globalSlice";
import { STORE_CONFIG } from "../../../store/storeConstant";
interface IProps {
  listData?: any[];
  handleSelectedItem?: (value: string) => void;
  selectedFormId?: string;
  isReviewer?: boolean;
}
const FormsList = (props: IProps) => {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const {study,forms } = appDataContext;
  const [formsList, setFormsList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isFormReviewer = () => {
    return props.isReviewer === true;
  };
  const loadForms = () => {
    setIsLoading(true);
    let url = `forms?studyId=${study?.id}`;
    if(isFormReviewer()){
      url += `&status=${FORMSTATUS.PUBLISHED},${FORMSTATUS.REJECTED},${FORMSTATUS.APPROVED}`;
    }
    AppSMService.axios
      .get(url)
      .then((res) => {
        if (res?.data && res.data.length > 0) {
          setFormsList(res?.data || []);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(`Error in fetching forms list - ${e}`);
        setFormsList(forms || []);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    loadForms();
  }, []);
  return (
    <>
      {isLoading ? "Fetching Forms ..." : ""}
      {formsList && formsList.length > 0 && (
        <List component="nav">
          {formsList.map((item: any) => {
            return (
              <ListItem
               key={item.id}
                className={item.id === props.selectedFormId ? "selected" : ""}
                onClick={() => props?.handleSelectedItem?.(item.id)}
              >
                <ListItemText
                  primary={item.label || item.name}
                  title={item.label || item.name}
                ></ListItemText>
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
};
export default FormsList;
