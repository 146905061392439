import { GridColDef } from "@mui/x-data-grid";

export interface ILogformModalProps {
  showEditModal: any;
  showFormModalEdit: boolean;
  logFormFields: any;
  logFormRows: any;
  deFormId: any;
  formId: any;
  logFormMode?: string;
  loadFormData?: any;
}
export interface ILogFormData {
  fieldId: string;
  fieldData: string;
}
export interface ILogFormRequest {
  deformId: string;
  formId?: string;
  logFormData: ILogFormData[];
}

export enum LOG_FORM_MODE {
  ADD = "ADD",
  EDIT = "EDIT",
}

export interface LogEntryFormGridState{
  showFormModalEdit:boolean;
  logFormFields:Array<any>;
  logFormColumns:Array<GridColDef>;
  logFormRows:Array<any>;
  selectedRowId:string;
  logFormMode:string;
  dummy:string | null;
  isReviewCommentsOpen:boolean;
  selectedFieldId:string;
}