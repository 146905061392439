import { IActionItem } from "../../organisms/ActionItems";

export interface userType {
  isDataEntryReviewer: boolean;
}

export interface DataEntryFilterType {
  value: string;
  options: any;
  type: string;
  id: string;
  label: string;
  visibility: boolean;
}

export type IColumn = {
  id: string;
  label: string;
  minWidth: number;
};
export type IRows = {
  id: number;
  formId: string;
  status: string;
  visitdate: string;
  previousvisit: string;
  location: string;
  registered: string;
};
export interface ICusomTable {
  columns: IColumn[];
  row: IRows;
}

export enum E_CONFIG_TYPE {
  site = "SITE",
  subject = "SUBJECT",
  visit = "VISIT",
}

export interface IDeForms {
  createdOn: string;
  dbMode: string;
  formId: string;
  formName: string;
  id: string;
  logForm: boolean;
  modifiedOn: string;
  order: number;
  siteId: string;
  specialForm: boolean;
  status: string;
  studyId: string;
  subjectId: string;
  visitId: string;
  visitOrder: number;
}

export interface DataEntryGridState{
    loader:boolean;
    formList:Array<IDeForms>;
    filterList:Array<DataEntryFilterType>;
    actionItemList:Array<IActionItem>;
}

export enum DATA_ENTRY_ACTION_ITEM{
  EXTRACT_DATA="ED",
  COMPARE_DATA="CD",
  SIGN_OFF="SO",
  HISTORY="HT"
}