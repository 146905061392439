import { GridColDef } from "@mui/x-data-grid";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
export interface QuerymanagementState {
  queryList: Array<any>;
  userList: Array<any>;
  options: Array<string>;
  assignUser: any;
  unassignUser: any;
  assignUserError: boolean;
  btnloading: boolean;
  openAlert: boolean;
  openAlertMsg: string;
  openAlertStatus: string;
  totalCount: number;
  unAssignedCount: number;
  assignedCount: number;
  open1:boolean;
  anchorEl:null | HTMLElement
}

export const QUERY_MANAGEMENT_GRID_COLUM:GridColDef[]=[
{
  field: 'id',
  headerName: 'Query ID',
  width: 250,
  editable: false
},
{
  field: 'comments',
  headerName: 'Comments',
  width: 300,
  editable: false
},
{
  field: 'assignee',
  headerName: 'Assigne',
  width: 200,
  editable: false
},
{
  field: 'formName',
  headerName: 'From Name',
  width: 200,
  editable: false
},
{
  field: 'modifiedOn',
  headerName: 'Update On',
  width: 250,
  editable: false
}]