import { IActionItem } from "./types";

export const ActionList:IActionItem[] = [
  { id: 1, name: "Extract Data", code: "ED", show: false,pathName:"/clinicalApp/dataentry" },
  { id: 2, name: "Compare Data", code: "CD", show: false,pathName:"/clinicalApp/dataEntryReviewer" },
  { id: 3, name: "History", code: "HT", show: false ,pathName:"/clinicalApp/dataEntryReviewer"},
  { id: 4, name: "Sign Off", code: "SO", show: false,pathName:"/clinicalApp/dataEntryReviewer" },
];

export const COMPARE_DATA_COLUMN = [
  {
    field: "id",
    headerName: "Form Id",
    width: 210,
    editable: false,
    hideable: true,
  },
  {
    field: "form",
    headerName: "Form",
    width: 210,
    editable: false
  },
  {
    field: "formName",
    headerName: "Field",
    width: 210,
    editable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    editable: false,
  },
];

export const COMPARE_DATA_VISIBILITY = {
  // Hide columns status and traderName, the other columns will remain visible
  id: false,
};
