import React, { useState, useRef } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  FormHelperText,
  Toolbar,
  Typography
} from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '../../molecules/Alert/Alert';
import { AppSMService,AppUMService as AppService } from '../../../network/useAxios';
import { QUERY_MANAGEMENT_GRID_COLUM, QuerymanagementState } from './types';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
import { useAppSelector } from '../../../hooks';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { BREAD_CRUMBS, ROUTING_CONSTANTS, TEXT_CONSTANTS } from '../../../utils';


const Querymanagement = () => {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  
  const { study } = appDataContext;
  const {HOME,QUERY_MANAGEMENT}=BREAD_CRUMBS;
  const { LOG_ENTRY, DATA_ENTRY_REVIWER_FORM, FORMS,DASHBOARD } = ROUTING_CONSTANTS;
  const { QUERY, USERS, DE_FORMS, QUERY_ASSIGN } = NETWORK_CONSTANTS.END_POINTS;
  const {
    VALIDATION_TEXT: {
      COMMON: { ERROR, SUCCESS, SOMETHING_WENT_WRONG },
      QUERY_MANAGEMENT_PAGE: { ASSIGNED_USER_SUCCESS }
    }
  } = TEXT_CONSTANTS;
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: QUERY_MANAGEMENT, link: '' }
  ];
  const navigate = useNavigate();
  const editID = useRef('');
  const formID = useRef('');
  const fieldID = useRef('');

  const queryType = useRef('');

  const [querymanagementState, setQuerymanagementState] = useState<QuerymanagementState>({
    queryList: [],
    userList: [],
    options: [],
    assignUser: '',
    unassignUser: '',
    assignUserError: false,
    btnloading: false,
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: '',
    totalCount: 0,
    unAssignedCount: 0,
    assignedCount: 0,
    open1: false,
    anchorEl: null
  });
  const {
    assignUser,
    assignUserError,
    assignedCount,
    btnloading,
    openAlert,
    openAlertMsg,
    openAlertStatus,
    options,
    queryList,
    totalCount,
    unAssignedCount,
    unassignUser,
    userList,
    anchorEl,
    open1
  } = querymanagementState;
  React.useEffect(() => {
    loadDataEntry();
    loadStatisticsData();
    loadUsers();
  }, []);
  const updateState = (data: any) => {
    setQuerymanagementState((prevState: QuerymanagementState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadDataEntry = () => {
    AppSMService.axios
      .get(`${QUERY}?studyId=${study.id}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          updateState({ queryList: data.length > 0 ? data : [] });
        }
      })
      .catch((e) => {
        console.log(`Error in creating new field - ${e}`);
      });
  };

  const loadStatisticsData = () => {
    AppSMService.axios
      .get(`${QUERY}/statistics?studyId=${study.id}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          const { totalCount, unAssignedCount } = data;
          const assigned_Count = parseInt(totalCount) - parseInt(unAssignedCount);
          updateState({
            totalCount: parseInt(totalCount),
            unAssignedCount: parseInt(unAssignedCount),
            assignedCount: assigned_Count
          });
        }
      })
      .catch((e) => {
        console.log(`Error in creating new field - ${e}`);
      });
  };

  const loadUsers = () => {
    AppService.axios.get(`${USERS}?userType=User&sortBy=createdDate`).then(({ status, data }) => {
      if (status === 200) {
        updateState({ userList: data.length > 0 ? data : [] });
      }
    });
  };

  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    const {
      row: { id, formId, type, fieldId }
    } = params;
    editID.current = id;
    formID.current = formId;
    queryType.current = type;
    fieldID.current = fieldId;
    updateState({ anchorEl: event.currentTarget });
  };
  const handleClose = () => {
    updateState({ anchorEl: null });
  };

  const handleClickOpen = (ID: string) => {
    if (ID === '2') {
      updateState({ unassignUser: 'Yes' });
      handleAssignUser();
    } else {
      updateState({ unassignUser: 'No', assignUserError: false, open: true, anchorEl: null });
    }
  };
  const viewQueryDetails = () => {
    if (queryType.current === 'data') {
      AppSMService.axios
        .get(`/${DE_FORMS}/${formID.current}`)
        .then(({ status, data }) => {
          if (status === HTTP_STATUS_CODE.OK) {
            const _navigationState: any = {
              forms: [],
              isLogForm: true,
              formId: data?.formId,
              deFormId: formID.current,
              selectedForm: data,
              fieldCommentID: fieldID.current
            };

            navigate(data?.logForm ? LOG_ENTRY : DATA_ENTRY_REVIWER_FORM, {
              state: data?.logForm
                ? _navigationState
                : { ..._navigationState, isDataEntryOperator: true }
            });
          }
        })
        .catch((e) => {
          console.log(`Error in creating new field - ${e}`);
        });
    } else {
      navigate(`${FORMS}${formID.current}/review`);
    }
  };
  const handleClose1 = () => {
    updateState({ open: false });
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1)
    }
  }));

  const columns: GridColDef[] = [
    ...QUERY_MANAGEMENT_GRID_COLUM,
    {
      field: 'action',
      headerName: 'Action',
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(event) => handleClick(event, params)}>
              <MoreIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}>
              <MenuItem onClick={() => handleClickOpen('1')}>Assign</MenuItem>
              <MenuItem onClick={() => handleClickOpen('2')}>Unassign</MenuItem>
              <MenuItem onClick={() => viewQueryDetails()}>View</MenuItem>
            </Menu>
          </>
        );
      }
    }
  ];

  const rowsUpdated = queryList;

  const handleChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { value }
    } = event;
    updateState({ assignUser: value, assignUserError: false });
  };
  const handleAssignUser = () => {
    if (unassignUser === 'No') {
      if (assignUser === '') {
        updateState({ assignUserError: true });
        return false;
      } else {
        updateState({ assignUserError: false });
      }
    }

    AppSMService.axios
      .patch(`${QUERY_ASSIGN}${editID.current}`, {
        assignee: assignUser
      })
      .then(({ status }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          updateState({
            openAlertStatus: SUCCESS,
            openAlertMsg: ASSIGNED_USER_SUCCESS,
            options: [],
            openAlert: true,
            assignUser: '',
            btnloading: false
          });
          loadDataEntry();
          handleClose1();
        } else {
          updateState({
            openAlertStatus: ERROR,
            openAlertMsg: SOMETHING_WENT_WRONG,
            openAlert: true,
            assignUser: '',
            btnloading: false
          });
        }

        AutoCloseAlert();

        handleClose();
      })
      .catch((e) => {
        updateState({
          openAlertStatus: ERROR,
          openAlertMsg: SOMETHING_WENT_WRONG,
          openAlert: true,
          assignUser: '',
          btnloading: false
        });
        AutoCloseAlert();
      });
  };

  const handleCloseAlert = () => {
    updateState({ openAlertStatus: '', openAlertMsg: '', openAlert: false });
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({ openAlertStatus: '', openAlertMsg: '', openAlert: false });
    }, 3000);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />

        <Toolbar
          sx={{
            color: '#000',
            minHeight: '45px !important',
            backgroundColor: '#FFF',
            textAlign: 'right',
            alignItems: 'flex-start',
            paddingTop: '5px'
          }}>
          <Typography sx={{ paddingTop: '4px' }}>
            <b>Total Query:</b> {totalCount}
          </Typography>
          <Typography sx={{ ml: 1, paddingTop: '4px' }}>
            <b>Assigned Query:</b> {assignedCount}
          </Typography>
          <Typography sx={{ ml: 1, paddingTop: '4px' }}>
            <b>UnAssigned Query:</b> {unAssignedCount}
          </Typography>
        </Toolbar>

        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rowsUpdated}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 }
                }
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
            />
          </div>
        </Paper>
      </Grid>
      <BootstrapDialog
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={open1}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose1}>
          Assign Users
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ minWidth: 500 }}>
          <FormControl variant="standard" fullWidth sx={{ mb: 4 }}>
            <InputLabel id="demo-simple-select-standard-label">User</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={assignUser}
              onChange={handleChange}
              label="User"
              error={assignUserError}>
              {userList.map((item: any, index: number) => {
                return (
                  <MenuItem key={index} value={item.username}>
                    {item.username}
                  </MenuItem>
                );
              })}
            </Select>
            {assignUserError === true && (
              <FormHelperText style={{ color: 'red' }}>This field is required</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            autoFocus
            onClick={handleAssignUser}
            variant="contained"
            style={{
              backgroundColor: '#0E3D67'
            }}
            loading={btnloading}>
            ASSIGN
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default Querymanagement;

function BootstrapDialogTitle(props: any) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
