import React from "react";
import { useNavigate } from "react-router-dom";
import MasterLayout from "../../templates/MasterLayout";
import { useAppDispatch } from "../../../hooks";
import { AppUMService } from "../../../network/useAxios";
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from "../../../network/networkConstants";
import { updateAccessRightsData, updateStudyRoleData, updateUserData } from "../../../store/slices/globalSlice";
import { ROUTING_CONSTANTS } from "../../../utils";

export interface IClinicalAppProps {}
function ClinicalApp() {
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    loadUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const loadUser = async () => {
    const { data: userData, status: userStatus } =
    await AppUMService.axios.get(NETWORK_CONSTANTS.END_POINTS.USER_ME);
  if (userStatus === 200) {
    dispatch(updateStudyRoleData(userData.studyRole));
    dispatch(updateUserData({
      id: userData.principal.id,
      name:
      userData.principal.firstName + " " + userData.principal.lastName,
      type: userData.principal.userType,
      username: userData?.principal?.username || "",
      authority: userData.authoritites.authority,
      clientUserName: userData.principal.clientUserName
    }));
    const { data: userAccessData, status: userAccessStatus } =
    await AppUMService.axios.get(`${NETWORK_CONSTANTS.END_POINTS.USER_ACCESS}?username=${userData.principal.username}`);
    if(userAccessStatus===HTTP_STATUS_CODE.OK)
      {
        dispatch(updateAccessRightsData(userAccessData.accessRights || []))
      }
      else{
        navigate(ROUTING_CONSTANTS.ROOT);
      }
  } 
  };

  return <MasterLayout />;
}
export default ClinicalApp;
