import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

export const BKUP_MANGE_GRID_COLUMN_CLIENT: GridColDef[] = [
  {
    field: "studyname",
    headerName: "Study Name",
    width: 200,
    editable: false,
  },
  {
    field: "backupstatus",
    headerName: "Backup Status",
    width: 150,
    editable: false,
  },
  {
    field: "backupdatetime",
    headerName: "Backup Date & Time",
    width: 300,
    editable: false,
  },
  {
    field: "backupby",
    headerName: "Backup By",
    width: 200,
    editable: false,
  },
  {
    field: "verificationstatus",
    headerName: "Verification Status",
    width: 250,
    editable: false,
  }
];

export const ONBOARD_GRID_COLUMN_SPONSOR: GridColDef[] = [
  {
    field: "firstName",
    headerName: "Name",
    width: 250,
    editable: false,
  },
  {
    field: "phoneNumber",
    headerName: "Phone",
    width: 200,
    editable: false,
  },
  {
    field: "website",
    headerName: "Website",
    width: 200,
    editable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    editable: false,
  },
];

export const ONBOARD_GRID_COLUMN_DEFAULT= [
    {
      field: "username",
      headerName: "User Name",
      width: 200,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
    },
    {
      field: "authorities",
      headerName: "Authorities",
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return params.value.map((item: any) => {
          return item.name;
        });
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: false,
    }]
