import { useEffect, useState } from 'react';

import { DataGrid, GridColDef, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import LogformModal from './LogformModal';
import AddIcon from '@mui/icons-material/Add';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { AppSMService } from '../../../network/useAxios';
import { useLocation } from 'react-router-dom';
import { find, isEmpty } from 'lodash';
import { Button, InputAdornment, Modal, Paper, Stack } from '@mui/material';
import { ILogFormData, LOG_FORM_MODE, LogEntryFormGridState } from './types';
import { Message, SaveOutlined } from '@mui/icons-material';
import { FORMSTATUS } from '../../../models/ClinicalForm';
import FormViewerFieldStatus from '../FormViewerFieldStatus/FormViewerFieldStatus';
import { IDeFormReviewFields } from '../../molecules/DataEntryForm/types';
import ReviewerComments from '../ReviewerComments/ReviewerComments';
import _ from 'lodash';
import { LOG_COLUMNS_CONFIG } from './LogEntryFormGridConfig';
import { IDeForms } from '../../pages/DataEntryOperator/types';
import { BREAD_CRUMBS, ROUTING_CONSTANTS } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { InitialStateTypes, updateLogFormData } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
declare global {
  interface Window {
    editRow: any;
    deleteRowData: any;
  }
}

export default function LogEntryFormGrid() {
  const {
    state: { deFormId, formId, selectedForm }
  } = useLocation();
  const { HOME, LOG_FORM } = BREAD_CRUMBS;
  const { DASHBOARD } = ROUTING_CONSTANTS;
  const breadCrumbList = [
    { title: HOME, link: DASHBOARD },
    { title: LOG_FORM, link: '' }
  ];
  const deForm: IDeForms = selectedForm;
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const dispatch = useAppDispatch();
  const { logForm, user, study } = appDataContext;
  const [logEntryFormGridState, setLogEntryFormGridState] = useState<LogEntryFormGridState>({
    showFormModalEdit: false,
    logFormFields: [],
    logFormColumns: [],
    logFormRows: [],
    selectedRowId: '',
    logFormMode: '',
    dummy: null,
    isReviewCommentsOpen: false,
    selectedFieldId: ''
  });

  const {
    isReviewCommentsOpen,
    logFormColumns,
    logFormFields,
    logFormMode,
    logFormRows,
    selectedFieldId,
    selectedRowId,
    showFormModalEdit
  } = logEntryFormGridState;

  const { DE_FORMS, FORMS, FIELD_REVIEW, FIELD_REVIEWS } = NETWORK_CONSTANTS.END_POINTS;
  const apiRef = useGridApiRef();

  useEffect(() => {
    loadFormData();
    return () => {
      logForm.logformReviewFields = {};
    };
  }, []);
  const updateState = (data: any) => {
    setLogEntryFormGridState((prevState: LogEntryFormGridState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadFormData = async () => {
    try {
      const promiseAllResponse = await Promise.all([
        AppSMService.axios.get(`/${FORMS}/${formId}`),
        AppSMService.axios.get(`/${DE_FORMS}/${deFormId}`),
        AppSMService.axios.get(`${FIELD_REVIEW}?formId=${deFormId}`)
      ]);
      let _logFormColumns: GridColDef[] = [];
      let _logFormFields: any = [];
      let _logFormRows: any = [];
      const _logFormReviewFields: any = {};
      promiseAllResponse.forEach(({ status, data, config }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          if (config.url === `/${DE_FORMS}/${deFormId}`) {
            _logFormRows = data?.fields?.map(({ id, logFormData }: any) => {
              const _fieldRowData: any = {};
              logFormData.forEach(({ fieldData, fieldId }: ILogFormData) => {
                const _field = find(_logFormFields, function (o) {
                  return o.id === fieldId;
                });
                _fieldRowData[fieldId] = fieldData;
                _fieldRowData[_field?.definition.name] = fieldData;
                _fieldRowData['id'] = fieldId;
                _fieldRowData['Lid'] = id;
              });
              return _fieldRowData;
            });
          } else if (config.url === `/${FORMS}/${formId}`) {
            _logFormFields = data?.fields;
            _logFormColumns = data?.fields?.map((field: any) => {
              _logFormReviewFields[field.id] = { rId: '', status: '' };
              const type = _.toLower(field?.definition.name);
              const width = LOG_COLUMNS_CONFIG[type]?.width || 200;
              return {
                field: field?.definition.name,
                headerName: field?.definition.name,
                width: width,
                editable: false,
                type
              };
            });
          } else {
            data?.forEach(({ fieldId, reviewStatus, id }: IDeFormReviewFields) => {
              _logFormReviewFields[fieldId] = {
                rId: id,
                status: reviewStatus
              };
            });
          }
        }
      });
      updateState({
        logFormFields: _logFormFields,
        logFormRows: _logFormRows,
        logFormColumns: [..._logFormColumns, ...getAdditionalColumn()]
      });
      dispatch(updateLogFormData({ logformReviewFields: _logFormReviewFields }));
    } catch {
      throw Error('Promise failed');
    }
  };
  const getAdditionalColumn = () => {
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }: any) => {
          // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          return [
            <GridActionsCellItem
              key={id}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => editRowData(id, LOG_FORM_MODE.EDIT)}
              color="inherit"
            />
          ];
        }
      },
      {
        field: 'status',
        type: 'actions',
        headerName: 'Review Status',
        width: 150,
        cellClassName: 'actions',
        getActions: ({ id, row }: any) => {
          console.log('Review Status', id, row);
          return [
            <>
              <InputAdornment position="start">
                <Message onClick={() => openFieldReviewComments(id)} />
              </InputAdornment>
              <FormViewerFieldStatus
                currentStatus={logForm.logformReviewFields[id]?.status}
                handleStatusChange={(type) => saveFormReviewInfo(id, row, type)}
              />
            </>
          ];
        }
      }
    ];
  };
  const openFieldReviewComments = (fieldId: string) => {
    updateState({
      isReviewCommentsOpen: fieldId ? true : false,
      selectedFieldId: fieldId ? fieldId : selectedFieldId
    });
  };
  const editRowData = (id: string, mode: string) => {
    updateState({ selectedRowId: id, showFormModalEdit: true, logFormMode: mode });
  };
  const showEditModal = () => {
    updateState({ showFormModalEdit: !showFormModalEdit });
  };

  const getSelectedRowData = () => {
    if (!isEmpty(selectedRowId)) {
      return find(logFormRows, { Lid: selectedRowId });
    }
    return {};
  };
  const RenderLogFormModal = () => {
    return (
      <LogformModal
        showEditModal={showEditModal}
        showFormModalEdit={showFormModalEdit}
        logFormFields={logFormFields}
        logFormRows={getSelectedRowData()}
        deFormId={deFormId}
        formId={formId}
        logFormMode={logFormMode}
        loadFormData={loadFormData}
      />
    );
  };

  const publishForm = (status: FORMSTATUS) => {
    AppSMService.axios
      .patch(`forms/${formId}`, {
        status: status.toUpperCase()
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(`Error in updating form ${deFormId} - ${e}`);
      });
  };
  const saveFormReviewInfo = (id: string, row: any, type: string) => {
    console.log('row', row);
    const _logformReviewFields = { ...logForm.logformReviewFields };
    let _URL = `/${FIELD_REVIEWS}`;
    let _requestData: any = {};
    let apiService;
    const _reviewObject = _logformReviewFields[id];
    if (!isEmpty(_reviewObject?.status) && !isEmpty(_reviewObject?.rId)) {
      _URL = `${FIELD_REVIEWS}/${_reviewObject?.rId}`;
      _requestData = {
        fieldId: id,
        reviewedBy: user?.username,
        reviewStatus: type
      };
      apiService = AppSMService.axios.patch(`${_URL}`, _requestData);
    } else {
      _requestData = {
        fieldId: id,
        fieldName: row.definition.label,
        reviewedBy: user?.username,
        reviewStatus: type,
        formId: deFormId,
        formName: deForm.formName,
        dbMode: deForm.dbMode,
        studyId: deForm.studyId
      };
      apiService = AppSMService.axios.post(`${_URL}`, _requestData);
    }

    apiService
      .then(({ status, data: { id, fieldId } }: any) => {
        if (status === HTTP_STATUS_CODE.OK) {
          _logformReviewFields[fieldId] = {
            rId: id,
            status: type
          };
          dispatch(updateLogFormData({ logformReviewFields: _logformReviewFields }));
          updateState({ dummy: new Date() });
        }
      })
      .catch((e: any) => {
        console.log(`Error in fetching forms list - ${e}`);
      });
  };
  const handleClose = () => {
    updateState({ isReviewCommentsOpen: false });
  };
  return (
    <div style={{ height: 300, width: '100%' }}>
      <Breadcrumb breadCrumbList={breadCrumbList} />
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Stack direction="row" spacing={1} sx={{ mb: 1 }} style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            endIcon={<SaveOutlined />}
            onClick={() => publishForm(FORMSTATUS.PUBLISHED)}>
            Publish
          </Button>

          <Button
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => editRowData('', LOG_FORM_MODE.ADD)}>
            Add
          </Button>
        </Stack>
        <DataGrid
          apiRef={apiRef}
          rows={logFormRows}
          columns={logFormColumns}
          rowHeight={60}
          getRowId={(row) => row.Lid}
        />
      </Paper>
      {showFormModalEdit && <RenderLogFormModal />}
      <Modal
        open={isReviewCommentsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}>
        <ReviewerComments
          formId={formId}
          studyId={study?.id}
          fieldId={selectedFieldId}
          handleClose={handleClose}
          type={'data'}
        />
      </Modal>
    </div>
  );
}
