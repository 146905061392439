import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import Filters from "../../organisms/Filters/Filters";
import FilterSetup from "./FilterSetup.json";
import _ from "lodash";
import DonutChart from "react-donut-chart";
import { FilterType } from "./types";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { STORE_CONFIG } from "../../../store/storeConstant";
import { MODES, ROUTING_CONSTANTS, USER_TYPE } from "../../../utils";
import {
  HTTP_STATUS_CODE,
  NETWORK_CONSTANTS,
} from "../../../network/networkConstants";
import { AppSMService, AppUMService } from "../../../network/useAxios";
import {
  InitialStateTypes,
  updateClientData,
  updateMetaData,
  updateProjectData,
  updateStudyData,
} from "../../../store/slices/globalSlice";

function Dashboard() {
  const navigate = useNavigate();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const dispatch = useAppDispatch();
  const { user, client } = appDataContext;
  const [filterList, setFilterList] = useState<FilterType[]>(
    _.filter(FilterSetup, function (data) {
      if (data.id === USER_TYPE.CLIENT) {
        return (
          user?.authority === USER_TYPE.SUPER_ADMIN ||
          user?.type === USER_TYPE.USER
        );
      } else {
        return data.visibility;
      }
    })
  );
  const [dashboardState, setDashboardState] = useState({
    statData: "",
    chartData: [],
  });
  const updateState = (data: any) => {
    setDashboardState((prevState)=>{
      const _updatedState:any={...prevState};
      Object.keys(data).forEach(function(key:string) {
        _updatedState[key]=data[key]
      });
      return _updatedState;
    });
  };
  useEffect(() => {
    getMode();
    checkLogin();
    getStatData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkLogin = () => {
    setTimeout(() => {
      if (user?.username !== "") {
        if (
          user?.authority === USER_TYPE.SUPER_ADMIN ||
          user?.type === USER_TYPE.USER
        ) {
          getClientData();
        } else {
          getSponsorData();
          // getProjectData(context.user?.id);
        }
      } else {
        checkLogin();
      }
    }, 500);
  };
  function getChartData(data: any) {
    return [
      {
        label: "Total Active Clients",
        value: data.activeClients,
        isEmpty: parseInt(data.activeClients) > 0 ? false : true,
      },
      {
        label: "Total Inactive Clients",
        value: data.inActiveClients,
        isEmpty: parseInt(data.inActiveClients) > 0 ? false : true,
      },
      {
        label: "Total Active Users",
        value: data.activeUsers,
        isEmpty: parseInt(data.activeUsers) > 0 ? false : true,
      },
      {
        label: "Total Inactive Users",
        value: data.inActiveUsers,
        isEmpty: parseInt(data.inActiveUsers) > 0 ? false : true,
      },
      {
        label: "Total Active Studies",
        value: data.activeStudies,
        isEmpty: parseInt(data.activeStudies) > 0 ? false : true,
      },
      {
        label: "Total Inactive Studies",
        value: data.inActiveStudies,
        isEmpty: parseInt(data.inActiveStudies) > 0 ? false : true,
      },
    ];
  }
  const getStatData = async () => {
    const userURL = `${NETWORK_CONSTANTS.END_POINTS.STUDY_STATS}?active=true`;
    const { data, status } = await AppSMService.axios.get(userURL);
    if (status === HTTP_STATUS_CODE.OK) {
      updateState({ statData: data, chartData: getChartData(data) });
    }
  };

  const getClientData = async () => {
    const userURL =
      user?.authority === USER_TYPE.SUPER_ADMIN
        ? `${NETWORK_CONSTANTS.END_POINTS.USERS}?sortBy=createdDate`
        : `${NETWORK_CONSTANTS.END_POINTS.USER_ACCESS}/${NETWORK_CONSTANTS.END_POINTS.USERS}/${user?.username}?accessibleEntities.type=client`;
    const { data, status } = await AppUMService.axios.get(userURL);
    if (status === 200) {
      if (data.length > 0) {
        setFilterList(loadOptions({ id: MODES.CLIENT.id, options: data }));
      }
    }
  };

  const getSponsorData = async () => {
    const _URL = `${NETWORK_CONSTANTS.END_POINTS.USERS}?userType=sponsor&sortBy=createdDate&clientUserName=${user?.clientUserName}`;
    const { data, status } = await AppUMService.axios.get(_URL);
    if (status === 200) {
      if (data.length > 0) {
        setFilterList(loadOptions({ id: MODES.SPONSOR.id, options: data }));
      }
    }
  };

  const getProjectData = (id: any) => {
    const _URL = `${NETWORK_CONSTANTS.END_POINTS.PROJECT}?sponsorId=${id}`;
    AppSMService.axios.get(_URL).then(({ status, data }) => {
      if (status === 200 && _.isArray(data)) {
        setFilterList(loadOptions({ id: MODES.PROJECT.id, options: data }));
      }
    });
  };

  const getStudyData = (projectId: string) => {
    const _URL = `${NETWORK_CONSTANTS.END_POINTS.STUDY}?projectId=${projectId}`;
    AppSMService.axios.get(_URL).then(({ status, data }) => {
      if (status === 200 && _.isArray(data)) {
        setFilterList(loadOptions({ id: MODES.STUDY.id, options: data }));
      }
    });
  };

  const getMode = () => {
    setFilterList(
      loadOptions({
        id: MODES.id,
        options: MODES.options,
      })
    );
  };

  const loadOptions = ({ id, options }: any) => {
    const data = filterList?.map((data) => {
      if (data.id === id) {
        data.options = options?.map((item: any) => {
          switch (id) {
            case MODES.CLIENT.id:
              item.label = `${item.username}`;
              break;
            case MODES.SPONSOR.id:
              item.label = `${item.firstName}`;
              break;
            case MODES.PROJECT.id:
              item.label = `${item.label}`;
              break;
            case MODES.STUDY.id:
              item.label = item.name;
              break;
            case MODES.id:
              item.label = `${item.label}`;
              break;
            default:
              break;
          }

          return item;
        });
      }
      return data;
    });
    return data;
  };
  const setInputValue = (value: any, type: any) => {
    if (type === MODES.CLIENT.id) {
      if (!_.isEmpty(value)) {
        const clientId: string = _.find(
          _.find(filterList, { id: MODES.CLIENT.id })?.options,
          { label: value }
        )?.id;
        updateInputValue(type, value);
        getProjectData(clientId);
      } else {
        updateInputValue(type, "");
        resetOptions(MODES.PROJECT.id);
      }
    } else if (type === MODES.SPONSOR.id) {
      if (!_.isEmpty(value)) {
        const sponsorId: string = _.find(
          _.find(filterList, { id: MODES.SPONSOR.id })?.options,
          { label: value }
        )?.id;
        updateInputValue(type, value);
        getProjectData(sponsorId);
      } else {
        updateInputValue(type, "");
        resetOptions(MODES.PROJECT.id);
      }
    } else if (type === MODES.PROJECT.id) {
      if (!_.isEmpty(value)) {
        const projectId: string = _.find(
          _.find(filterList, { id: MODES.PROJECT.id })?.options,
          { label: value }
        )?.id;

        updateInputValue(type, value);
        getStudyData(projectId);
      } else {
        updateInputValue(type, "");
        resetOptions(MODES.STUDY.id);
      }
    } else if (type === MODES.STUDY.id) {
      if (!_.isEmpty(value)) {
        updateInputValue(type, value);
      } else {
        updateInputValue(type, "");
        resetOptions(MODES.SITE.id);
      }
    } else if (type === MODES.id) {
      if (!_.isEmpty(value)) {
        updateInputValue(type, value);
      } else {
        updateInputValue(type, "");
        resetOptions(MODES.id);
      }
    }
  };
  const resetOptions = (type: string) => {
    const updatedFilterData: any = filterList.map((item) => {
      if (item.id === type) {
        item.options = [];
        item.value = "";
      }
      return item;
    });
    setFilterList(updatedFilterData);
  };
  const updateInputValue = (type: string, value: string) => {
    const updatedFilterData: any = filterList.map((item) => {
      if (item.id === type) {
        item.value = value;
      }
      return item;
    });
    setFilterList(updatedFilterData);
  };
  const loginStudy = () => {
    let studyInfo: any = {};
    let project_ID = "";
    let mode = "DBP";
    filterList.forEach((item) => {
      const selectedData = getFilterId(item.options, item.value);
      if (item.id === MODES.SPONSOR.id) {
        dispatch(updateClientData({ id: user?.id }));
      } else if (item.id === MODES.PROJECT.id) {
        dispatch(
          updateProjectData({
            id: selectedData.id,
            name: selectedData.label,
          })
        );

        project_ID = selectedData.id;
      } else if (item.id === MODES.STUDY.id) {
        studyInfo = selectedData;
      } else {
        mode = selectedData.value;
      }
    });
    if (studyInfo !== "") {
      dispatch(
        updateStudyData({
          id: studyInfo?.id,
          mode,
          name: studyInfo?.name,
          reviewers:
            typeof studyInfo?.reviewers !== "undefined" &&
            typeof studyInfo?.reviewers !== "undefined"
              ? studyInfo?.reviewers
              : [],
          reviewMinPercentage:
            typeof studyInfo?.reviewMinPercentage !== "undefined" &&
            typeof studyInfo?.reviewMinPercentage !== "undefined"
              ? studyInfo?.reviewMinPercentage
              : "",

          unfreeze: studyInfo?.unfreeze,
          active: studyInfo?.active,
        })
      );
      dispatch(
        updateMetaData({
          clientId: client.id,
          projectId: project_ID,
          studyId: studyInfo?.id,
        })
      );
    }
    navigate(ROUTING_CONSTANTS.STUDY_DETAILS);
  };
  const getFilterId = (options: any, value: any) => {
    return _.find(options, { label: value });
  };

  const fileterLabel = (
    <>
      <LoginIcon fontSize="small" style={{ marginRight: "10px" }} />
      Study Login
    </>
  );
  const RenderDonutChart = () => (
    <>
      {" "}
      <Typography
        component="h3"
        variant="subtitle1"
        color="primary"
        gutterBottom
      >
        {dashboardState.statData !== "" && (
          <>
            {dashboardState.chartData.length > 0 && (
              <DonutChart data={dashboardState.chartData} />
            )}
          </>
        )}
      </Typography>
    </>
  );
  const RenderFilter = () => (
    <>
      <Filters
        filterlist={filterList}
        buttonlabel={fileterLabel}
        setInputValue={setInputValue}
        applyFilter={loginStudy}
      />
    </>
  );
  return (
    <Grid container spacing={3}>
      {/* Chart */}
      <Grid item xs={12} md={12} lg={12}>
        {user?.type !== "" && (
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {user?.type === USER_TYPE.ADMIN ? (
              <RenderDonutChart />
            ) : (
              <RenderFilter />
            )}
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
export default Dashboard;
