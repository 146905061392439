import { Box, Button, Modal, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FormsList from '../../organisms/FormsList/FormsList';
import FormViewer from '../../organisms/FormViewer/FormViewer';
import ReviewCoverage from '../../organisms/ReviewCoverage/ReviewCoverage';
import { ClearAllOutlined, SaveOutlined } from '@mui/icons-material';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import { FORMSTATUS } from '../../../models/ClinicalForm';
import ReviewerComments from '../../organisms/ReviewerComments/ReviewerComments';
import { AppSMService } from '../../../network/useAxios';
import { FormReviewerState } from './types';
import { NETWORK_CONSTANTS } from '../../../network/networkConstants';
import { ROUTING_CONSTANTS } from '../../../utils';
import { useAppSelector } from '../../../hooks';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';

const FormReviewerPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { FORMS: RFORMS, FORM_LIST } = ROUTING_CONSTANTS;
  const { FORMS, STUDY, FORM_REVIEW } = NETWORK_CONSTANTS.END_POINTS;
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { user } = appDataContext;
  const [formReviewerState, setFormReviewerState] = useState<FormReviewerState>({
    formDetails: {
      id: '',
      name: '',
      data: [],
      lastUpdated: 0,
      status: FORMSTATUS.PENDING, //Pending, In Progress
      metaData: [],
      version: '',
      fields: [],
      studyId: '',
      logForm: false
    },
    formPercentage: {
      total: 100,
      completed: 0
    },
    isReviewCommentsOpen: false,
    selectedFieldId: ''
  });
  const { formDetails, formPercentage, isReviewCommentsOpen, selectedFieldId } = formReviewerState;
  const updateState = (data: any) => {
    setFormReviewerState((prevState: FormReviewerState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const handleSelectedForm = (formId: string) => {
    formId && navigate(`${RFORMS}${formId}/review`);
  };
  const retrieveFormData = async () => {
    if (params.formId) {
      await AppSMService.axios
        .get(`${FORMS}/${params.formId}`)
        .then(({ data }) => {
          updateState({ formDetails: data || null });
        })
        .catch((e: any) => {
          console.log(`Error in fetching forms list - ${e}`);
        });
    }
  };
  const updateReviewerFieldProgress = async () => {
    AppSMService.axios
      .get(`${STUDY}/completion-pct?studyId=${formDetails?.studyId}`)
      .then(() => {})
      .catch(() => {
        console.log(`Error in fetching form review progress`);
      });
  };
  const updateFormStatus = (status: FORMSTATUS) => {
    AppSMService.axios
      .post(`${FORM_REVIEW}`, {
        reviewStatus: status.toUpperCase(),
        reviewedBy: user?.username,
        formId: params.formId
      })
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          navigate(`${FORM_LIST}?isReviewer=true`);
        }, 1000);
      })
      .catch((e) => {
        console.log(`Error in updating form ${params.formId} - ${e}`);
      });
  };
  const openFieldReviewComments = (fieldId: string) => {
    updateState({
      isReviewCommentsOpen: fieldId ? true : false,
      selectedFieldId: fieldId ? fieldId : selectedFieldId
    });
  };
  const handleClose = () => {
    updateState({ isReviewCommentsOpen: false });
  };
  useEffect(() => {
    retrieveFormData();
    updateReviewerFieldProgress();
  }, [params?.formId]);
  return (
    <>
      <Box sx={{ display: 'flex', height: '100%' }} className="form-viewer">
        <Box
          sx={{
            width: '200px',
            maxWidth: '200px',
            overflowX: 'auto',
            border: '1px #ccc solid',
            height: '100%'
          }}>
          <FormsList
            selectedFormId={params.formId}
            handleSelectedItem={handleSelectedForm}
            isReviewer={true}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            padding: 0,
            margin: 0
          }}>
          <Box sx={{ width: '85%', height: '100%' }}>
            <Box sx={{ minHeight: '500px' }}>
              <FormViewer
                formId={params.formId || ''}
                handleStatusChange={updateReviewerFieldProgress}
                openFieldReviewComments={openFieldReviewComments}
              />
            </Box>
            {formDetails && formDetails.fields && formDetails.fields.length > 0 ? (
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                spacing={2}
                pt={4}
                pb={2}>
                <Button
                  variant="outlined"
                  endIcon={<ClearAllOutlined />}
                  onClick={() => navigate('/clinicalApp/forms/list?isReviewer=true')}>
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  endIcon={<DoDisturbOffIcon />}
                  onClick={() => updateFormStatus(FORMSTATUS.REJECTED)}>
                  Reject
                </Button>
                <Button
                  variant="contained"
                  endIcon={<SaveOutlined />}
                  onClick={() => updateFormStatus(FORMSTATUS.APPROVED)}>
                  Approve
                </Button>
              </Stack>
            ) : null}
          </Box>
          <Box sx={{ width: '15%' }}>
            <ReviewCoverage
              targetPercentage={formPercentage.total}
              completedpercentage={formPercentage.completed}
            />
          </Box>
        </Box>
      </Box>
      <Modal
        open={isReviewCommentsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}>
        <ReviewerComments
          formId={params.formId}
          studyId={formDetails?.studyId}
          fieldId={selectedFieldId}
          handleClose={handleClose}
          type={'form'}
        />
      </Modal>
    </>
  );
};
export default FormReviewerPage;
