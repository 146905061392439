
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Paper, Button, Typography, InputLabel, Input, Stack, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Filters from '../../organisms/Filters/Filters';
import SearchIcon from '@mui/icons-material/Search';
import { AppSMService,AppUMService as AppService } from '../../../network/useAxios';
import DataEntryFilterSetupData from './DataEntryFilterSetup.json';
import _, { isEmpty } from 'lodash';
import { DATA_ENTRY_ACTION_ITEM, DataEntryGridState, E_CONFIG_TYPE, userType } from './types';
import { COLUMNS_VISIBILITY, DATA_ENTRY_FORM_COLUMNS } from './DataEntryFormConfig';
import {
  CompareData,
  ExtractData,
  SignOff,
  History,
  ActionList,
  ActionsItems,
  IActionItem
} from '../../organisms/ActionItems/index';
import Loader from '../../molecules/Loader/Loader';
import { useStudyAccessRights } from '../../../utils/useStudyAccessRights';
import { MODES, ROUTING_CONSTANTS } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { InitialStateTypes, updateDeformData } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
const DataEntryGrid = (props: userType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const dispatch = useAppDispatch();
  const {
    study,
    deForms: { metaInfo }
  } = appDataContext;
  const { META_DATA, USERS, PROJECT, STUDY, SUBJECT, DE_FORMS } = NETWORK_CONSTANTS.END_POINTS;
  const { dataEntryMenuAccessRights, dataViewAccessRights } = useStudyAccessRights();
  const { LOG_ENTRY, DATA_ENTRY_FORM, DATA_ENTRY_REVIWER_FORM } = ROUTING_CONSTANTS;
  const [dataEntryGridState, setDataEntryGridState] = useState<DataEntryGridState>({
    loader: false,
    formList: [],
    filterList: _.filter(DataEntryFilterSetupData, function (data) {
      return data.visibility;
    }),
    actionItemList: getActionList() || []
  });
  const { actionItemList, filterList, formList, loader } = dataEntryGridState;
  const [siteElement, SubjectElement, VisitElement] = filterList;

  const updateState = (data: any) => {
    setDataEntryGridState((prevState: DataEntryGridState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };

  function getActionList() {
    return ActionList.filter((item: IActionItem) => item.pathName === location.pathname);
  }

  useEffect(() => {
    if (!isEmpty(study?.id) && isEmpty(metaInfo)) {
      loadMetadata();
    }

    if (
      (!isEmpty(siteElement.value) ||
        !isEmpty(SubjectElement.value) ||
        !isEmpty(VisitElement.value)) &&
      !isEmpty(metaInfo)
    ) {
      applyFilter();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(study?.id)) {
      getSiteData(study?.id);
    }
  }, [metaInfo]);
  const loadMetadata = async () => {
    try {
      const promiseAllResponse = await Promise.all([
        AppSMService.axios.get(`${META_DATA}?type=${E_CONFIG_TYPE.site}&studyId=${study?.id}`),
        AppSMService.axios.get(`${META_DATA}?type=${E_CONFIG_TYPE.subject}&studyId=${study?.id}`),
        AppSMService.axios.get(`${META_DATA}?type=${E_CONFIG_TYPE.visit}&studyId=${study?.id}`)
      ]);
      const _metaInfo: any = {};
      promiseAllResponse.forEach(({ status, data = [] }) => {
        if (status === 200) {
          if (data?.length > 0) {
            const [metaDataInfo] = data;

            _metaInfo[metaDataInfo.type] = { data: metaDataInfo.data[0] };
          }
        }
      });
      dispatch(updateDeformData({ metaInfo: _metaInfo }));
    } catch {
      throw Error('Promise failed');
    }
  };

  const getClientData = () => {
    AppService.axios.get(`${USERS}?userType=client&sortBy=createdDate`).then(({ status, data }) => {
      if (status === HTTP_STATUS_CODE.OK && _.isArray(data)) {
        const _filterList = loadOptions({ id: MODES.CLIENT.id, options: data });
        updateState({ filterList: _filterList });
      }
    });
  };
  const getProjectData = (clientId: string) => {
    AppSMService.axios.get(`${PROJECT}?clientId=${clientId}`).then(({ status, data }) => {
      if (status === HTTP_STATUS_CODE.OK && _.isArray(data)) {
        const _filterList = loadOptions({ id: MODES.PROJECT.id, options: data });
        updateState({ filterList: _filterList });
      }
    });
  };

  const getStudyData = (projectId: string) => {
    AppSMService.axios.get(`${STUDY}}?projectId=${projectId}`).then(({ status, data }) => {
      if (status === HTTP_STATUS_CODE.OK && _.isArray(data)) {
        const _filterList = loadOptions({ id: MODES.STUDY.id, options: data });
        updateState({ filterList: _filterList });
      }
    });
  };
  const getSiteData = (studyId: any) => {
    if (!isEmpty(metaInfo['SITE'])) {
      AppSMService.axios.get(`${STUDY}/${studyId}/config?type=SITE`).then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK && _.isArray(data)) {
          const _filterList = loadOptions({ id: MODES.SITE.id, options: data });
          updateState({ filterList: _filterList });
        }
      });
    }
  };
  const getSubjectData = (siteId: any) => {
    AppSMService.axios
      .get(`${SUBJECT}?siteId=${siteId}&dbMode=${study.mode}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK && _.isArray(data)) {
          const _filterList = loadOptions({ id: MODES.SUBJECT.id, options: data });
          updateState({ filterList: _filterList });
        }
      });
  };
  const getVisitData = (studyId: any) => {
    AppSMService.axios
      .get(`${STUDY}/${studyId}/config?type=VISIT&dbMode=${study.mode}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK && _.isArray(data)) {
          const _filterList = loadOptions({ id: MODES.VISIT.id, options: data });
          updateState({ filterList: _filterList });
        }
      });
  };

  const loadOptions = ({ id, options }: any) => {
    const data = filterList?.map((data) => {
      if (data.id === id) {
        data.options = options?.map((item: any) => {
          switch (id) {
            case MODES.CLIENT.id:
              item.label = `${item.firstName} ${item.lastName}`;
              break;
            case MODES.PROJECT.id:
              item.label = item.id;
              break;
            case MODES.STUDY.id:
              item.label = item.name;
              break;
            case MODES.SITE.id:
              item.label = getItemLabel(item, E_CONFIG_TYPE.site);
              break;
            case MODES.VISIT.id:
              item.label = getItemLabel(item, E_CONFIG_TYPE.visit);
              break;
            case MODES.SUBJECT.id:
              item.label = getItemLabel(item, E_CONFIG_TYPE.subject);
              break;
            default:
              break;
          }

          return item;
        });
      }
      return data;
    });
    return data;
  };
  const getItemLabel = (item: any, type: string) => {
    if (!isEmpty(metaInfo[type])) {
      return item?.data[metaInfo[type].data?.name];
    }
    return null;
  };
  const setInputValue = (value: any, type: any) => {
    if (type === MODES.CLIENT.id) {
      if (!_.isEmpty(value)) {
        const clientId: string = _.find(_.find(filterList, { id: MODES.CLIENT.id })?.options, {
          label: value
        })?.id;
        updateInputValue(type, value);
        getProjectData(clientId);
      } else {
        updateInputValue(type, '');
        resetOptions(MODES.PROJECT.id);
      }
    } else if (type === MODES.PROJECT.id) {
      if (!_.isEmpty(value)) {
        updateInputValue(type, value);
        getStudyData(value);
      } else {
        updateInputValue(type, '');
        resetOptions(MODES.STUDY.id);
      }
    } else if (type === MODES.STUDY.id) {
      if (!_.isEmpty(value)) {
        updateInputValue(type, value);
        getSiteData(study?.id);
      } else {
        updateInputValue(type, '');
        resetOptions(MODES.SITE.id);
      }
    } else if (type === MODES.SITE.id) {
      if (!_.isEmpty(value)) {
        const siteId: string = _.find(_.find(filterList, { id: MODES.SITE.id })?.options, {
          label: value
        })?.id;
        updateInputValue(type, value);
        getSubjectData(siteId);
      } else {
        updateInputValue(type, '');
        resetOptions(MODES.SUBJECT.id);
      }
    } else if (type === MODES.SUBJECT.id) {
      if (!_.isEmpty(value)) {
        updateInputValue(type, value);
        getVisitData(study?.id);
      } else {
        updateInputValue(type, '');
        resetOptions(MODES.SUBJECT.id);
      }
    } else {
      if (!_.isEmpty(value)) {
        updateInputValue(type, value);
      } else {
        updateInputValue(type, '');
        resetOptions(MODES.VISIT.id);
      }
    }
  };
  const getStudyId = () => {
    const _studyValue = _.find(filterList, { id: MODES.STUDY.id })?.value;
    return _.find(_.find(filterList, { id: MODES.STUDY.id })?.options, {
      name: _studyValue
    })?.id;
  };
  const updateInputValue = (type: string, value: string) => {
    const updatedFilterData: any = filterList.map((item) => {
      if (item.id === type) {
        item.value = value;
      }
      return item;
    });
    updateState({ filterList: updatedFilterData });
  };
  const resetOptions = (type: string) => {
    const updatedFilterData: any = filterList.map((item) => {
      if (item.id === type) {
        item.options = [];
        item.value = '';
      }
      return item;
    });
    updateState({ filterList: updatedFilterData });
  };

  const applyFilter = () => {
    let requestData = `studyId=${study?.id}`;
    // requestData += `&dbMode=${study?.mode}`;
    updateState({ loader: true });
    filterList.map((item) => {
      /*  if (item.id === "study") {
        studyId = _.find(item.options, { label: item.value })?.id;
      } else */ if (item.id === 'site' && !isEmpty(item.value)) {
        requestData += `&siteId=${getFilterId(item.options, item.value)}`;
      } else if (item.id === 'subject' && !isEmpty(item.value)) {
        requestData += `&subjectId=${getFilterId(item.options, item.value)}`;
      } else if (item.id === 'visit' && !isEmpty(item.value)) {
        requestData += `&visitId=${getFilterId(item.options, item.value)}`;
      }
    });

    AppSMService.axios
      .get(`${DE_FORMS}?${requestData}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK && !_.isEmpty(data)) {
          updateState({ formList: data });
        }
      })
      .finally(() => updateState({ loader: false }));
  };

  const getFilterId = (options: any, value: any) => {
    return _.find(options, { label: value })?.id;
  };
  const handleEvent = ({ row, id }: any) => {
    const _dataViewAccessRights = !props.isDataEntryReviewer
      ? dataEntryMenuAccessRights
        ? false
        : true
      : dataViewAccessRights
        ? true
        : false;
    if (row?.logForm) {
      navigate(LOG_ENTRY, {
        state: {
          forms: formList,
          isLogForm: row?.logForm,
          formId: row?.formId,
          deFormId: id,
          selectedForm: row
        }
      });
    } else if (!dataEntryMenuAccessRights && !props.isDataEntryReviewer) {
      return false;
    }

    navigate(props.isDataEntryReviewer ? DATA_ENTRY_REVIWER_FORM : DATA_ENTRY_FORM, {
      state: {
        forms: formList,
        isLogForm: row?.logForm,
        formId: row?.formId,
        deFormId: id,
        isDataEntryOperator: props.isDataEntryReviewer === false,
        selectedForm: row,
        dataViewAccessRights: _dataViewAccessRights
      }
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  /*  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }; */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns: GridColDef[] = DATA_ENTRY_FORM_COLUMNS;
  const rows = formList;

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const id = query.get('reject');
    // setReject(id);
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const [openSignoff, setOpenSignoff] = React.useState(false);
  const handleOpenSignoff = () => {
    setOpenSignoff(true);
    setAnchorEl(null);
  };
  const handleCloseSignoff = () => setOpenSignoff(false);
  const fileterLabel = (
    <>
      <SearchIcon fontSize="small" /> Search
    </>
  );
  const showActionItemModal = (item: IActionItem) => {
    if (item.show) {
      switch (item.code) {
        case DATA_ENTRY_ACTION_ITEM.EXTRACT_DATA:
          return <ExtractData meta={item} showModal={showModal} />;
        case DATA_ENTRY_ACTION_ITEM.COMPARE_DATA:
          return <CompareData meta={item} showModal={showModal} />;
        case DATA_ENTRY_ACTION_ITEM.SIGN_OFF:
          return <SignOff meta={item} showModal={showModal} />;
        case DATA_ENTRY_ACTION_ITEM.HISTORY:
          return <History meta={item} showModal={showModal} />;
        default:
          return null;
      }
    }
  };
  const renderActionModal = actionItemList.map((item: IActionItem) => {
    return showActionItemModal(item);
  });
  function showModal(code: string, show: boolean) {
    const _actionItems = actionItemList.map((item: IActionItem) => {
      if (item.code === code) {
        item.show = show;
      }

      return item;
    });
    updateState({ actionItemList: _actionItems });
  }
  const renderTableGrid = () => {
    return (
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography component="h3" variant="subtitle1" color="primary" gutterBottom>
              {`Data ${props.isDataEntryReviewer ? 'Reviewer' : 'Entry'} - Forms`}
            </Typography>
          </Box>
          <Box
            m={1}
            //margin
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end">
            <ActionsItems showModal={showModal} actionItemList={actionItemList} />
          </Box>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              columnVisibilityModel={COLUMNS_VISIBILITY}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 }
                }
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
              onRowClick={handleEvent}
            />
          </div>
        </Paper>
      </Grid>
    );
  };

  const renderSignOffModal = () => {
    return (
      <Modal
        open={openSignoff}
        onClose={handleCloseSignoff}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1000,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24
          }}>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Sign Off
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <Grid item xs={12} pt={5} pb={2} pl={4} pr={4}>
            <InputLabel htmlFor="input-with-icon-adornment">Comments</InputLabel>
            <Input
              style={{
                width: '100%'
              }}
              type="text"
            />
          </Grid>
          <Grid item xs={12} pb={20} pl={4} pr={4}>
            <InputLabel htmlFor="input-with-icon-adornment">Password</InputLabel>
            <Input
              style={{
                width: '100%'
              }}
              type="text"
            />
          </Grid>
          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            spacing={2}
            pt={4}
            pb={2}
            pr={4}>
            <Button variant="outlined" onClick={handleCloseSignoff}>
              Cancel
            </Button>
            <Button variant="contained">Sign Off</Button>
          </Stack>
        </Box>
      </Modal>
    );
  };
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} mb={4} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240
          }}>
          <Filters
            filterlist={filterList}
            buttonlabel={fileterLabel}
            setInputValue={setInputValue}
            applyFilter={applyFilter}
          />
        </Paper>
      </Grid>
      {loader && <Loader open={loader} handleClose={() => updateState({ loader: false })} />}
      {renderTableGrid()}
      {renderSignOffModal()}
      {renderActionModal}
    </React.Fragment>
  );
};

export default DataEntryGrid;
