import { IClinicalForm } from "../../../models/ClinicalForm";

  export interface FormListPageState {
    formsList: Array<IClinicalForm>;
    isFormValid:boolean;
    isCreateFormOpen:boolean;
    open:boolean;
    updateMessage:string;
    isError:boolean;
    initLoad:boolean

  }

  export const INLINE_MENUS_LIST=[
    { key: "ASSIGN_GLOBAL", label: "Assign to Global Library" },
    { key: "ASSIGN_CLIENT", label: "Assign to Client library" },
    { key: "ASSIGN_PROJECT", label: "Assign to Project library" },
  ]
  
  export enum INLINE_MENU_KEY{
    ASSIGN_GLOBAL="ASSIGN_GLOBAL",
    ASSIGN_CLIENT="ASSIGN_CLIENT",
    ASSIGN_PROJECT="ASSIGN_PROJECT"
  }

  export enum SCOPE{
    CLIENT="CLIENT",
    PROJECT="PROJECT",
    GLOBAL="GLOBAL"
  }
  