import { useEffect, useState } from 'react';
import { IClinicalForm, IFieldReview } from '../../../models/ClinicalForm';
import { IFormElement } from '../../../models/Common';
import { AppSMService } from '../../../network/useAxios';
import FormViewerFieldRenderer from '../FormViewerFieldRenderer/FormViewerFieldRenderer';
import { useAppSelector } from '../../../hooks';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
interface IProps {
  formId: string;
  handleStatusChange?: () => void;
  openFieldReviewComments?: (fieldName: string) => void;
}
const FormViewer = (props: IProps) => {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { user } = appDataContext;
  const [formDetails, setFormDetails] = useState<IClinicalForm | null | undefined>();
  const [formReviewDetails, setFormReviewDetails] = useState<IFieldReview[]>([]);
  const loadFormDetails = () => {
    AppSMService.axios
      .get(`forms/${props.formId}`)
      .then((res: any) => {
        setFormDetails(res?.data);
        loadFormReviewDetails(props.formId);
      })
      .catch((e: any) => {
        console.log(`Error in fetching forms list - ${e}`);
      });
  };
  const loadFormReviewDetails = (formId: string) => {
    AppSMService.axios
      .get(`field-review?formId=${formId}`)
      .then((res: any) => {
        setFormReviewDetails(res.data);
      })
      .catch((e: any) => {
        console.log(`Error in fetching form Review Details - ${e}`);
      });
  };
  const getFieldReviewData = (fieldId: string, formId: string): IFieldReview | null | undefined => {
    if (formReviewDetails && formReviewDetails.length > 0) {
      const reviewedItem = formReviewDetails.find((item: IFieldReview) => {
        return (
          item.reviewedBy === user?.username && item.fieldId === fieldId && item.formId === formId
        );
      });
      return reviewedItem;
    }
    return null;
  };
  useEffect(() => {
    setFormDetails(null);
    props.formId && loadFormDetails();
  }, [props.formId]);
  return (
    <p>
      {formDetails?.fields &&
        formDetails?.fields.length > 0 &&
        formDetails?.fields.map((item: IFormElement) => {
          return (
            <FormViewerFieldRenderer
              key={item.id}
              fieldReview={getFieldReviewData(item.id || '', item.formId || '')}
              formElement={item}
              handleStatusChange={() => props?.handleStatusChange?.()}
              openFieldReviewComments={props.openFieldReviewComments}
              showComments
            />
          );
        })}
    </p>
  );
};
export default FormViewer;
