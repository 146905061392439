export const FIND_KEYS = {
  mode: {
    name: 'name'
  }
};
export const BASE_UI_URL = 'clinicalApp';

export const BREAD_CRUMBS = {
  HOME: 'Home',
  ACCESS_MANAGEMENT: 'Access Management',
  ONBOARDING: 'Onboarding',
  CHANGE_PASSWORD: 'Change Password',
  SPONSOR: 'Sponsor',
  PROJECT_MANAGEMENT: 'Project Management',
  CLIENT: 'Client',
  STUDY_MANAGEMENT: 'Study Management',
  DETAILS: 'Details',
  CONFIG: 'Config',
  SPECIAL_FORM: 'Special Form',
  SUBJECT: 'Subject',
  ACCESS: 'Access',
  META_DATA: 'Metadata',
  QUERY_MANAGEMENT: 'Query Management',
  LOG_FORM: 'Log form'
};

export const ROUTING_CONSTANTS = {
  ROOT: `/`,
  DASHBOARD: `/${BASE_UI_URL}/dashboard/`,
  PROJECT: `/${BASE_UI_URL}/projects/`,
  SPONSORS: `/${BASE_UI_URL}/onboarding-sponsor`,
  USERS: `/${BASE_UI_URL}/onboarding-user`,
  CLIENT: `/${BASE_UI_URL}/onboarding-client`,
  CLIENT_USER: `/${BASE_UI_URL}/onboarding-client-user`,
  USERS1: `users/`,
  STUDY_DETAILS: `/${BASE_UI_URL}/study/details`,
  FORM_LIST: `/${BASE_UI_URL}/forms/list`,
  STUDY: `/${BASE_UI_URL}/study/`,
  STUDY_ACCESS: `/${BASE_UI_URL}/study/access`,
  FORMS: `/${BASE_UI_URL}/forms`,
  DATA_ENTRY_REVIWER_FORM: `/${BASE_UI_URL}/dataEntryReviewer/form`,
  DATA_ENTRY_REVIWER: `/${BASE_UI_URL}/dataEntryReviewer`,
  DATA_ENTRY_FORM: `/${BASE_UI_URL}/dataEntry/form`,
  DATA_ENTRY: `/${BASE_UI_URL}/dataEntry`,
  LOG_ENTRY: `/${BASE_UI_URL}/logEntry/form`
};

export enum USER_TYPE {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'Admin',
  CLIENT = 'client',
  CLIENT_USER = 'client_user',
  SPONSOR = 'sponsor',
  USER = 'user'
}

export const USER_TYPE_CONFIG = {
  [USER_TYPE.ADMIN]: {
    name: 'Admin',
    type: 'admin'
  },
  [USER_TYPE.CLIENT]: {
    name: 'Client',
    type: 'client'
  },
  [USER_TYPE.CLIENT_USER]: {
    name: 'User',
    type: 'user'
  },
  [USER_TYPE.SPONSOR]: {
    name: 'Sponsor',
    type: 'sponsor'
  },
  [USER_TYPE.USER]: {
    name: 'User',
    type: 'user'
  }
};

export const MODES = {
  CLIENT: {
    id: 'client'
  },
  SPONSOR: {
    id: 'sponsor'
  },
  PROJECT: {
    id: 'project'
  },
  STUDY: {
    id: 'study'
  },
  SITE: { id: 'site' },
  VISIT: { id: 'visit' },
  SUBJECT: { id: 'subject' },
  id: 'mode',
  options: [
    { label: 'DBP', value: 'DBP' },
    { label: 'QC', value: 'QC' },
    { label: 'LIVE', value: 'LIVE' }
  ]
};

export const TEXT_CONSTANTS = {
  COMMON: {
    TOKEN: 'token',
    FORM: {
      ELEMENT_TYPE: {
        TYPE_SUBMIT: 'submit',
        TYPE_EMAIL: 'email',
        TYPE_PASSWORD: 'password'
      }
    }
  },
  FORM: {
    LOGIN: {
      EMAIL: 'email',
      PASSWORD: 'password',
      RESET_PASSWORD: 'resetpassword',
      UPDATE_PASSWORD: 'updatepassword',
      CONFIRM_PASSWORD: 'confirmpassword',
      FORGOT_EMAIL: 'forgotemail',
      PASSWORD_LABEL: 'Password*',
      CONFIRM_PASSWORD_LABEL: 'Confirm Password*',
      REST_PASSWORD_LABEL: 'Reset Password',
      BACK_TO_LOGIN: 'Back to login',
      CHANGE_PASSWORD_LABEL: 'Change Password',
      SIGN_IN_LABEL: 'Sign in',
      FORGOT_PASSWORD: 'Forgot password',
      SUBMIT: 'Submit',
      USER_NAME_LABEL: 'User Name*',
      EMAIL_LABEL: 'Email*'
    },
    CHANGE_PASSWORD: {
      RESET_PASSWORD: 'resetpassword',
      CONFIRM_PASSWORD: 'confirmpassword',
      OLD_PASSWORD: 'oldpassword',
      OLD_PASSWORD_LABEL: 'Old Password*',
      PASSWORD_LABEL: 'Password*',
      CONFIRM_PASSWORD_LABEL: 'Confirm Password*'
    }
  },
  VALIDATION_TEXT: {
    COMMON: {
      SUCCESS: 'success',
      ERROR: 'error',
      FIELD_REQUIRED: 'This field is required',
      MAIL_SEND: 'Mail send Successfully. Please check your mail',
      SOMETHING_WENT_WRONG: 'Something Went Wrong. Try Again'
    },
    LOGIN: {
      LOGIN_SUCCESS: 'Successfully Logged In',
      LOGIN_FAILED: 'Invalid Login',
      PASSWORD_NOT_MATCHING: 'Password does not match',
      PASSWORD_UPDATED_SUCCESSFULLY: 'Password Updated Successfully.',
      USER_NOT_UPDATED: 'User Not Update'
    },
    ACCESS_MANAGEMENT: {
      ACCESS_MANAGEMENT_UPDATE: 'Access Management Update Success',
      ACCESS_MANAGEMENT_UPDATE_FAILED: 'Access Management Not Update',
      ACCESS_MANAGEMENT_DELETE_FAILED: "Can't delete access management"
    },
    ONBOARDING: {
      USER_DELETE_SUCCESS: 'User Deleted Success',
      USER_DELETE_FAILURE: 'User Not delete'
    },
    FORM_LIST_PAGE: {
      GLOBAL_LIBRARY_CREATED: 'Global library created Successfully',
      CLIENT_LIBRARY_CREATED: 'Client library created Successfully',
      PROJECT_LIBRARY_CREATED: 'Project library created Successfully'
    },
    FORM_VIEWER_PAGE: {
      NEED_ATLEAST_ONE_FIELD: 'Kindly select atleast one form field to publish the form',
      FORM_UPDATED_SUCCESS: 'Form updated successfully!!',
      FORM_SAVE_ERROR: 'Error when saving the form',
      SELECT_ATLEAST_ONE_FIELD_TO_SAVE: 'Kindly select atleast one form field to save the form'
    },
    PROJECT_PAGE: {
      PROJECT_UPDATE_SUCCESS: 'Project Update Successfully',
      PROJECT_CREATE_SUCCESS: 'Project Create Successfully',
      PROJECT_DELETION_SUCCESS: 'Project Deleted Successfully',
      PROJECT_DELETION_ERROR: 'Project not deleted ',
      DIALOG_CONFIRM_TO_REMOVE_STUDY: 'Are you sure want remove this study?'
    },
    STUDY_PAGE: {
      STUDY_PERCENTAGE_MAX_100: 'Maximum 100',
      STUDY_UPDATE_SUCCESS: 'Study Update Successfully',
      STUDY_CREATE_SUCCESS: 'Study Create Successfully',
      STUDY_DELETION_SUCCESS: 'Study Deleted Successfully',
      STUDY_DELETION_ERROR: 'Study not deleted ',
      CONFIG_UPDATE_SUCCESS: 'Config Updated Success',
      CONFIG_CREATE_SUCCESS: 'Config Created Success',
      SPECIAL_FORM_UPDATE_SUCCESS: 'Special Form UPDATE Success',
      SPECIAL_FORM_CREATE_SUCCESS: 'Special Form Created Success',
      SUBJECT_CREATE_SUCCESS: 'Subject Created Success',
      SUBJECT_UPDATE_SUCCESS: 'Subject Updated Success',
      ACCESS_UPDATE_SUCCESS: 'Access Updated Success',
      ACCESS_CREATE_SUCCESS: 'Access Saved Success'
    },
    QUERY_MANAGEMENT_PAGE: {
      ASSIGNED_USER_SUCCESS: 'User Assigned Success'
    },
    ONBOARDING_PAGE:{
      USER_UPDATE_SUCCESS: 'User Update Successfully',
      USER_CREATE_SUCCESS: 'User Create Successfully'
    }
  }
};

export enum FORM_STATUS {
  APPROVED = 'APPROVED'
}

export enum DATE_FORMAT {
  D1 = 'dd/mm/yyyy'
}

export const LOGIN_MODES = [
  {
    name: 'DBP',
    value: 'DBP'
  },
  {
    name: 'QC',
    value: 'QC'
  },
  {
    name: 'LIVE',
    value: 'LIVE'
  },
  {
    name: 'STUDY SETTINGS',
    value: 'STUDY SETTINGS'
  }
];
