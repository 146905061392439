import _ from 'lodash';
export function useDataValidation() {
  const checkIsJson = function (data: string) {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  };

  const stringToJson = function (data: string) {
    try {
      JSON.parse(data);
    } catch (e) {
      return {};
    }
    return JSON.parse(data);
  };
  const jsonToString = function (data: any) {
    try {
      return JSON.stringify(data);
    } catch (e) {
      return {};
    }
  };
  const checkIsEmpty = function (data: any) {
    try {
      return _.isEmpty(data);
    } catch (e) {
      return false;
    }
  };
  const findIndex = function (data: any, value: any, key?: any) {
    return _.findIndex(data, function (field: any) {
      if (checkIsEmpty(key)) {
        return field?.id === value;
      }
      return field[key] === value;
    });
  };
  const find = function (data: any, value: any, key?: any) {
    return _.find(data, function (field) {
      if (checkIsEmpty(key)) {
        return field?.id === value;
      }
      return field[key] === value;
    });
  };

  const split = function (key: any, value: any) {
    return _.split(value, key);
  };

  const map = function (data: any, key: string) {
    return _.map(data, key);
  };
  const checkUnitField = function (definition: any) {
    return !checkIsEmpty(definition?.units) && !checkIsEmpty(definition?.unitLabel);
  };
  const checkIsEqual = function (data: any, data1: any) {
    return _.isEqual(data, data1);
  };

  const toLowerCase = function (data: string) {
    return _.lowerCase(data);
  };
  return {
    checkIsJson,
    stringToJson,
    jsonToString,
    checkIsEmpty,
    findIndex,
    find,
    split,
    map,
    checkUnitField,
    checkIsEqual,
    toLowerCase
  };
}
