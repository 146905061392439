import React from "react";
import { IClinicalForm } from "../models/ClinicalForm";
import { swap } from "formik";

declare global {
  interface Window {
    jQuery: any;
    $: any;
  }
}
export const getFormDetails = (forms: IClinicalForm[], formId: string) => {
  let formData = null;
  if (forms && forms.length > 0) {
    formData = forms.find((form: IClinicalForm) => {
      return form.id === formId;
    });
  }
  return formData;
}
export const formatDate = (date: string) => {
  const date_val = new Date(date);
  return date_val.toLocaleString();
}

export const getCustomDate = (key: string): any => {
  const date = new Date();
  switch (key) {
    case 'FULL_YEAR':
      return date.getFullYear();
    default:
      return date;
  }
}

export function minTwoDigits(n: number) {
  return (n < 10 ? "0" : "") + n;
}