import  { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  FORMELEMENTTYPE,
  FORMFIELDSTATUS,
  IFormElement,
} from "../../../models/Common";
import LabelText from "../../atoms/LabelText/LabelText";
import FormInputText from "../../atoms/FormInputText/FormInputText";
import FormSelectBox from "../../molecules/FormSelectBox/FormSelectBox";
import FormRadioGroup from "../../molecules/FormRadioGroup/FormRadioGroup";
import FormCheckboxGroup from "../../molecules/FormCheckboxGroup/FormCheckboxGroup";
import FormDatePicker from "../../molecules/FormDatePicker/FormDatePicker";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import FormViewerFieldStatus from "../FormViewerFieldStatus/FormViewerFieldStatus";
import { useParams } from "react-router-dom";
import { AppSMService } from '../../../network/useAxios';
import { IFieldReview } from "../../../models/ClinicalForm";
import { useDataValidation } from "../../../utils/useDataValidation";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAppSelector } from "../../../hooks";
import { STORE_CONFIG } from "../../../store/storeConstant";
import { InitialStateTypes } from "../../../store/slices/globalSlice";
interface IProps {
  formElement: IFormElement;
  showComments?: boolean;
  fieldReview?: IFieldReview | null | undefined;
  handleStatusChange?: () => void;
  openFieldReviewComments?: (fieldId: string) => void;
}
const FormViewerFieldRenderer = (props: IProps) => {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const {study,user } = appDataContext;
  const { checkUnitField }: any = useDataValidation();
  const params = useParams();
  const [formField, setFormField] = useState<IFormElement>(props.formElement);
  const [fieldReview, setFieldReview] = useState<
    IFieldReview | null | undefined
  >(null);
  const renderFormElement = (item: IFormElement) => {
    let units: any = [];
    let showUnits: boolean = false;
    if (checkUnitField(item.definition)) {
      units = item.definition?.units.split(",");
      showUnits = true;
    }
    switch (item.definition.type) {
      case FORMELEMENTTYPE.PARAGRAPH:
        return <LabelText label={item.definition.label} />;
      case FORMELEMENTTYPE.TEXT:
      case FORMELEMENTTYPE.TEXTAREA:
      case FORMELEMENTTYPE.NUMBER:
        return (
          <Box>
            <FormInputText
              id={item.id}
              type={item.definition.type}
              label={item.definition.label}
              name={item.definition.name}
              disabled={true}
              style={{ width: showUnits ? "50%" : "100%" }}
            />
            {showUnits && (
              <FormControl
                style={{
                  width: "45%",
                  marginLeft: "10px",
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                <InputLabel id={item.id}>
                  {item.definition?.unitLabel}
                </InputLabel>
                <Select
                  labelId={item.id}
                  label={item.definition?.unitLabel}
                  disabled={false}
                >
                  {units.map((option: any) => (
                    <MenuItem key={option} value={option} disabled>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        );
      case FORMELEMENTTYPE.SELECT:
        return (
          <FormSelectBox
            id={item.id}
            label={item.definition.label}
            value={item.definition.label}
            isDisabled={true}
            options={item.definition.values}
          />
        );
      case FORMELEMENTTYPE.RADIOGROUP:
        return (
          <FormRadioGroup
            id={item.id}
            value={item.definition.label}
            isDisabled={true}
            options={item.definition.values}
          />
        );
      case FORMELEMENTTYPE.CHECKBOXGROUP:
        console.log("FORMELEMENTTYPE.CHECKBOXGROUP", item);
        return (
          <FormCheckboxGroup
            id={item.id}
            value={item.definition.label}
            isDisabled={true}
            options={item.definition.values}
          />
        );
      case FORMELEMENTTYPE.DATE:
        return (
          <FormDatePicker
            id={item.id}
            value={item.definition.label}
            disabled={true}
            label={item.definition.label}
          />
        );
      default:
        return item.definition.type;
    }
  };
  const updateFormFieldStatus = (status: FORMFIELDSTATUS) => {
    if (fieldReview && fieldReview.id) {
      AppSMService.axios
        .patch(`field-review/${fieldReview.id}`, {
          fieldId: formField.id,
          reviewStatus: status,
          reviewedBy: user?.username,
          formId: params.formId,
          studyId: study?.id,
        })
        .then((res) => {
          setFormField({ ...formField, status: status });
          props?.handleStatusChange?.();
          setFieldReview(res.data);
        })
        .catch(() => {
          console.log(
            `Error in updating form field status - ${formField.id} - ${status}`
          );
        });
    } else {
      AppSMService.axios
        .post("field-review", {
          fieldId: formField.id,
          reviewStatus: status,
          reviewedBy: user?.username,
          formId: params.formId,
          studyId: study?.id,
        })
        .then((res) => {
          setFormField({ ...formField, status: status });
          props?.handleStatusChange?.();
          setFieldReview(res.data);
        })
        .catch(() => {
          console.log(
            `Error in updating form field status - ${formField.id} - ${status}`
          );
        });
    }
  };
  useEffect(() => {
    setFormField(props.formElement);
  }, [props.formElement]);
  useEffect(() => {
    setFieldReview(props?.fieldReview);
  }, [props.fieldReview]);
  return (
    <Box sx={{ display: "flex", height: "100%", marginLeft: "10px" }}>
      <Box
        sx={{
          width: "5%",
          display: "flex",
          padding: "10px",
          textAlign: "left",
          alignItems: "center",
          cursor: "pointer",
        }}
        title="Comments"
        onClick={() => props.openFieldReviewComments?.(formField?.id || "")}
      >
        {props.showComments && <ChatOutlinedIcon />}
      </Box>
      <Box
        sx={{
          width: "85%",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          textAlign: "left",
        }}
      >
        <div>{formField.definition.label}</div>
        {renderFormElement(formField)}
      </Box>
      <FormViewerFieldStatus
        currentStatus={fieldReview?.reviewStatus || ""}
        handleStatusChange={updateFormFieldStatus}
      />
    </Box>
  );
};

export default FormViewerFieldRenderer;
