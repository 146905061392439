import { Circle } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { AppSMService } from '../../../network/useAxios';
import { isEmpty } from "lodash";
import { useAppSelector } from "../../../hooks";
import { InitialStateTypes } from "../../../store/slices/globalSlice";
import { STORE_CONFIG } from "../../../store/storeConstant";
interface IFormReviewer{
    studyId: string;
    formId: string;
}
const FormReviewerStatus = (props: IFormReviewer) => {
    const [studyDetails, setStudyDetails] = useState<any>(null);
    const [formReviewedDetails, setFormReviewedDetails] = useState<any>(null);
    const appDataContext: InitialStateTypes = useAppSelector(
        (state) => state[STORE_CONFIG.reducers.appData]
      );
      const {study } = appDataContext;
    const getStudyDetails = (studyId: string) => {
        setStudyDetails(null);
        AppSMService.axios.get(`study/${studyId}`).then((res: any) => {
            console.log(res?.data);
            setStudyDetails(res?.data || null);
            getFormReviewedDetails();
        });
    }
    const getFormReviewedDetails = () => {
        setFormReviewedDetails(null);
        if(!props.formId){
            return;
        }
        AppSMService.axios.get(`form-review/?formId=${props.formId}`).then((res: any) => {
            console.log(res?.data);
            setFormReviewedDetails(res?.data || null);
        });
    }
    const isReviewed = (reviewer: string): boolean => {
        return formReviewedDetails && formReviewedDetails.length > 0 && formReviewedDetails.find((item: any) => item.reviewedBy === reviewer);
    }
    useEffect(() => {
        if(!isEmpty(study?.id))
        {
            getStudyDetails(study?.id || "");
        }
       
    }, ["", props.formId, props.studyId])
    return <Box sx={{display: "flex", width: "100%", flexDirection: "row", cursor: "pointer"}}>{studyDetails?.reviewers && studyDetails?.reviewers?.length > 0 && studyDetails?.reviewers.map((reviewer: string) => {
        return <div key={reviewer} title={reviewer}><Circle  color={isReviewed(reviewer) ? "success" : "warning"} sx={{width: "15px", height: "15px", marginLeft: "3px"}}/></div>
    })}</Box>
}
export default FormReviewerStatus;