import React from "react";
import { TextField } from "@mui/material";
import { FORMELEMENTTYPE } from "../../../models/Common";
import "./FormInputText.css";
export interface IFormInputTextProps {
  id: string | undefined | null;
  label: string;
  name: string;
  type?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  style?:any;
}
const FormInputText = (props: IFormInputTextProps) => {
  const { id, label, name, autoComplete, autoFocus, required, type, value, disabled,style } = props;
  return (
    <TextField
      margin="normal"
      type={type || "text"}
      required={required}
      fullWidth
      id={id || ""}
      label={label}
      name={name}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      value={value}
      disabled={disabled}
      multiline={type === FORMELEMENTTYPE.TEXTAREA}
      rows={type === FORMELEMENTTYPE.TEXTAREA ? 3 : 0}
      style={style}
    />
  );
};
export default FormInputText;
