
export const STORE_CONFIG={
    thunk:{
     login:{
         name:"login"
     },
     userMe:{
        name:"user/me"
     }
    },
    reducers:{
     appData:"app"
    }
 }
 