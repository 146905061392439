import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ClearAllOutlined, SaveOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  InputLabel,
  FormLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";

import DialogTitle from "@mui/material/DialogTitle";
import {  isEmpty } from "lodash";
import { getCustomDate, minTwoDigits } from "../../../common/util";
import { CUSTOM_DATE_KEY, FIELD_MAPPER_KEY } from "../../../common/constants";
import LabelText from "../../atoms/LabelText/LabelText";
import { ILogFormRequest, ILogformModalProps, LOG_FORM_MODE } from "./types";
import { AppSMService } from '../../../network/useAxios';


export default function LogformModal({
  showEditModal,
  logFormFields,
  logFormRows,
  deFormId,
  formId,
  logFormMode,
  loadFormData,
}: ILogformModalProps) {
  const [openModal, setOpenModal] = useState(true);
  const fullWidth=true;
  const maxWidth :DialogProps["maxWidth"]="sm";
  const logFormRowsData = logFormRows;
  const handleModalClose = () => {
    setOpenModal(false);
    showEditModal();
    loadFormData();
  };

  const handleFieldChange = (e: any, element: any, type?: string) => {
    logFormRowsData[element?.id] = e.target.value;
    logFormRowsData[element?.definition?.name] = e.target.value;
  };

  const year = getCustomDate(CUSTOM_DATE_KEY.FULL_YEAR);

  const renderFormObj = () => {
    const _formFields = logFormFields;

    if (!isEmpty(_formFields)) {
      return _formFields?.map((element: any, subindex: any) => {
        return (
          <Grid item xs={12} md={12} spacing={2} m={2} pt={2} mb={0} key={`${element.definition.type}_${subindex}`}>
            {element.definition.type === "paragraph" ||
            element.definition.type === "header" ? (
              fieldMapper(
                element.definition.type,
                element.definition,
                subindex,
                element
              )
            ) : (
              <FormControl variant="standard" fullWidth>
                <Grid
                  container
                  spacing={2}
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                
                  {fieldMapper(
                    element.definition.type,
                    element.definition,
                    subindex,
                    element
                  )}
                </Grid>
              </FormControl>
            )}
          </Grid>
        );
      });
    }
    return null;
  };
  const fieldMapper = (
    key: string,
    definition: any,
    elementIndex: any,
    element: any
  ) => {
    const gridBreakPoint = 6;
    switch (key) {
      case FIELD_MAPPER_KEY.HEADER:
        return <LabelText label={definition.label} />;
      case FIELD_MAPPER_KEY.PARAGRAPH:
        return (
          <Box sx={{ width: "100%", maxWidth: 800 }}>
            <Typography variant="subtitle1" gutterBottom>
              {definition?.name}
            </Typography>
          </Box>
        );
      case FIELD_MAPPER_KEY.STRING:
      case FIELD_MAPPER_KEY.TEXT:
      case FIELD_MAPPER_KEY.NUMBER:
      case FIELD_MAPPER_KEY.TEXTAREA:
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <TextField
              label={definition?.name}
              style={{
                width:
                  definition?.units && definition?.units.length !== 0
                    ? "70%"
                    : "100%",
              }}
              type={key}
              defaultValue={
                logFormRowsData[element.id] != null
                  ? logFormRowsData[element.id]
                  : ""
              }
              onChange={(event) => handleFieldChange(event, element, key)}
              disabled={false}
              multiline={key === FIELD_MAPPER_KEY.TEXTAREA}
              rows={key === FIELD_MAPPER_KEY.TEXTAREA ? 3 : 0}
            />
            {definition?.units && definition?.units.length !== 0 && (
              <FormControl fullWidth>
                <InputLabel id={element.id}>Units</InputLabel>
                <Select
                  labelId={element.id}
                  defaultValue={
                    logFormRowsData[element.id] != null
                      ? logFormRowsData[element.id]
                      : ""
                  }
                  style={{
                    width: "28%",
                    marginLeft: "10px",
                  }}
                  onChange={(event) => handleFieldChange(event, element, key)}
                >
                  {definition?.values?.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        );
      case FIELD_MAPPER_KEY.SELECT:
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <FormControl fullWidth>
              <InputLabel id={element.id}>{definition?.name}</InputLabel>
              <Select
                labelId={element.id}
                defaultValue={
                  logFormRowsData[element.id] != null
                    ? logFormRowsData[element.id]
                    : ""
                }
                onChange={(event) => handleFieldChange(event, element, key)}
                disabled={false}
              >
                {definition?.values?.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      case FIELD_MAPPER_KEY.CHECKBOX_GROUP:
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <FormControl fullWidth>
              <FormLabel component="legend">{definition?.name}</FormLabel>
              <FormGroup
                row
                onChange={(event) => handleFieldChange(event, element, key)}
              >
                {definition?.values?.map((option: any) => (
                  <FormControlLabel
                  key={element.id}
                    defaultValue={option.value}
                    checked={
                      (logFormRowsData[element.id] != null
                        ? logFormRowsData[element.id]
                        : "") === option.value
                        ? true
                        : false
                    }
                    control={<Checkbox />}
                    label={option.label}
                    disabled={false}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        );
      case FIELD_MAPPER_KEY.RADIO_GROUP:
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <FormControl>
              <FormLabel id={element.id}>{definition?.name}</FormLabel>
              <RadioGroup
                aria-labelledby={element.id}
                row
                onChange={(event) => handleFieldChange(event, element, key)}
              >
                {definition?.values?.map((option: any) => (
                  <FormControlLabel
                  key={element.id}
                    defaultValue={option.value}
                    checked={
                      (logFormRowsData[element.id] != null
                        ? logFormRowsData[element.id]
                        : "") === option.value
                        ? true
                        : false
                    }
                    control={<Radio />}
                    label={option.label}
                    disabled={false}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        );
      case FIELD_MAPPER_KEY.DATE:
        { const date = logFormRowsData[element.id]?.split("-") || ["", "", ""];
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <FormControl>
              <InputLabel id="day">Day</InputLabel>
              <Select
                labelId="day"
                label="Day"
                defaultValue={
                  logFormRowsData[element.id] != null ? date[2] : ""
                }
                sx={{
                  width: { xs: "90px", sm: "90px" },
                }}
                style={{ marginLeft: "5px", marginRight: "5px" }}
                onChange={(event) => {
                  const _event = Object.assign(event);

                  date[2] = _event.target.value;
                  _event.target.value = date.join("-");
                  handleFieldChange(_event, element, key);
                }}
                disabled={false}
              >
                <MenuItem
                  value={logFormRowsData[element.id] != null ? date[2] : ""}
                >
                  <em>Select Date</em>
                </MenuItem>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="month">Month</InputLabel>
              <Select
                labelId="month"
                label="Month"
                defaultValue={
                  logFormRowsData[element.id] != null ? date[1] : ""
                }
                sx={{
                  width: { xs: "90px", sm: "90px" },
                }}
                style={{ marginLeft: "5px", marginRight: "5px" }}
                onChange={(event) => {
                  const _event = Object.assign(event);
                  date[1] = _event.target.value;
                  _event.target.value = date.join("-");
                  handleFieldChange(_event, element, key);
                }}
                disabled={false}
              >
                <MenuItem
                  value={logFormRowsData[element.id] != null ? date[1] : ""}
                >
                  <em>Select Month</em>
                </MenuItem>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((option) => (
                  <MenuItem key={option} value={minTwoDigits(option)}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="year">Year</InputLabel>
              <Select
                labelId="year"
                label="Year"
                defaultValue={
                  logFormRowsData[element.id] != null ? date[0] : ""
                }
                sx={{
                  width: { xs: "90px", sm: "90px" },
                }}
                style={{ marginLeft: "5px", marginRight: "5px" }}
                onChange={(event) => {
                  const _event = Object.assign(event);
                  date[0] = _event.target.value;
                  _event.target.value = date.join("-");
                  handleFieldChange(_event, element, key);
                }}
                disabled={false}
              >
                <MenuItem
                  defaultValue={
                    logFormRowsData[element.id] != null ? date[0] : ""
                  }
                >
                  <em>Select Year</em>
                </MenuItem>
                {Array.from(new Array(60), (val, index) => year - index).map(
                  (option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        ); }
      default:
        return;
    }
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const getHeaderName = () => {
    return "Logform";
  };
  const getSubHeaderName = () => {
    return "showing current values of the log form";
  };
  const saveLogForm = () => {
    let _URL = "/de-fields";
    const _requestData: ILogFormRequest = {
      deformId: deFormId,
      formId: formId,
      logFormData: [],
    };
    let apiService;
    let Lid: string = "";
    logFormFields.forEach(({ id }: any) => {
      if (!isEmpty(logFormRowsData[id])) {
        Lid = logFormRowsData.Lid;
        _requestData.logFormData.push({
          fieldId: id,
          fieldData: logFormRowsData[id],
        });
      }
    });
    if (logFormMode === LOG_FORM_MODE.EDIT) {
      _URL = `/de-fields/${Lid}`;
      apiService = AppSMService.axios.patch(`${_URL}`, _requestData);
    } else {
      apiService = AppSMService.axios.post(`${_URL}`, _requestData);
    }

    apiService
      .then(() => {
        handleModalClose();
      })
      .catch((e: any) => {
        console.log(`Error in fetching forms list - ${e}`);
      });
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography gutterBottom variant="h5" component="div">
            {getHeaderName()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {getSubHeaderName()}
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
            style={{ width: "100%" }}
          >
            {renderFormObj()}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            endIcon={<ClearAllOutlined />}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            endIcon={<SaveOutlined />}
            onClick={saveLogForm}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
