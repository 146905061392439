import { GridColDef } from "@mui/x-data-grid";

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const STUDY_GRID_COLUMN_CLIENT: GridColDef[] =  [
  {
    field: "id",
    headerName: "Study ID",
    width: 250,
    editable: false,
  },
  {
    field: "name",
    headerName: "Study Name",
    width: 200,
    editable: false,
  },
  {
    field: "reviewMinPercentage",
    headerName: "Review Min Percentage",
    width: 180,
    editable: false,
  },
  {
    field: "reviewers",
    headerName: "Reviewers",
    width: 400,
    editable: false,
  },]
