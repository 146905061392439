
import React, { Fragment, useContext, useRef } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { IHistoryItem } from '.';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AlertMessage from '../../molecules/Alert/Alert';
import Loader from '../../molecules/Loader/Loader';
import { useAppSelector } from '../../../hooks';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { AppSMService } from '../../../network/useAxios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialog-paperFullScreen': {
    width: '90%',
    height: '90%'
  }
}));

export default function History({ meta: { show, name, code }, showModal }: any) {
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { study } = appDataContext;
  const [stateObj, setStateObject] = React.useState({
    open: show,
    historyData: [],
    search: '',
    reviewers: {
      value: study.reviewers.length > 0 ? study.reviewers[0] : '',
      data: [study.reviewers]
    },
    snackbar: {
      show: false,
      isError: false,
      message: ''
    },
    loader: {
      show: false
    }
  });

  const dateRef = useRef<HTMLInputElement>(null);
  function getHistoryDetails() {
    showLoader(true);
    AppSMService.axios
      .get(
        `/field-review/history?studyId=${study?.id}&dbMode=${study?.mode}&reviewer=${stateObj.reviewers.value}&date=${dateRef.current?.value}`
      )
      .then(({ status, data }) => {
        if (status === 200) {
          setStateObject(() => {
            return {
              ...stateObj,
              historyData: data
            };
          });
        }
      });
  }

  React.useEffect(() => {
    showLoader(false);
    handleErrorMessageUI(
      stateObj.historyData.length > 0 ? 'Data retrieved  successfully' : 'No Data found',
      false,
      true
    );
  }, [stateObj.historyData]);
  React.useEffect(() => {
    setStateObject({ ...stateObj, open: show });
  }, [show]);

  const handleClose = () => {
    setStateObject({ ...stateObj, open: false });
    showModal(code, false);
  };

  const onHandleSubmit = () => {
    handleClose();
  };
  const onTextFieldChange = (event: any, key: string) => {
    setStateObject({
      ...stateObj,
      [key]: event.target.value as string
    });
  };
  const HistoryCard = ({
    createdOn,
    reviewedBy,
    reviewStatus,
    formName,
    fieldName
  }: IHistoryItem) => {
    const date = moment(createdOn).format('MM-DD-YYYY');
    return (
      <Card variant="outlined" style={{ padding: '5px', marginTop: '5px' }}>
        <CardContent style={{ padding: '6px' }}>
          <Grid container>
            <Grid item xs={2} style={{ alignSelf: 'center' }}>
              <Typography variant="body2" component="div">
                {date}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    {formName}{' '}
                    <span style={{ borderRadius: '5px', borderColor: 'green' }}>
                      <Button
                        variant="outlined"
                        style={{
                          fontSize: 'xx-small',
                          fontWeight: 'bold',
                          color: reviewStatus.toLowerCase() === 'approved' ? 'green' : 'orange'
                        }}>
                        {reviewStatus}
                      </Button>
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    {fieldName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    {reviewedBy}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const filteredData = stateObj.historyData.filter((item: IHistoryItem) => {
    if (isEmpty(stateObj.search)) {
      return true;
    }
    return (
      item.reviewStatus.indexOf(stateObj.search) != -1 ||
      item.reviewedBy.indexOf(stateObj.search) != -1
    );
  });
  const handleSelectChange = (event: SelectChangeEvent) => {
    setStateObject({
      ...stateObj,
      reviewers: {
        ...stateObj.reviewers,
        value: event.target.value as string
      }
    });
  };
  const renderFilterUI = () => {
    return (
      <Box sx={{ minWidth: 120 }}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="Select Date" format="DD-MM-YYYY" inputRef={dateRef} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth style={{ marginLeft: '10px' }}>
              <InputLabel id="select-reviewer-label">Select Reviewers</InputLabel>
              <Select
                labelId="select-reviewer-label"
                id="select-reviewer"
                value={stateObj.reviewers.value}
                label="Select Reviewers"
                onChange={handleSelectChange}>
                {stateObj.reviewers.data.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" onClick={getHistoryDetails} style={{ marginLeft: '30px' }}>
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };
  const handleErrorMessageUI = (message: string, isError: boolean, showSnackbar: boolean) => {
    setStateObject({
      ...stateObj,
      snackbar: {
        ...stateObj.snackbar,
        message: message,
        isError: isError,
        show: showSnackbar
      }
    });
  };
  const showLoader = (showLoader: boolean) => {
    setStateObject({
      ...stateObj,
      loader: {
        ...stateObj.loader,
        show: showLoader
      }
    });
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        fullScreen
        aria-labelledby="customized-dialog-title"
        open={stateObj.open}
        fullWidth={true}
        maxWidth={'md'}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              {renderFilterUI()}

              <Divider style={{ marginTop: '10px' }} />
            </Grid>
            <Grid item xs={12}>
              {filteredData.map((item: IHistoryItem) => {
                console.log('Item', item);
                return <>{HistoryCard(item)}</>;
              })}
            </Grid>
          </Grid>
          {stateObj.loader.show && (
            <Loader open={stateObj.loader.show} handleClose={() => showLoader(false)} />
          )}
        </DialogContent>
      </BootstrapDialog>
      {stateObj.snackbar.show && (
        <AlertMessage
          openAlert={stateObj.snackbar.show}
          openAlertMsg={stateObj.snackbar.message}
          openAlertStatus={stateObj.snackbar.isError ? 'error' : 'success'}
          handleCloseAlert={() => handleErrorMessageUI('', false, false)}
        />
      )}
    </React.Fragment>
  );
}
