import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
interface IAccess {
  id: string;
  name: string;
}
export interface IAccessRightsProps {
  accessRights: IAccess[];
  selectedAccess: any[];
  selectedAccessID: string;
  handleSetOptions: (value: string[]) => void;
  handleClose: () => void;
  selectAccess: () => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AccessRights(props: IAccessRightsProps) {
  const [options, setOptions] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { value },
    } = event;
    setOptions(typeof value === "string" ? value.split(",") : value);
    props.handleSetOptions(
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Box
      sx={{
        position: "absolute" as const,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Add Access Rights
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <FormControl
        sx={{
          m: 1,
          width: "80%",
          minHeight: "200px",
          marginTop: "30px",
          marginLeft: "20px",
          paddingRight: "50px",
        }}
      >
        <InputLabel id="demo-multiple-checkbox-label">Access RIghts</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          multiple
          value={options}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {props.accessRights.map((item: any, index: number) => {
            if (props.selectedAccess.indexOf(item.id) < 0) {
              return (
                <MenuItem key={index} value={item.name}>
                  <Checkbox checked={options.indexOf(item.name) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="right"
        spacing={2}
        pt={4}
        pb={2}
        pr={5}
      >
        <Button variant="outlined" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={props.selectAccess}>
          Add
        </Button>
      </Stack>
    </Box>
  );
}

export default AccessRights;
