import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  Modal,
  TextField,
  InputLabel,
  FormLabel,
  FormHelperText
} from '@mui/material';
import { Message, SaveOutlined, ClearAllOutlined } from '@mui/icons-material';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import { getCustomDate, minTwoDigits } from '../../../common/util';
import { CUSTOM_DATE_KEY, FIELD_MAPPER_KEY } from '../../../common/constants';
import {
  DataEntryFormState,
  DeFormFields,
  IDeFormField,
  IDeFormReviewFields,
  formParams
} from './types';
import LabelText from '../../atoms/LabelText/LabelText';
import { IFormDefinition } from '../../../models/Common';
import FormViewerFieldStatus from '../../organisms/FormViewerFieldStatus/FormViewerFieldStatus';
import ReviewerComments from '../../organisms/ReviewerComments/ReviewerComments';
import { FORMSTATUS } from '../../../models/ClinicalForm';
import '../../organisms/FormViewerFieldStatus/FormViewerFieldStatus.css';
import { IDeForms } from '../../pages/DataEntryOperator/types';
import Paper from '@mui/material/Paper';
import './DataEntryForm.css';
import { useDataValidation } from '../../../utils/useDataValidation';
import Loader from '../Loader/Loader';
import { BREAD_CRUMBS, ROUTING_CONSTANTS } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { InitialStateTypes, updateMetaData } from '../../../store/slices/globalSlice';
import { NETWORK_CONSTANTS } from '../../../network/networkConstants';
import { AppSMService } from '../../../network/useAxios';
function ClinicalForm(props: formParams) {
  const navigate = useNavigate();
  const {
    state: {
      deFormId,
      formId,
      isDataEntryOperator,
      selectedForm,
      fieldCommentID,
      dataViewAccessRights
    }
  } = useLocation();
  const {
    checkIsJson,
    stringToJson,
    jsonToString,
    checkIsEmpty,
    findIndex,
    find,
    checkUnitField
  }: any = useDataValidation();
  const deForm: IDeForms = selectedForm;
  const breadCrumbList = [
    { title: BREAD_CRUMBS.HOME, link: ROUTING_CONSTANTS.DASHBOARD },
    { title: props.pageName, link: ROUTING_CONSTANTS.DATA_ENTRY },
    { title: deForm.formName, link: '' }
  ];
  const { DE_FORMS, FORMS, FIELD_REVIEW, DE_FIELDS } = NETWORK_CONSTANTS.END_POINTS;
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const dispatch = useAppDispatch();
  const { study, user } = appDataContext;
  const [dataEntryFormState, setDataEntrFormState] = useState<DataEntryFormState>({
    deFormFields: [],
    formData: {},
    formFields: null,
    formReviewFields: {},
    isReviewCommentsOpen: false,
    selectedFieldId: ''
  });
  const {
    deFormFields,
    formData,
    formFields,
    formReviewFields,
    isReviewCommentsOpen,
    selectedFieldId
  } = dataEntryFormState;

  const studyId: string = study?.id;

  useEffect(() => {
    loadFormData();
    dispatch(updateMetaData({ clientId: '', studyId: studyId, projectId: '' }));
  }, []);
  const updateState = (data: any) => {
    setDataEntrFormState((prevState: DataEntryFormState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };

  const loadFormData = async () => {
    try {
      const promiseAllResponse = await Promise.all([
        AppSMService.axios.get(`/${DE_FORMS}/${deFormId}`),
        AppSMService.axios.get(`/${FORMS}/${formId}`),
        AppSMService.axios.get(`${FIELD_REVIEW}?formId=${deFormId}`)
      ]);
      const _formData: any = {};
      const _deFormData: any = {};
      const _deFormReviewFields: any = {};
      let _formFields: any = {};
      promiseAllResponse.forEach(({ status, data, config }) => {
        if (status === 200) {
          if (config.url === `/${DE_FORMS}/${deFormId}`) {
            if (!checkIsEmpty(data?.fields)) {
              data?.fields?.forEach(({ fieldId, fieldData, id }: DeFormFields) => {
                _formData[fieldId] = fieldData;
                _deFormData[fieldId] = { id };
              });
            }
          } else if (config.url === `/${FORMS}/${formId}`) {
            _formFields = data;

            data.fields.forEach((field: IDeFormField, index: number) => {
              if (checkIsEmpty(_formData)) {
                _formData[field.id] = '';
                _deFormReviewFields[field.id] = { rId: '', status: '' };
              }

              _formFields.fields[index]['show'] = true;
              if (!checkIsEmpty(field.parentId)) {
                const _index = findIndex(_formFields.fields, field.parentId, 'id');

                _formFields.fields[index]['show'] = !checkIsEmpty(_formData[field.id]);
                if (_formFields.fields[_index].definition.values) {
                  _formFields.fields[_index].definition.values.map((option: any) => {
                    if (option?.value === field.condition) {
                      option['childId'] = field.id;
                      option['childCondition'] = field.condition;
                    }
                  });
                }

                _formFields.fields[_index]['childId'] = field.id;
              }
            });
          } else {
            data?.forEach(({ fieldId, reviewStatus, id }: IDeFormReviewFields) => {
              _deFormReviewFields[fieldId] = {
                rId: id,
                status: reviewStatus
              };
            });
          }
        }
      });
      const _stateObject: any = {
        formData: _formData,
        deFormFields: _deFormData,
        formFields: _formFields,
        formReviewFields: _deFormReviewFields
      };

      if (!checkIsEmpty(fieldCommentID)) {
        _stateObject.selectedFieldId = fieldCommentID;
        _stateObject.isReviewCommentsOpen = true;
      }
      updateState(_stateObject);
    } catch {
      throw Error('Promise failed');
    }
  };

  const handleTextFieldChange = (e: any, name: string, eIndex: number, fromUnits?: boolean) => {
    let data: any = e.target.value;
    const value: string = formData[name];
    let units: string = '';
    if (fromUnits) {
      const _data = !checkIsEmpty(value) ? value : '';
      if (checkIsJson(_data)) {
        units = stringToJson(_data).units;
      }
      data = {
        value: e.target.value,
        units: !checkIsEmpty(units) ? units : ''
      };
      data = jsonToString(data);
    }
    updateState({ formData: { ...formData, [name]: data } });

    updateHelperText(eIndex);
  };

  const handleRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: IDeFormField,
    eIndex: number
  ) => {
    showHideChildField(field, e);

    updateHelperText(eIndex);
  };

  function showHideChildField(field: IDeFormField, e: React.ChangeEvent<HTMLInputElement>) {
    const option = find(field.definition.values, e.target.value, 'value');
    const index = findIndex(formFields?.fields, option?.childId, 'id');
    const _formData: any = { ...formData };
    const _formFields: any = { ...formFields };
    if (index !== -1 && !checkIsEmpty(field?.childId) && isDataEntryOperator) {
      field.definition.values.forEach((dField: any) => {
        const _fieldIndex = findIndex(formFields?.fields, dField.childId, 'id');
        const show =
          _formFields?.fields[_fieldIndex].condition === e.target.value && e.target.checked;
        _formFields.fields[_fieldIndex]['show'] = show;
        _formData[dField.childId] = null;
        if (
          _formFields.fields[_fieldIndex].definition.type === 'checkbox-group' ||
          _formFields.fields[_fieldIndex].definition.type === 'radio-group'
        ) {
          showHideDeepChildField(_formFields.fields[_fieldIndex], e, _formFields, _formData);
        }
      });
    }
    _formData[field?.id] = e.target.checked ? e.target.value : null;
    updateState({ formData: _formData, formFields: _formFields });
  }
  function showHideDeepChildField(
    field: IDeFormField,
    e: React.ChangeEvent<HTMLInputElement>,
    _formFields: any,
    _formData: any
  ) {
    field.definition?.values?.forEach((dField: any) => {
      const _fieldIndex = findIndex(formFields?.fields, dField.childId, 'id');
      const show =
        _formFields?.fields[_fieldIndex].condition === e.target.value && e.target.checked;
      _formFields.fields[_fieldIndex]['show'] = show;
      _formData[dField.childId] = null;
      if (
        _formFields.fields[_fieldIndex].definition.type === 'checkbox-group' ||
        _formFields.fields[_fieldIndex].definition.type === 'radio-group'
      ) {
        showHideDeepChildField(_formFields.fields[_fieldIndex], e, _formFields, _formData);
      }
    });
    return { fields: _formFields.fields, formData: _formData };
  }

  const handleSelectChange = (e: any, name: string, eIndex: number, fromUnits?: boolean) => {
    let data: any = e.target.value;
    let value: string = formData[name];
    if (fromUnits) {
      const _data = !checkIsEmpty(value) ? value : '';
      if (checkIsJson(_data)) {
        const _dataJson = stringToJson(_data);
        value = _dataJson.value;
      }
      data = {
        value: value,
        units: e.target.value
      };
      data = jsonToString(data);
    }
    updateState({ formData: { ...formData, [name]: data } });

    updateHelperText(eIndex);
  };
  function updateHelperText(eIndex: number) {
    const _formFields = formFields?.fields;
    _formFields[eIndex].definition['helperText'] = '';
    updateState({ formFields: { ...formFields, fields: _formFields } });
  }

  const year = getCustomDate(CUSTOM_DATE_KEY.FULL_YEAR);

  const renderFormObj = () => {
    const _formFields = formFields?.fields;

    if (!checkIsEmpty(_formFields)) {
      return _formFields?.map((element: any, subindex: any) => {
        if (!element?.show) {
          return null;
        }
        return (
          <Grid
            item
            xs={12}
            md={12}
            spacing={2}
            m={2}
            pt={2}
            mb={0}
            key={`${element.definition.type}_${subindex}`}>
            {element.definition.type === 'paragraph' || element.definition.type === 'header' ? (
              fieldMapper(element.definition.type, element.definition, subindex, element)
            ) : (
              <FormControl variant="standard" fullWidth>
                <Grid
                  container
                  spacing={2}
                  style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={1} md={1}>
                    {!dataViewAccessRights && (
                      <InputAdornment position="start">
                        <Message onClick={() => openFieldReviewComments(element.id)} />
                      </InputAdornment>
                    )}
                  </Grid>
                  {fieldMapper(element.definition.type, element.definition, subindex, element)}

                  {!dataViewAccessRights && (
                    <FormViewerFieldStatus
                      currentStatus={formReviewFields[element.id]?.status}
                      showPointer={isDataEntryOperator === false}
                      handleStatusChange={(type) =>
                        !isDataEntryOperator ? saveFormReviewInfo(element, type) : undefined
                      }
                    />
                  )}
                </Grid>
              </FormControl>
            )}
          </Grid>
        );
      });
    }
    return null;
  };

  const fieldMapper = (
    key: string,
    definition: IFormDefinition,
    elementIndex: any,
    element: any
  ) => {
    const gridBreakPoint = isDataEntryOperator === true ? 6 : 6;
    let units: any = [];
    let showUnits: boolean = false;
    let value: string = formData[element.id] != null ? formData[element.id] : '';
    let unitValue: string = '';
    if (checkUnitField(definition)) {
      units = definition?.units.split(',');
      showUnits = true;
      if (checkIsJson(value)) {
        const _valueJSON: any = stringToJson(value);
        value = _valueJSON?.value;
        unitValue = _valueJSON?.units;
      }
    }
    switch (key) {
      case FIELD_MAPPER_KEY.HEADER:
        return <LabelText label={definition.label} />;
      case FIELD_MAPPER_KEY.PARAGRAPH:
        return (
          <Box sx={{ width: '100%', maxWidth: 800 }}>
            <Typography variant="subtitle1" gutterBottom>
              {definition?.label}
            </Typography>
          </Box>
        );
      case FIELD_MAPPER_KEY.TEXT:
      case FIELD_MAPPER_KEY.NUMBER:
      case FIELD_MAPPER_KEY.TEXTAREA:
        return (
          <>
            {' '}
            <Grid item xs={showUnits ? 3 : gridBreakPoint} md={showUnits ? 3 : gridBreakPoint}>
              <TextField
                label={definition?.label}
                style={{
                  width: '100%'
                }}
                type={key}
                value={value}
                onChange={(event) =>
                  handleTextFieldChange(event, element.id, elementIndex, showUnits)
                }
                disabled={!isDataEntryOperator}
                multiline={
                  key === FIELD_MAPPER_KEY.TEXTAREA ? (definition?.rows > 1 ? true : false) : false
                }
                rows={key === FIELD_MAPPER_KEY.TEXTAREA ? definition?.rows : 0}
                required={definition?.required}
                helperText={definition?.helperText}
                maxRows={key === FIELD_MAPPER_KEY.TEXTAREA ? definition?.rows : 1}
              />
            </Grid>
            {showUnits && (
              <Grid item xs={3} md={3}>
                <FormControl
                  style={{
                    width: '100%'
                  }}>
                  <InputLabel id={element.id}>{definition?.unitLabel}</InputLabel>
                  <Select
                    labelId={element.id}
                    label={definition?.unitLabel}
                    value={unitValue}
                    onChange={(event) => handleSelectChange(event, element.id, elementIndex, true)}
                    disabled={!isDataEntryOperator}>
                    {units.map((option: any) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  {definition?.helperText && (
                    <FormHelperText>{definition?.helperText}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
          </>
        );
      case FIELD_MAPPER_KEY.SELECT:
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <FormControl required={definition?.required} fullWidth>
              <InputLabel id={element.id}>{definition?.label}</InputLabel>
              <Select
                labelId={element.id}
                value={value}
                onChange={(event) => handleSelectChange(event, element.id, elementIndex)}
                disabled={!isDataEntryOperator}>
                {definition?.values?.map((option: any) => (
                  <MenuItem key={option} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {definition?.helperText && <FormHelperText>{definition?.helperText}</FormHelperText>}
            </FormControl>
          </Grid>
        );
      case FIELD_MAPPER_KEY.CHECKBOX_GROUP:
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <FormControl required={definition?.required} fullWidth>
              <FormLabel component="legend">{definition?.label}</FormLabel>
              <FormGroup
                row
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleRadioChange(event, element, elementIndex)
                }>
                {definition?.values?.map((option: any) => (
                  <FormControlLabel
                    key={formData[element.id]}
                    value={option.value}
                    checked={
                      (formData[element.id] != null ? formData[element.id] : '') === option.value
                    }
                    control={<Checkbox />}
                    label={option.label}
                    disabled={!isDataEntryOperator}
                  />
                ))}
              </FormGroup>
              {definition?.helperText && <FormHelperText>{definition?.helperText}</FormHelperText>}
            </FormControl>
          </Grid>
        );
      case FIELD_MAPPER_KEY.RADIO_GROUP:
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <FormControl required={definition?.required}>
              <FormLabel id={element.id}>{definition?.label}</FormLabel>
              <RadioGroup
                aria-labelledby={element.id}
                row
                onChange={(event) => handleRadioChange(event, element, elementIndex)}>
                {definition?.values?.map((option: any) => (
                  <FormControlLabel
                    key={`${formData[element.id]}_${option.value}`}
                    value={option.value}
                    checked={
                      (formData[element.id] != null ? formData[element.id] : '') === option.value
                    }
                    control={<Radio />}
                    label={option.label}
                    disabled={!isDataEntryOperator}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        );
      case FIELD_MAPPER_KEY.DATE: {
        const date = formData[element.id]?.split('-') || ['', '', ''];
        return (
          <Grid item xs={gridBreakPoint} md={gridBreakPoint}>
            <FormControl required={definition?.required}>
              <InputLabel id="day">Day</InputLabel>
              <Select
                labelId="day"
                label="Day"
                value={formData[element.id] != null ? date[2] : ''}
                sx={{
                  width: { xs: '90px', sm: '90px' }
                }}
                style={{ marginLeft: '5px', marginRight: '5px' }}
                onChange={(event) => {
                  const _event = Object.assign(event);

                  date[2] = _event.target.value;
                  _event.target.value = date.join('-');
                  handleSelectChange(_event, element.id, elementIndex);
                }}
                disabled={!isDataEntryOperator}>
                <MenuItem value={formData[element.id] != null ? date[2] : ''}>
                  <em>Select Date</em>
                </MenuItem>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl required={definition?.required}>
              <InputLabel id="month">Month</InputLabel>
              <Select
                labelId="month"
                label="Month"
                value={formData[element.id] != null ? date[1] : ''}
                sx={{
                  width: { xs: '90px', sm: '90px' }
                }}
                style={{ marginLeft: '5px', marginRight: '5px' }}
                onChange={(event) => {
                  const _event = Object.assign(event);
                  date[1] = _event.target.value;
                  _event.target.value = date.join('-');
                  handleSelectChange(_event, element.id, elementIndex);
                }}
                disabled={!isDataEntryOperator}>
                <MenuItem value={formData[element.id] != null ? date[1] : ''}>
                  <em>Select Month</em>
                </MenuItem>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((option) => (
                  <MenuItem key={option} value={minTwoDigits(option)}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl required={definition?.required}>
              <InputLabel id="year">Year</InputLabel>
              <Select
                labelId="year"
                label="Year"
                value={formData[element.id] != null ? date[0] : ''}
                sx={{
                  width: { xs: '90px', sm: '90px' }
                }}
                style={{ marginLeft: '5px', marginRight: '5px' }}
                onChange={(event) => {
                  const _event = Object.assign(event);
                  date[0] = _event.target.value;
                  _event.target.value = date.join('-');
                  handleSelectChange(_event, element.id, elementIndex);
                }}
                disabled={!isDataEntryOperator}>
                <MenuItem value={formData[element.id] != null ? date[0] : ''}>
                  <em>Select Year</em>
                </MenuItem>
                {Array.from(new Array(60), (val, index) => year - index).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      }
      default:
        return;
    }
  };

  const renderDataEntryForm = () => {
    return (
      <>
        {/*  <Box
          sx={{
            width: "200px",
            maxWidth: "200px",
            overflowX: "auto",
            border: "1px #ccc solid",
            height: "100%",
          }}
        >
          <FormsList selectedFormId={forms[0].id} />
        </Box>
 */}
        <Box sx={{ width: '100%' }}>
          <Paper
            elevation={3}
            sx={{
              paddingBottom: '20px'
            }}>
            {renderFormObj()}
          </Paper>
        </Box>
      </>
    );
  };
  function formValidation() {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const _formFields = [...formFields?.fields];
    let isValidForm = true;
    Object.keys(formData).forEach((value) => {
      const fieldIndex = findIndex(formFields?.fields, value);

      if (fieldIndex != -1) {
        const required = _formFields[fieldIndex]?.definition?.required;
        const maxlength = _formFields[fieldIndex]?.definition?.maxlength;
        let fieldValue = formData[value];
        if (checkIsJson(fieldValue)) {
          fieldValue = stringToJson(fieldValue)?.value;
        }
        if (required && checkIsEmpty(fieldValue)) {
          _formFields[fieldIndex].definition['helperText'] =
            `${_formFields[fieldIndex]?.definition?.label} required`;
          isValidForm = false;
        } else if (maxlength && fieldValue.length > maxlength) {
          _formFields[fieldIndex].definition['helperText'] =
            `Maximum allowed ${maxlength} char only`;
          isValidForm = false;
        }
      }
    });
    updateState({ formFields: { ...formFields, fields: _formFields } });
    return isValidForm;
  }

  const saveForm = () => {
    if (formValidation()) {
      Object.keys(formData).forEach((value) => {
        const fieldObj = find(formFields?.fields, value);
        if (!checkIsEmpty(fieldObj)) {
          let _URL = `/${DE_FIELDS}`;
          const _requestData: any = {
            deformId: deFormId,
            fieldId: fieldObj?.id,
            fieldData: formData[value]
          };
          let apiService;
          if (!checkIsEmpty(deFormFields[fieldObj?.id]?.id)) {
            _URL = `/${DE_FIELDS}/${deFormFields[fieldObj?.id]?.id}`;
            delete _requestData.deformId;
            apiService = AppSMService.axios.patch(`${_URL}`, _requestData);
          } else {
            apiService = AppSMService.axios.post(`${_URL}`, _requestData);
          }

          apiService
            .then(() => {
              navigate(ROUTING_CONSTANTS.DATA_ENTRY);
            })
            .catch((e: any) => {
              console.log(`Error in fetching forms list - ${e}`);
            });
        }
      });
    }
  };
  const saveFormReviewInfo = (element: IDeFormField, type: string) => {
    const _formReviewFields = { ...formReviewFields };
    let _URL = `/${FIELD_REVIEW}`;
    let _requestData: any = {};
    let apiService;
    const _reviewObject = formReviewFields[element?.id];
    if (!checkIsEmpty(_reviewObject?.status) && !checkIsEmpty(_reviewObject?.rId)) {
      _URL = `${FIELD_REVIEW}/${_reviewObject?.rId}`;
      _requestData = {
        fieldId: element.id,
        reviewedBy: user?.username,
        reviewStatus: type
      };
      apiService = AppSMService.axios.patch(`${_URL}`, _requestData);
    } else {
      _requestData = {
        fieldId: element.id,
        fieldName: element.definition.label,
        reviewedBy: user?.username,
        reviewStatus: type,
        formId: deFormId,
        formName: deForm.formName,
        dbMode: deForm.dbMode,
        studyId: deForm.studyId
      };
      apiService = AppSMService.axios.post(`${_URL}`, _requestData);
    }

    apiService
      .then(() => {
        _formReviewFields[element?.id] = {
          rId: _reviewObject.id,
          status: type
        };
        updateState({ formReviewFields: _formReviewFields });
      })
      .catch((e: any) => {
        console.log(`Error in fetching forms list - ${e}`);
      });
  };
  const handleClose = () => {
    updateState({ isReviewCommentsOpen: false });
  };
  const openFieldReviewComments = (fieldId: string) => {
    updateState({
      isReviewCommentsOpen: fieldId ? true : false,
      selectedFieldId: fieldId ? fieldId : selectedFieldId
    });
  };

  const updateFormStatus = (status: FORMSTATUS) => {
    AppSMService.axios
      .post(`form-review`, {
        reviewStatus: status.toUpperCase(),
        reviewedBy: user?.username,
        formId: deFormId
      })
      .then(() => {
        setTimeout(() => {
          navigate(ROUTING_CONSTANTS.DATA_ENTRY_REVIWER);
        }, 1000);
      })
      .catch((e) => {
        console.log(`Error in updating form ${deFormId} - ${e}`);
      });
  };
  const renderBottonActionUI = () => {
    return (
      <Box>
        <Stack direction="row" alignItems="right" justifyContent="right" spacing={2} pt={4} pb={2}>
          <Button
            variant="outlined"
            endIcon={<ClearAllOutlined />}
            onClick={() =>
              navigate(
                isDataEntryOperator === true
                  ? ROUTING_CONSTANTS.DATA_ENTRY
                  : ROUTING_CONSTANTS.DATA_ENTRY_REVIWER
              )
            }>
            Cancel
          </Button>

          {isDataEntryOperator === false && (
            <Button
              variant="outlined"
              endIcon={<DoDisturbOffIcon />}
              onClick={() => updateFormStatus(FORMSTATUS.REJECTED)}>
              Reject
            </Button>
          )}
          <Button
            variant="contained"
            endIcon={<SaveOutlined />}
            onClick={() => {
              isDataEntryOperator ? saveForm() : updateFormStatus(FORMSTATUS.APPROVED);
            }}>
            {isDataEntryOperator === true ? 'Save' : 'Approve'}
          </Button>
          {isDataEntryOperator && (
            <Button
              variant="contained"
              endIcon={<SaveOutlined />}
              disabled={checkIsEmpty(formData)}
              onClick={() => {
                updateFormStatus(FORMSTATUS.PUBLISHED);
              }}>
              Publish
            </Button>
          )}
        </Stack>
      </Box>
    );
  };
  return (
    <>
      <Breadcrumb breadCrumbList={breadCrumbList} />
      {formFields === null && <Loader open={true} />}
      <Box>
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>{renderDataEntryForm()}</Box>
        {!dataViewAccessRights && renderBottonActionUI()}
      </Box>
      <Modal
        open={isReviewCommentsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}>
        <ReviewerComments
          formId={deFormId}
          studyId={studyId}
          fieldId={selectedFieldId}
          handleClose={handleClose}
          type={'data'}
        />
      </Modal>
    </>
  );
}
export default ClinicalForm;
