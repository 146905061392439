export const LOG_COLUMNS_CONFIG:any = {
  "text": {
    width: 250,
  },
  "string": {
    width: 250,
  },
  "number": {
    width: 100,
  }
};
