import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { IReviewerComments} from "../../../models/Common";
import { AppBar, Button, FormControl, Input, InputLabel, Stack, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import Comments from "../Comments/Comments";
import { AppSMService} from '../../../network/useAxios';
import { useStudyAccessRights } from "../../../utils/useStudyAccessRights";
import { useAppSelector } from "../../../hooks";
import { InitialStateTypes } from "../../../store/slices/globalSlice";
import { STORE_CONFIG } from "../../../store/storeConstant";
interface IProps{
    studyId?:string;
    formId?: string;
    fieldId?:string;
    saveComments?: (obj: any) => void;
    handleClose: () => void;
    type?:string;
}
const ReviewerComments = (props: IProps) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [commentText, setCommentText] = useState<string>("");
    const [reviewerComments, setReviewerComments] = useState<IReviewerComments[]>([]);
    const appDataContext: InitialStateTypes = useAppSelector(
      (state) => state[STORE_CONFIG.reducers.appData]
    );
    const { user } = appDataContext;
    const {replayQueryAccessRights} = useStudyAccessRights();
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }
    const getFieldReviewComments = () => {
        AppSMService.axios.get(`query?fieldId=${props.fieldId}`).then((res: any) => {
            const queryList = res.data || [];
            const groupQueryByReviewers: any = [];
            if(queryList?.length > 0){
                queryList.map((query: any) => {
                    if(groupQueryByReviewers[query.commentedBy] && groupQueryByReviewers[query.commentedBy].comments){
                        groupQueryByReviewers[query.commentedBy].comments = [...groupQueryByReviewers[query.commentedBy].comments, query];
                    } else {
                        groupQueryByReviewers[query.commentedBy] = {
                            "name": query.commentedBy,
                            "type": "reviewer",
                            "comments": [query]
                        };
                    }
                });
            }
            const tempArr: any = [];
            Object.keys(groupQueryByReviewers).forEach((reviewerName: any) => {
                tempArr.push(groupQueryByReviewers[reviewerName]);
            })
            setReviewerComments(tempArr);
        }).catch((e: any) => {
            console.log(`Error in fetching Field Reviewers comments - ${e}`);
        });
    }
    const saveQuery = () => {
        console.log(props);
        if(props.fieldId && user?.username && commentText && props?.formId && props?.studyId){
            const commentObj = {
                "fieldId": props.fieldId,
                "commentedBy": user?.username,
                "comments": commentText,
                "formId": props?.formId,
                "studyId": props?.studyId,
                "type":props?.type
            };
            AppSMService.axios.post(`query`,commentObj).then((res) => {
                console.log(res);
                setCommentText("")
                getFieldReviewComments();
            }).catch((e) => {
                console.log(`Error in saving the comments for form ${props.formId}  field ${props.fieldId} - ${e}`);
            });
        }
    }
    useEffect(() => {
        if(props.fieldId){
            getFieldReviewComments();
        } else {
            setReviewerComments([]);
        }
    }, [props.fieldId])
    return (
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Comments
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            value={selectedTabIndex}
          >
            {reviewerComments?.length > 0 &&
              reviewerComments.map((item: IReviewerComments, index: number) => {
                console.log(item);
                return (
                  <Tab
                    label={item.name}
                    {...a11yProps(index)}
                    onClick={() => setSelectedTabIndex(index)}
                    key={item.name}
                  />
                );
              })}
          </Tabs>
        </Box>
        <Box>
          <div role="tabpanel" style={{ padding: "20px 0" }}>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                height: 250,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              <Comments
                comments={reviewerComments?.[selectedTabIndex]?.comments || []}
              />
            </Box>
          </div>
        </Box>
        <Box sx={{ p: 3 }}>
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="input-with-icon-adornment">
                Comments
              </InputLabel>
              <Input
                style={{ width: "100%" }}
                value={commentText}
                onChange={(event) => setCommentText(event.target.value)}
              />
            </FormControl>
          </Box>
          {replayQueryAccessRights && (
            <Stack
              direction="row"
              alignItems="right"
              justifyContent="right"
              spacing={2}
              pt={4}
              pb={2}
            >
              <Button variant="outlined" onClick={() => props.handleClose?.()}>
                Cancel
              </Button>
              <Button variant="contained" onClick={saveQuery}>
                Add Comment
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    );
}

export default ReviewerComments;