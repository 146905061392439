
import {
  AppBar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AppSMService } from '../../../network/useAxios';
import CustomTabPanel from '../../molecules/CustomTabPanel/CustomTabPanel';
import FormsList from '../FormsList/FormsList';
import { useAppSelector } from '../../../hooks';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { useState } from 'react';
import { NETWORK_CONSTANTS } from '../../../network/networkConstants';

const CreateForm = () => {
  const navigate = useNavigate();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { study, project } = appDataContext;
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [formName, setFormName] = useState<string>('');
  const [formDomainClass, setFormDomainClass] = useState<any>({
    value: '',
    data: [
      {
        id: 1,
        name: 'Special Purpose Domains',
        value: 'Special Purpose Domains'
      },
      { id: 2, name: 'Interventions Domains', value: 'Interventions Domains' },
      { id: 3, name: 'Events Domains', value: 'Events Domains' },
      { id: 4, name: 'Findings Domains', value: 'Findings Domains' },
      {
        id: 5,
        name: 'Findings About Events or Interventions',
        value: 'Findings About Events or Interventions'
      },
      { id: 6, name: 'Custom Domain', value: 'Custom Domain' }
    ]
  });
  const [formDomainDesc, setFormDomainDesc] = useState<string>('');
  const [formDomainCode, setFormDomainCode] = useState<string>('');
  const [formAnnotations, setFormAnnotations] = useState<string>('');
  const [selectedFormId, setSelectedFormId] = useState<string>('');
  const [formlogForm, setFormlogForm] = useState<boolean>(false);
  const [specialForm, setSpecialForm] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  
  function a11yProps(index: number) {
    return {
      id: `form-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };
  const handleFormSubmit = () => {
    if (formName) {
      AppSMService.axios.post(NETWORK_CONSTANTS.END_POINTS.FORMS,{
        data: {
          name: formName,
          studyId: study?.id,
          projectID: project?.id,
          domainClass: formDomainClass.value,
          domainDescription: formDomainDesc,
          domainCode: formDomainCode,
          annotations: formAnnotations,
          logForm: formlogForm,
          specialForm: specialForm
        }
      })
        .then((res: any) => {
          if (res?.data) {
            navigate(`/clinicalApp/forms/${res.data.id}`);
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormName(e.target.value);
  };
  const handleSelectedFormItem = (formId: string) => {
    setSelectedFormId(formId);
    //setOpen(false);
  };
  const getFormDetails = (formId: string): Promise<any> => {
    return AppSMService.axios
      .get(`forms/${formId}`)
      .then((res) => {
        return res?.data || null;
      })
      .catch(() => {
        return null;
      });
  };
  const createNewForm = async (formData: any): Promise<any> => {
    const { name, studyId, annotations, domainClass, domainCode, domainName } = formData;
    const payload = {
      name: name,
      studyId: studyId,
      annotations: annotations || null,
      domainClass: domainClass || null,
      domainCode: domainCode || null,
      domainName: domainName || null,
      logForm: formlogForm,
      specialForm: specialForm
    };
    return AppSMService.axios
      .post(`forms`, payload)
      .then((res) => {
        return res?.data || null;
      })
      .catch(() => {
        return null;
      });
  };
  const createFormField = async (field: any, formId: string): Promise<any> => {
    return AppSMService.axios
      .post('fields', { formId: formId, definition: field })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(`Error in creating new field - ${e}`);
        return null;
      });
  };
  const handleExistingFormSubmit = async () => {
    if (!selectedFormId) {
      console.log(`Form not selected`);
      return null;
    }
    const formDetails = await getFormDetails(selectedFormId);
    if (formDetails) {
      const newFormData = await createNewForm(formDetails);
      if (newFormData && newFormData.id) {
        formDetails.fields &&
          formDetails.fields.length > 0 &&
          formDetails.fields.forEach(async (formField: any) => {
            await createFormField(formField.definition, newFormData.id);
          });
        navigate(`/clinicalApp/forms/${newFormData.id}`);
      } else {
        console.log(`Unable to create new Form`);
      }
    } else {
      console.log(`Form details not found`);
    }
  };
  const handleOpen = () => {
    console.log('handleOpen',open);
    setOpen(true);
  };
  const handleFileFormatChange = (event: SelectChangeEvent) => {
    setFormDomainClass({
      ...formDomainClass,
      value: event.target.value as string
    });
  };
  const RenderDomainClass = (
    <Box sx={{ minWidth: 120, width: '50%' }}>
      <FormControl fullWidth>
        <InputLabel id="domain-class-label">Domain Class</InputLabel>
        <Select
          labelId="domain-class-label"
          id="domain-class"
          value={formDomainClass.value}
          label="Domain Class"
          onChange={handleFileFormatChange}>
          {formDomainClass.data.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
  return (
    <Box
      sx={{
        position: 'absolute' as const,
        top: '48%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24
      }}>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Create Form
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          value={selectedTabIndex}
          onChange={handleChange}>
          <Tab label="Custom Form" {...a11yProps(0)} />
          <Tab label="Pull From DB" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTabIndex} index={0}>
        <FormControl
          style={{
            width: '100%',
            height: '600px',
            rowGap: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <TextField
            label="Form Name"
            style={{ width: '50%' }}
            required={true}
            type="text"
            variant="outlined"
            value={formName}
            onChange={handleOnChange}
          />
          {RenderDomainClass}
          <TextField
            label="Domain Description"
            style={{ width: '50%' }}
            type="text"
            variant="outlined"
            value={formDomainDesc}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormDomainDesc(e.target.value)}
          />
          <TextField
            label="Domain Code"
            style={{ width: '50%' }}
            type="text"
            variant="outlined"
            value={formDomainCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormDomainCode(e.target.value)}
          />
          <TextField
            label="Annotations"
            style={{ width: '50%', display: 'none' }}
            type="text"
            variant="outlined"
            value={formAnnotations}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormAnnotations(e.target.value)
            }
            hidden
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={formlogForm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormlogForm(e.target.checked)
                }
              />
            }
            label="Log Form"
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={specialForm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSpecialForm(e.target.checked)
                }
              />
            }
            label="Special Form"
          />
          <Button
            style={{ margin: '10px' }}
            variant="contained"
            onClick={handleFormSubmit}
            disabled={formName === ''}>
            Submit
          </Button>
        </FormControl>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <div
          style={{
            width: '100%',
            height: '500px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
          <h3>Select a form</h3>
          <div
            style={{
              height: '100%',
              overflow: 'auto',
              border: '1px #ccc solid',
              width: '400px'
            }}>
            <FormsList
              handleSelectedItem={handleSelectedFormItem}
              selectedFormId={selectedFormId}
            />

            {/* <Select sx={{width: '300px'}} label="Select a form" labelId="select-form-label" value={selectedFormId} open={open} onClick={handleOpen}>
                            <FormsList handleSelectedItem={(e) => console.log(e)}/>
                        </Select> */}
          </div>
          <Button
            style={{ margin: '10px' }}
            variant="contained"
            onClick={handleExistingFormSubmit}
            disabled={selectedFormId === ''}>
            Submit
          </Button>
        </div>
      </CustomTabPanel>
    </Box>
  );
};
export default CreateForm;
