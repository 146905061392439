import { combineReducers, configureStore } from "@reduxjs/toolkit";
import  globalReducer from "./slices/globalSlice"
import { STORE_CONFIG } from "./storeConstant";
import  thunk  from 'redux-thunk';

const rootReducer = combineReducers({  [STORE_CONFIG.reducers.appData]: globalReducer });

export const store:any = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
  },
});

// Get the type of our store variable
export type AppStore = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch']