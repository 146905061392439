import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';

import {
  Grid,
  Paper,
  Box,
  Typography,
  Select,
  Button,
  Stack,
  TextField,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ClearAllOutlined, SaveOutlined } from '@mui/icons-material';
import Breadcrumb from '../../organisms/Breadcrumb/Breadcrumb';
import { AppUMService as AppService } from '../../../network/useAxios';
import Alert from '../../molecules/Alert/Alert';
import { OnBoardingFormState } from './types';
import {
  BREAD_CRUMBS,
  ROUTING_CONSTANTS,
  TEXT_CONSTANTS,
  useDataValidation,
  USER_TYPE,
  USER_TYPE_CONFIG
} from '../../../utils';
import { InitialStateTypes } from '../../../store/slices/globalSlice';
import { STORE_CONFIG } from '../../../store/storeConstant';
import { HTTP_STATUS_CODE, NETWORK_CONSTANTS } from '../../../network/networkConstants';
import { useAppSelector } from '../../../hooks';
import { AxiosError } from 'axios';

function OnboardingFrom() {
  const navigate = useNavigate();
  const location = useLocation();
  const { ONBOARDING } = BREAD_CRUMBS;
  const { DASHBOARD, USERS: RUSERS, CLIENT, CLIENT_USER, SPONSORS } = ROUTING_CONSTANTS;
  const { ACCESS_MANAGEMENTS, USERS } = NETWORK_CONSTANTS.END_POINTS;
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const { user } = appDataContext;
  const { checkIsEmpty } = useDataValidation();
  const userData: any = { name: '', type: '', path: '' };

  const [onBoardingFormState, setOnBoardingForm] = useState<OnBoardingFormState>({
    openAlert: false,
    openAlertMsg: '',
    openAlertStatus: '',
    fields: [
      {
        firstname: '',
        errfirstname: false,
        middlename: '',
        lastname: '',
        email: '',
        errEmail: '',
        phoneNumber: '',
        username: '',
        errUserName: false,
        password: '',
        errPassword: false,
        address: '',
        city: '',
        country: '',
        pincode: '',
        requestuser: '',
        acccessrights: '',
        erroracccessrights: false,
        status: 'Active',
        website: ''
      }
    ],
    accessRights: [],
    userList: [],
    submitCheck: '',
    btnloading: false
  });
  const { accessRights, btnloading, fields, openAlert, openAlertMsg, openAlertStatus } =
    onBoardingFormState;
  const pathname = location.pathname;
  if (pathname.indexOf(`${RUSERS}/form`) >= 0) {
    userData.name = USER_TYPE_CONFIG[USER_TYPE.USER].name;
    userData.type = USER_TYPE_CONFIG[USER_TYPE.USER].type;
    userData.path = RUSERS;
  } else if (pathname.indexOf(`${CLIENT}/form`) >= 0) {
    userData.name = USER_TYPE_CONFIG[USER_TYPE.CLIENT].name;
    userData.type = USER_TYPE_CONFIG[USER_TYPE.CLIENT].type;
    userData.path = CLIENT;
  } else if (pathname.indexOf(`${CLIENT_USER}/form`) >= 0) {
    userData.name = USER_TYPE_CONFIG[USER_TYPE.USER].name;
    userData.type = USER_TYPE_CONFIG[USER_TYPE.CLIENT_USER].type;
    userData.path = CLIENT_USER;
  } else if (pathname.indexOf(`${SPONSORS}/form`) >= 0) {
    userData.name = USER_TYPE_CONFIG[USER_TYPE.SPONSOR].name;
    userData.type = USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type;
    userData.path = SPONSORS;
  }
  const breadCrumbList = [
    { title: BREAD_CRUMBS.HOME, link: DASHBOARD },
    { title: ONBOARDING, link: '' },
    { title: userData.name, link: '' }
  ];

  const { formID } = useParams();

  React.useEffect(() => {
    loadAccessMgtData();
    loadDataEntry();
    if (formID !== '' && typeof formID !== 'undefined') {
      loadUserDetails();
    }
  }, []);
  const updateState = (data: any) => {
    setOnBoardingForm((prevState: OnBoardingFormState) => {
      const _updatedState: any = { ...prevState };
      Object.keys(data).forEach(function (key: string) {
        _updatedState[key] = data[key];
      });
      return _updatedState;
    });
  };
  const loadAccessMgtData = () => {
    AppService.axios
      .get(ACCESS_MANAGEMENTS)
      .then(({ data, status }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          updateState({ accessRights: data || [] });
        }
      })
      .catch((e) => {
        console.log(`Error in AccessManagement - ${e}`);
      });
  };
  const loadUserDetails = () => {
    AppService.axios
      .get(`${USERS}${formID}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          const fieldsDetails = [
            {
              firstname: data.firstName,
              errfirstname: false,
              middlename: data.middlename !== null ? data.middlename : '',
              lastname: data.lastName !== null ? data.lastName : '',
              email: data.email,
              errEmail: '',
              phoneNumber: data.phoneNumber !== null ? data.phoneNumber : '',
              username: data.username,
              errUserName: false,
              password: '',
              errPassword: false,
              address: data.address !== null ? data.address : '',
              city: data.city !== null ? data.city : '',
              country: data.country !== null ? data.country : '',
              pincode: data.pinCode !== null ? data.pinCode : '',
              requestuser: '',
              acccessrights: data.authorities.length > 0 ? data.authorities[0].name : '',
              erroracccessrights: data.authorities.length > 0 ? false : true,
              status: data.status !== null ? data.status : 'Active',
              website: data.website !== null ? data.website : '',
              userType: userData.type
            }
          ];
          updateState({ fields: fieldsDetails, submitCheck: 'Yes' });
        }
      })
      .catch((e) => {
        console.log(`Error in User Details - ${e}`);
      });
  };

  const loadDataEntry = () => {
    /* axios
      .get("http://localhost:3000/assets/onboardinguser.json")
      .then((res) => {
        setUserList(res.data);
      }); */
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    const name = event.target.name;
    if (name === 'lastname' && userData.type === USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type) {
      value = value.split(' ').join('');
    }
    updateValue(name, value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const name = event.target.name;
    updateValue(name, value);
  };
  const updateValue = (name: string, value: string) => {
    const firstname = name === 'firstname' ? value : fields[0].firstname;
    const errfirstname =
      name === 'firstname' ? (value === '' ? true : false) : fields[0].errfirstname;
    const middlename = name === 'middlename' ? value : fields[0].middlename;
    const lastname = name === 'lastname' ? value : fields[0].lastname;
    const email = name === 'email' ? value : fields[0].email;
    const errEmail =
      name === 'email' ? (value === '' ? 'This field is required' : '') : fields[0].errEmail;

    const phoneNumber = name === 'phoneNumber' ? value : fields[0].phoneNumber;
    const username = name === 'username' ? value : fields[0].username;
    const errUserName = name === 'username' ? (value === '' ? true : false) : fields[0].errUserName;

    const password = name === 'password' ? value : fields[0].password;
    const errPassword = name === 'password' ? (value === '' ? true : false) : fields[0].errPassword;

    const address = name === 'address' ? value : fields[0].address;
    const city = name === 'city' ? value : fields[0].city;
    const country = name === 'country' ? value : fields[0].country;
    const pincode = name === 'pincode' ? value : fields[0].pincode;
    const requestuser = name === 'requestuser' ? value : fields[0].requestuser;
    const acccessrights = name === 'acccessrights' ? value : fields[0].acccessrights;
    const erroracccessrights =
      name === 'acccessrights' ? (value === '' ? true : false) : fields[0].erroracccessrights;

    const website = name === 'website' ? value : fields[0].website;

    const fieldsDetails = [
      {
        firstname: firstname,
        errfirstname: errfirstname,
        middlename: middlename,
        lastname: lastname,
        email: email,
        errEmail: errEmail,
        phoneNumber: phoneNumber,
        username: username,
        errUserName: errUserName,
        password: password,
        errPassword: errPassword,
        address: address,
        city: city,
        country: country,
        pincode: pincode,
        requestuser: requestuser,
        acccessrights: acccessrights,
        erroracccessrights: erroracccessrights,
        status: fields[0].status,
        userType: userData.type,
        website: website
      }
    ];
    updateState({ fields: fieldsDetails, submitCheck: 'No' });
  };

  const saveChangesPreCheckValidation = () => {
    const fieldsList = fields;
    let isValidated = true;
    if (fieldsList[0].firstname === '') {
      fieldsList[0].errfirstname = true;
      isValidated = false;
    }
    if (fieldsList[0].email === '' && userData.type !== 'sponsor') {
      fieldsList[0].errEmail = 'This field is required';
      isValidated = false;
    } else {
      if (userData.type !== 'sponsor') {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!fieldsList[0].email.match(validRegex)) {
          isValidated = false;
          fieldsList[0].errEmail = 'Invalid email address!';
        }
      }
    }
    if (fieldsList[0].username === '' && userData.type !== 'sponsor') {
      isValidated = false;
      fieldsList[0].errUserName = true;
    }
    /*  if (fieldsList[0].password === "" && formID === "" && type !== "sponsor") {
      error++;
      fieldsList[0].errPassword = true;
    } */
    if (
      fieldsList[0].acccessrights.length === 0 &&
      userData.type !== 'client' &&
      userData.type !== 'sponsor'
    ) {
      isValidated = false;
      fieldsList[0].erroracccessrights = true;
    }
    updateState({ fields: fieldsList, submitCheck: 'Yes' });
    return isValidated;
  };

  const saveChanges = async () => {
    const fieldsList = fields;

    if (saveChangesPreCheckValidation()) {
      let client_User_Name: any = '';
      if (userData.type === CLIENT_USER) {
        client_User_Name = user?.username;
      } else if (userData.type === CLIENT) {
        client_User_Name = fieldsList[0].username;
      }

      const postData = {
        username: fieldsList[0].username,
        /* password: fieldsList[0].password, */
        firstName: fieldsList[0].firstname,
        middlename: fieldsList[0].middlename,
        lastName: fieldsList[0].lastname,
        image: '',
        phoneNumber: fieldsList[0].phoneNumber,
        address: fieldsList[0].address,
        city: fieldsList[0].city,
        country: fieldsList[0].country,
        pinCode: fieldsList[0].pincode,
        email: fieldsList[0].email,
        website: fieldsList[0].website,
        status: 'Active',
        userType: userData.type,
        authorities: [
          {
            name: fieldsList[0].acccessrights
          }
        ],
        erroracccessrights: fieldsList[0].erroracccessrights,
        clientUserName: client_User_Name
      };
      let response: any = { status: HTTP_STATUS_CODE.OK, data: {} };

      if (!checkIsEmpty(formID)) {
        response =await AppService.axios.put(`${USERS}${formID}`, postData).catch((e:AxiosError) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const {Code,Message}:any=e?.response?.data;
          updateState({
            openAlert: true,
            openAlertStatus: TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.ERROR,
            openAlertMsg:  checkIsEmpty(Code)? TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.SOMETHING_WENT_WRONG:Message,
            btnloading: false
          });
          console.log(`Error in saveChanges`,response);
        });
      } else {
        response = await AppService.axios.post(`${USERS}`, postData).catch((e:AxiosError) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const {Code,Message}:any=e?.response?.data;
          updateState({
            openAlert: true,
            openAlertStatus: TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.ERROR,
            openAlertMsg:  checkIsEmpty(Code)? TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.SOMETHING_WENT_WRONG:Message,
            btnloading: false
          });
          console.log(`Error in saveChanges`,response);
        });
      }
      if (response?.status === HTTP_STATUS_CODE.OK) {
        updateState({
          openAlert: true,
          openAlertStatus: TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.SUCCESS,
          openAlertMsg: !checkIsEmpty(formID)
            ? TEXT_CONSTANTS.VALIDATION_TEXT.ONBOARDING_PAGE.USER_UPDATE_SUCCESS
            : TEXT_CONSTANTS.VALIDATION_TEXT.ONBOARDING_PAGE.USER_CREATE_SUCCESS,
          btnloading: false
        });
        setTimeout(() => {
          navigate(userData.path);
        }, 2000);
        AutoCloseAlert();
      }
    }
  };
  const handleCloseAlert = () => {
    updateState({
      openAlert: false,
      openAlertStatus: '',
      openAlertMsg: ''
    });

    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      updateState({
        openAlert: false,
        openAlertStatus: '',
        openAlertMsg: ''
      });
    }, 5000);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Grid container>
          <Breadcrumb breadCrumbList={breadCrumbList} />
          <Paper sx={{ width: 1, padding: 5 }}>
            <Typography variant="h6" gutterBottom>
              {formID !== '' && typeof formID !== 'undefined' ? 'Edit' : 'Add'} {userData.name}
            </Typography>
            <Grid item xs={12}>
              <TextField
                label={
                  userData.type === USER_TYPE_CONFIG[USER_TYPE.CLIENT].type
                    ? 'Company Name*'
                    : userData.type === USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type
                      ? 'Name*'
                      : 'First Name*'
                }
                variant="standard"
                name="firstname"
                sx={{ marginRight: '20px' }}
                value={fields[0].firstname}
                onChange={handleTextFieldChange}
                error={fields[0].errfirstname}
                helperText={fields[0].errfirstname === true && 'This field is required'}
              />
              {userData.type !== USER_TYPE_CONFIG[USER_TYPE.CLIENT].type &&
                userData.type !== USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type && (
                  <TextField
                    label="Middle Name"
                    variant="standard"
                    name="middlename"
                    sx={{ marginRight: '20px' }}
                    value={fields[0].middlename}
                    onChange={handleTextFieldChange}
                  />
                )}
              {userData.type !== USER_TYPE_CONFIG[USER_TYPE.CLIENT].type && (
                <TextField
                  label={
                    userData.type === USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type
                      ? 'Short Name'
                      : 'Last Name'
                  }
                  variant="standard"
                  name="lastname"
                  sx={{ marginRight: '20px' }}
                  value={fields[0].lastname}
                  onChange={handleTextFieldChange}
                />
              )}

              {userData.type !== USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type && (
                <TextField
                  label="Email*"
                  name="email"
                  value={fields[0].email}
                  onChange={handleTextFieldChange}
                  variant="standard"
                  sx={{ marginRight: '20px' }}
                  error={fields[0].errEmail !== '' ? true : false}
                  helperText={fields[0].errEmail}
                />
              )}

              <TextField
                label="Phone"
                name="phoneNumber"
                value={fields[0].phoneNumber}
                onChange={handleTextFieldChange}
                variant="standard"
                sx={{ marginRight: '20px' }}
              />
              {userData.type === USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type && (
                <TextField
                  label="Website"
                  name="website"
                  value={fields[0].website}
                  onChange={handleTextFieldChange}
                  variant="standard"
                  sx={{ marginRight: '20px' }}
                />
              )}
              {userData.type !== USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type && (
                <>
                  <TextField
                    label="User Name*"
                    variant="standard"
                    name="username"
                    sx={{ marginRight: '20px' }}
                    value={fields[0].username}
                    onChange={handleTextFieldChange}
                    error={fields[0].errUserName}
                    helperText={
                      fields[0].errUserName && TEXT_CONSTANTS.VALIDATION_TEXT.COMMON.FIELD_REQUIRED
                    }
                  />
                  {/* <TextField
                    label={formID === "" ? "Password*" : "Password"}
                    name="password"
                    value={fields[0].password}
                    onChange={handleTextFieldChange}
                    variant="standard"
                    sx={{ marginRight: "20px" }}
                    error={formID === "" && fields[0].errPassword}
                    helperText={
                      fields[0].errPassword &&
                      formID === "" &&
                      "This field is required"
                    }
                    type="password"
                  /> */}
                </>
              )}
            </Grid>
            <Typography variant="h6" gutterBottom mt={3}></Typography>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="standard"
                name="address"
                sx={{ marginRight: '20px' }}
                value={fields[0].address}
                onChange={handleTextFieldChange}
              />
              <TextField
                label="City"
                variant="standard"
                name="city"
                sx={{ marginRight: '20px' }}
                value={fields[0].city}
                onChange={handleTextFieldChange}
              />
              <TextField
                label="Country"
                name="country"
                sx={{ marginRight: '20px' }}
                value={fields[0].country}
                onChange={handleTextFieldChange}
                variant="standard"
              />
              <TextField
                label="Pincode"
                name="pincode"
                value={fields[0].pincode}
                onChange={handleTextFieldChange}
                variant="standard"
              />
            </Grid>
            {
              /* (formID === "" || typeof formID === undefined) && ( */
              <>
                {/* <Typography variant="h6" gutterBottom mt={3}>
                  Send Creation Request
                </Typography>
                <Grid item xs={8}>
                  <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                    <InputLabel id="demo-select-small-label">User</InputLabel>
                    <Select
                      label="User"
                      name="requestuser"
                      onChange={handleChange}
                    >
                      {userList.length > 0 &&
                        userList.map((option: any) => (
                          <MenuItem value={option.id}>{option.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </>
              /* ) */
            }
            {userData.type !== USER_TYPE_CONFIG[USER_TYPE.CLIENT].type &&
              userData.type !== USER_TYPE_CONFIG[USER_TYPE.SPONSOR].type && (
                <>
                  <Typography variant="h6" gutterBottom mt={3}>
                    Authorities
                  </Typography>
                  <Grid item xs={8}>
                    <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                      <InputLabel id="demo-select-small-label">Select Authorities</InputLabel>
                      <Select
                        label="Select Authorities"
                        name="acccessrights"
                        onChange={handleChange}
                        value={fields[0].acccessrights}
                        error={fields[0].erroracccessrights}>
                        {accessRights.length > 0 &&
                          accessRights.map((option: any, index: number) => (
                            <MenuItem value={option.name} key={index}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {fields[0].erroracccessrights && (
                        <FormHelperText error={true}>This field is required</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}
            <Box>
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                spacing={2}
                pt={4}
                pb={2}>
                <Link to={userData.path}>
                  <Button variant="outlined" endIcon={<ClearAllOutlined />}>
                    Cancel
                  </Button>
                </Link>
                <LoadingButton
                  onClick={saveChanges}
                  loading={btnloading}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                  variant="contained">
                  {formID !== '' && typeof formID !== 'undefined' ? 'Update' : 'Add'}{' '}
                  {userData.name}
                </LoadingButton>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Box>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </Box>
  );
}
export default OnboardingFrom;
