import { IUser } from "./User";

export interface IOption {
    label?: string;
    value?: string;
    id?: string;
    selected?: boolean;
}
export enum FORMELEMENTTYPE{
    TEXT = "text",
    TEXTAREA = "textarea",
    SELECT = "select",
    RADIOGROUP = "radio-group",
    NUMBER = "number",
    CHECKBOXGROUP = "checkbox-group",
    DATE = "date",
    PARAGRAPH = "paragraph",
    TIME = "time"
}
export enum FORMELEMENTSTATUS{
    APPROVED = "approved",
    REJECTED = "rejected"
}
export enum FORMFIELDSTATUS{
    APPROVED = "Approved",
    REJECTED = "Rejected"
}
export interface IComment {
    comments?: string;
    text?: string;
    timestamp?: string;
    createdOn?: string;
    status?: string;
    commentedBy?: string;
    user?: IUser;
}
export interface IReviewerComments {
    //reviewer: IUser;
    name: string;
    type?: string;
    comments: IComment[];
}
export interface IFormDefinition{
    label: string;
    name: string;
    type: FORMELEMENTTYPE;
    values?:IOption[];
    units?:any
    unitLabel?:string;
    unitName?:string;
    rows?:any;
    required?:boolean;
    helperText?:string;
}
export interface IFormElement{
    id?: string;
    // label: string;
    // name: string;
    // type: FORMELEMENTTYPE;
    subtype?: string;
    className?: string;
    status?: FORMFIELDSTATUS;
    reviewComments?:IReviewerComments[];
    definition:IFormDefinition;
    formId?: string;
}
export const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

