import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
  Box,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader
} from '@mui/material';
import { TAB_NAME, TabPanelProps } from './index';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import { useRef } from 'react';
import AlertMessage from '../../molecules/Alert/Alert';
import Loader from '../../molecules/Loader/Loader';
import { AppUMService } from '../../../network/useAxios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
export default function ExtractData({ meta: { show, name, code }, showModal }: any) {
  const [stateObj, setStateObject] = React.useState({
    open: show,
    tabIndex: 0,
    fileFormat: {
      value: '',
      data: [{ id: 1, name: 'CSV', value: 'csv' }]
    },
    frequency: {
      value: '',
      data: [
        { id: 1, name: '30 min', value: '30' },
        { id: 1, name: '60 min', value: '60' },
        { id: 1, name: '120 min', value: '120' }
      ]
    },
    interval: {
      value: '',
      data: [
        { id: 1, name: 'Daily', value: 'daily' },
        { id: 1, name: 'Weekly', value: 'weekly' },
        { id: 1, name: 'Monthly', value: 'monthly' }
      ]
    },
    dataformat: {
      value: '',
      data: [{ id: 1, name: 'CSV', value: 'csv' }]
    },
    scheduleData: [],
    email: '',
    password: '',
    snackbar: {
      show: false,
      isError: false,
      message: ''
    },
    loader: {
      show: false
    }
  });
  const [time, setTime] = React.useState<Dayjs | null>(dayjs(new Date().toISOString()));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const timeRef = useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    getSchedule();
  }, []);

  React.useEffect(() => {
    setStateObject({ ...stateObj, open: show });
  }, [show]);

  function getSchedule() {
    AppUMService.axios.get('/datatransition/schedules').then(({ status, data }) => {
      if (status === 200) {
        setStateObject({
          ...stateObj,
          scheduleData: data
        });
      }
    });
  }
  /*action : cancel Freeze and Unfreeze  */
  function updateSchedule(id: string, action: string) {
    AppUMService.axios
      .put(`/datatransition/schedule/jobs/${id}?action=${action}`)
      .then(({ status }) => {
        if (status === 200) {
          getSchedule();
        }
      });
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setStateObject({ ...stateObj, tabIndex: newValue });
  };
  const handleFileFormatChange = (event: SelectChangeEvent) => {
    setStateObject({
      ...stateObj,
      fileFormat: {
        ...stateObj.fileFormat,
        value: event.target.value as string
      }
    });
  };
  const handleDataFormatChange = (event: SelectChangeEvent) => {
    setStateObject({
      ...stateObj,
      dataformat: {
        ...stateObj.fileFormat,
        value: event.target.value as string
      }
    });
  };
  const handleFrequencyChange = (event: SelectChangeEvent) => {
    setStateObject({
      ...stateObj,
      frequency: { ...stateObj.frequency, value: event.target.value as string }
    });
  };
  const handleIntervalChange = (event: SelectChangeEvent) => {
    setStateObject({
      ...stateObj,
      interval: { ...stateObj.interval, value: event.target.value as string }
    });
  };
  const handleErrorMessageUI = (message: string, isError: boolean, showSnackbar: boolean) => {
    setStateObject({
      ...stateObj,
      snackbar: {
        ...stateObj.snackbar,
        message: message,
        isError: isError,
        show: showSnackbar
      }
    });
  };
  const showLoader = (showLoader: boolean) => {
    setStateObject({
      ...stateObj,
      loader: {
        ...stateObj.loader,
        show: showLoader
      }
    });
  };
  const handleClose = () => {
    setStateObject({ ...stateObj, open: false });
    showModal(code, false);
  };
  const onTextFieldChange = (event: any, key: string) => {
    setStateObject({
      ...stateObj,
      [key]: event.target.value as string
    });
  };

  const onHandleSubmit = () => {
    if (stateObj.tabIndex === 0) {
      showLoader(true);
      console.log('Data Extraction Done');
      getFileToDownload('/datatransition/export')
        .then((response:any) => {
          console.log('response', response);
          handleErrorMessageUI('Data Extraction completed successfully', false, true);

          const type = response.headers['content-type'];
          const blob = new Blob([response.data], { type: type });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'file.csv';
          link.click();
        })
        .finally(() => {
          setTimeout(() => {
            showLoader(false);
            handleClose();
          }, 2000);
        });
    } else {
      const requestData = {
        interval: stateObj.interval.value,
        time: timeRef.current?.value,
        emailIds: [stateObj.email],
        password: stateObj.password,
        dataFormat: stateObj.dataformat.value,
        status: 'active'
      };
      showLoader(true);
      AppUMService.axios
        .post('/datatransition/schedule', requestData)
        .then((response) => {
          console.log('Data Schedule Done', response);

          handleErrorMessageUI('Schedule added successfully', false, true);
        })
        .finally(() => {
          setTimeout(() => {
            showLoader(false);
            handleClose();
          }, 2000);
        });
    }
  };
  const schShowPopOverUI = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const schPopOverHandleClose = () => {
    setAnchorEl(null);
  };
  const schPopOverUI = (sId: string) => (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={schPopOverHandleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      <Typography sx={{ p: 1 }}>
        <Button variant="outlined" onClick={() => updateSchedule(sId, 'freeze')}>
          Freeze
        </Button>
      </Typography>
      <Typography sx={{ p: 1 }}>
        <Button variant="outlined" onClick={() => updateSchedule(sId, 'cancel')}>
          Cancel
        </Button>
      </Typography>
    </Popover>
  );

  const ScheduledCard = ({  time, interval, status, emailIds, jobId }: any, index: number) => {
    return (
      <Card variant="outlined" style={{ padding: '5px', marginTop: '5px' }}>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={schShowPopOverUI}>
              <MoreVertIcon />
            </IconButton>
          }
          title={`Schedule ${index + 1}`}
          subheader=""
          style={{ padding: '0px 0px 0px 6px', fontSize: '0.8rem' }}>
          {' '}
        </CardHeader>
        <CardContent style={{ padding: '6px' }}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="body2" component="div">
                <Button
                  variant="outlined"
                  style={{
                    fontSize: 'xx-small',
                    fontWeight: 'bold',
                    color: status.toLowerCase() === 'active' ? 'green' : 'orange'
                  }}>
                  {status}
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ marginTop: '10px' }}>
              <Typography variant="body2" component="div">
                {time}
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ marginTop: '10px' }}>
              <Typography variant="body2" component="div">
                {interval}
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ marginTop: '10px' }}>
              <Typography variant="body2" component="div">
                {emailIds.toString()}
              </Typography>
            </Grid>
          </Grid>

          {schPopOverUI(jobId)}
        </CardContent>
      </Card>
    );
  };
  const renderTab1 = (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="file-format-label">File format</InputLabel>
        <Select
          labelId="file-format-label"
          id="file-format"
          value={stateObj.fileFormat.value}
          label="File format"
          onChange={handleFileFormatChange}>
          {stateObj.fileFormat.data.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
  const renderTab2 = (
    <Box sx={{ minWidth: 120 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="interval-label">Interval</InputLabel>
            <Select
              labelId="interval-label"
              id="interval"
              value={stateObj.interval.value}
              label="Interval"
              onChange={handleIntervalChange}>
              {stateObj.interval.data.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="frequency-label">Frequency</InputLabel>
            <Select
              labelId="frequency-label"
              id="frequency"
              value={stateObj.frequency.value}
              label="Frequency"
              onChange={handleFrequencyChange}>
              {stateObj.frequency.data.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Time"
              value={time}
              onChange={(newValue) => setTime(newValue)}
              format="HH:mm"
              inputRef={timeRef}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="text"
            value={stateObj.email}
            style={{ width: '100%' }}
            label="Email"
            variant="outlined"
            autoFocus
            onChange={(e) => onTextFieldChange(e, 'email')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="password"
            value={stateObj.password}
            style={{ width: '100%' }}
            label="Password"
            variant="outlined"
            onChange={(e) => onTextFieldChange(e, 'password')}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="data-format-label">Data format</InputLabel>
            <Select
              labelId="data-format-label"
              id="data-format"
              value={stateObj.dataformat.value}
              label="Data format"
              onChange={handleDataFormatChange}>
              {stateObj.dataformat.data.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {stateObj.scheduleData.map((item, index) => {
            return <>{ScheduledCard(item, index)}</>;
          })}
        </Grid>
      </Grid>
    </Box>
  );

  function getFileToDownload(apiUrl: string) {
    return AppUMService.axios.post(apiUrl, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={stateObj.open}
        fullWidth={true}
        maxWidth={'md'}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={stateObj.tabIndex}
              onChange={handleTabChange}
              aria-label="basic tabs example">
              <Tab label={TAB_NAME.OND} {...a11yProps(0)} />
              <Tab label={TAB_NAME.SCH} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={stateObj.tabIndex} index={0}>
            {renderTab1}
          </CustomTabPanel>
          <CustomTabPanel value={stateObj.tabIndex} index={1}>
            {renderTab2}
          </CustomTabPanel>
          {stateObj.loader.show && (
            <Loader open={stateObj.loader.show} handleClose={() => showLoader(false)} />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onHandleSubmit}>
            {stateObj.tabIndex === 0 ? 'Extract' : 'Scheduled'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {stateObj.snackbar.show && (
        <AlertMessage
          openAlert={stateObj.snackbar.show}
          openAlertMsg={stateObj.snackbar.message}
          openAlertStatus={stateObj.snackbar.isError ? 'error' : 'success'}
          handleCloseAlert={() => handleErrorMessageUI('', false, false)}
        />
      )}
    </React.Fragment>
  );
}
