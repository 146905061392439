
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import FormContext, { sampleForm } from "../../../contexts/form.Context";
import { Box, Button, Container, Modal, Stack } from "@mui/material";
import { SaveOutlined, PublishOutlined } from "@mui/icons-material";
import { GridDeleteIcon } from "@mui/x-data-grid";
import {
  FORMSTATUS,
  IClinicalForm,
  IFieldReview,
} from "../../../models/ClinicalForm";
import "./FormBuilder.css";
import ReviewerComments from "../ReviewerComments/ReviewerComments";
import { AppSMService } from '../../../network/useAxios';
import { FORMFIELDSTATUS } from "../../../models/Common";
import ConditionalFieldLink from "../ConditionalFieldLink/ConditionalFieldLink";
import { useAppSelector } from "../../../hooks";
import { InitialStateTypes } from "../../../store/slices/globalSlice";
import { STORE_CONFIG } from "../../../store/storeConstant";
import { useParams } from "react-router-dom";
window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");

interface IFormProps {
  form?: IClinicalForm | null | undefined;
  handleFormData?: (formObj: any) => void;
  formFields?: any;
  handlePublishForm?: (formObj: any) => void;
  formStatus?: string;
  fieldChildrenIds?: any;
  getFormDetails?: (formId: string) => void;
  formId?: string;
}
const FormBuilder = (props: IFormProps) => {
  const {
    form,
    handleFormData,
    formFields,
    handlePublishForm,
    fieldChildrenIds,
    getFormDetails,
    formId,
    formStatus,
  } = { ...props };
  const params = useParams();
  const appDataContext: InitialStateTypes = useAppSelector(
    (state) => state[STORE_CONFIG.reducers.appData]
  );
  const {user } = appDataContext;
  const [formTabDetails, setFormTabDetails] = useState(sampleForm.formTabs);
  const [formReviewDetails, setFormReviewDetails] = useState<IFieldReview[]>(
    []
  );
  const [stateObj, setStateObject] = React.useState({
    currentLinkId: "",
    isReviewCommentsOpen: false,
    isConditionalFieldsOpen: false,
    selectedFieldId: "",
    snackbar: {
      show: false,
      isError: false,
      message: "",
    },
    currentOption: "",
  });
  const formObjRef = useRef<any>(null);
  const fb = useRef<HTMLDivElement>(null);
  const userAttrs: any = {};
  let formObj: any;
  const fields = [
    "checkbox-group",
    "date",
    "number",
    "radio-group",
    "select",
    "text",
    "textarea",
  ];
  const newAttributes = {
    units: {
      type: "text",
      label: "Units",
      value: "",
    },
    unitLabel: {
      type: "text",
      label: "Unit Label",
      value: "",
    },
    unitName: {
      type: "text",
      label: "Unit Name",
      value: "",
    },
    textName: {
      type: "text",
      label: "Text Name",
      value: "",
    },
  };
  let formData: any = [];
  const getFieldReviewData = (
    fieldId: string,
    formId: string
  ): IFieldReview | null | undefined => {
    if (formReviewDetails && formReviewDetails.length > 0) {
      const reviewedItem = formReviewDetails.find((item: IFieldReview) => {
        return (
          item.reviewedBy === user?.username &&
          item.fieldId === fieldId &&
          item.formId === formId
        );
      });
      return reviewedItem;
    }
    return null;
  };
  const saveForm = () => {
    const formFields = formObjRef.current?.actions?.getData();
    handleFormData?.(formFields);
  };
  const publishForm = () => {
    const formFields = formObjRef.current?.actions?.getData();
    handlePublishForm?.(formFields);
  };
  const clearForm = () => {
    formObjRef.current?.actions.clearFields();
  };
  const handleConditionalFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateObject({
      ...stateObj,
      isConditionalFieldsOpen: true,
      isReviewCommentsOpen: false,
      currentLinkId: e.currentTarget.id,
      currentOption: e.target.getAttribute("condition") || "",
    });
  };
  const handleQueryIconListener = (el: HTMLElement) => {
    let fieldId = el.id;
    fieldId = fieldId.replace("field-form-field-name-", "");
    fieldId = fieldId.replace("-preview", "");
    setStateObject({
      ...stateObj,
      selectedFieldId: fieldId ? fieldId : "",
      isReviewCommentsOpen: fieldId ? true : false,
    });
  };
  const clearQueryFieldListeners = () => {
    const queryEleList = document.getElementsByClassName("query-img");
    Array.prototype.forEach.call(queryEleList, (el: HTMLElement) => {
      el.removeEventListener("click", handleQueryIconListener.bind(this, el));
    });
    //$("#fb-editor").off("click", ".link-img", handleConditionalFields);
  };
  const attachQueryFieldListeners = () => {
    clearQueryFieldListeners();
    console.log("attachQueryFieldListeners called");
    // document.querySelectorAll('.form-field').forEach(element => element.addEventListener('click', (e: any) => {
    //   console.log("edit field", e);
    // }), false)
    $("li.form-field").each((index: number, element: HTMLElement) => {
      const fieldElement = $(element).find(".query-img");
      if (fieldElement && params.formId) {
        let fieldId = $(fieldElement).attr("id");
        fieldId = fieldId.replace("field-form-field-name-", "");
        fieldId = fieldId.replace("-preview", "");
        const fieldReviewStatus = getFieldReviewData(fieldId, params.formId);
        if (fieldReviewStatus) {
          const approvalStatus = document.createElement("div");
          approvalStatus.setAttribute(
            "class",
            fieldReviewStatus?.reviewStatus?.toLowerCase?.()
          );
          if (fieldReviewStatus.reviewStatus === FORMFIELDSTATUS.APPROVED) {
            approvalStatus.innerHTML = `<img title="${FORMFIELDSTATUS.APPROVED}" src="${process.env.PUBLIC_URL}/images/approved.svg" />`;
          } else if (
            fieldReviewStatus.reviewStatus === FORMFIELDSTATUS.REJECTED
          ) {
            approvalStatus.innerHTML = `<img title="${FORMFIELDSTATUS.REJECTED}" src="${process.env.PUBLIC_URL}/images/rejected.svg" />`;
          }
          element.appendChild(approvalStatus);
        }
      }
      //$(holderElement).after(approvalStatus);
    });
    const queryEleList = document.getElementsByClassName("query-img");
    Array.prototype.forEach.call(queryEleList, (el: HTMLElement) => {
      el.addEventListener("click", handleQueryIconListener.bind(this, el));
    });
  };

  const renderForm = async () => {
    fields.forEach(function (item) {
      if(item==="text")
      {
        userAttrs[item] = newAttributes;
      }     
    });    
    formData = formFields || {};
    try {
      formData = formFields || {};
    } catch (error) {
      console.log("error",error);
    }
    return new Promise((resolve, reject) => {
      try {
        const custom_fields = [
          {
            label: "Time",
            type: "text",
            subtype: "time",
            icon: "⏰", //html emoji
            step: "2",
          },
        ];
        const formOptions = {
          fields: custom_fields,
          subtypes: {
            text: ["time"],
          },
          formData,
          showActionButtons: false,
          typeUserAttrs: userAttrs,
          disableFields: [
            "autocomplete",
            "file",
            "header",
            "hidden",
            "starRating",
            "button",
            "paragraph",
          ],
          disabledAttrs: [
            "name",
            "className",
            "max",
            "step",
            "value",
            "inline",
            "access",
            "other",
            "unitName",
            "maxlength",
            "min",
            "subtype",
            "description"
          ],
          onOpenFieldEdit: (editPanel: any) => {
            const prevHolder = $(editPanel)
              .parent()
              .find(".prev-holder .query-img");
            if (prevHolder && prevHolder.length > 0) {
              const optionValues = $(editPanel).find(".option-value");
              Array.prototype.forEach.call(optionValues, (el: HTMLElement) => {
                const currentField = $(el);
                if (currentField.parent().find(".link-img").length === 0) {
                  let fieldId = currentField.attr("name");
                  fieldId = fieldId.replace("form-field-name-", "");
                  fieldId = fieldId.replace("-option", "");
                  const conditionalFieldIcon = $(
                    '<img class="link-img" title="Conditional fields"/>'
                  );
                  conditionalFieldIcon.attr(
                    "src",
                    `${process.env.PUBLIC_URL}/images/link.png`
                  );
                  conditionalFieldIcon.attr("id", `${fieldId}`);
                  conditionalFieldIcon.attr(
                    "condition",
                    `${currentField.val()}`
                  );
                  currentField.after(conditionalFieldIcon);
                }
              });
            }
          },
          layoutTemplates: {
            label: function (label: any, data: any) {
              const fieldNameIndex = data.id.indexOf("form-field-name-");
              let commentIcon = $('<img class="query-img" title="query"/>');
              if (fieldNameIndex === -1) {
                commentIcon = $("<span />");
              }
              commentIcon.attr(
                "src",
                `${process.env.PUBLIC_URL}/images/comment.png`
              );
              commentIcon.attr("id", `field-${data.id}`);
              //commentIcon.append(conditionalIcon);
              return $('<div class="query-icon" />')
                .attr("id", `query-${data.id}`)
                .append(commentIcon);
            },
          },
          // layoutTemplates: {
          //   default: function(field: any, label: any, help: any, data: any) {
          //     const commentsWrapper = $('<div/>')
          //     .addClass('query-icon')
          //     .attr('id', data.id);
          //     $(label).prepend(commentsWrapper);
          //     return $('<div/>').append(label, field, help);
          //   }
          // }
          // templates: {
          //   text: function(fieldData: any){
          //     return {
          //       field: `<span data-field-id="${fieldData.id}">icon</span>`,
          //       onRender: function(options: any) {
          //         console.log("options", options);
          //         console.log("test", fieldData);
          //       }
          //     };
          //   }
          // }
        };
       
        if (!formObjRef.current) {
          $(fb.current)
            .formBuilder(formOptions)
            .promise.then((formBuilder: any) => {
              formObjRef.current = formBuilder;
              formObjRef.current?.actions?.setData?.(formData);
              attachQueryFieldListeners();
              resolve("");
              formObjRef.current = formBuilder;              
            });
        } else {        
          formObjRef.current?.actions?.setData?.(formData);      
          attachQueryFieldListeners();
          resolve("");
          setFormData(formData);
        }
      } catch (error) {
        console.log(`Error in preparing form builder - ${error}`);
        reject();
      }
    });
  };
  function setFormData(formData:any)
  {
       formObjRef.current?.actions?.setData?.(formData);
    attachQueryFieldListeners();
  }
  const handleClose = () => {
    setStateObject({
      ...stateObj,
      isReviewCommentsOpen: false,
      isConditionalFieldsOpen: false,
    });
  };
  const loadFormReviewDetails = (formId: string) => {
    AppSMService.axios
      .get(`field-review?formId=${formId}`)
      .then((res: any) => {
        setFormReviewDetails(res.data);
      })
      .catch((e: any) => {
        console.log(`Error in fetching form Review Details - ${e}`);
      });
  };
  useEffect(() => {
    renderForm();
    params.formId && loadFormReviewDetails(params.formId);
    disableFormEditor();   
  }, [params.formId, formFields]);
  useEffect(() => {
    $("#fb-editor").on("click", ".link-img", handleConditionalFields);
    return () => {
      $("#fb-editor").off("click", ".link-img", handleConditionalFields);
    };
  }, []);
  function disableFormEditor()
  {
    const _disableFormEditor=formStatus ===FORMSTATUS.PUBLISHED;
    if(_disableFormEditor)
    {
      $("#fb-editor").addClass("noclick");
    }
    else{
      $("#fb-editor").removeClass("noclick");
    }
   return _disableFormEditor;
  }
  return (
    <>
      <FormContext.Provider value={formTabDetails}>
        <Container
          sx={{
            maxWidth: { xs: "100%", sm: "100%" },
            bgcolor: "background.paper",
          }}
        >
          <Box pt={2}>
            <div id="fb-editor" ref={fb} />
          </Box>
         {!disableFormEditor() && <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            pt={4}
            pb={2}
          >
            <Button
              variant="outlined"
              startIcon={<GridDeleteIcon />}
              onClick={clearForm}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              endIcon={<SaveOutlined />}
              onClick={saveForm}
              disabled={formObj?.actions?.getData?.().length === 0}
            >
              Save
            </Button>
            <Button
              variant="contained"
              endIcon={<PublishOutlined />}
              onClick={publishForm}
              disabled={
                formObj?.actions?.getData?.().length === 0 ||
                props.formStatus !== "SAVED"
              }
            >
              Publish
            </Button>
            <Modal
              open={stateObj.isReviewCommentsOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              onClose={handleClose}
            >
              <ReviewerComments
                formId={params.formId}
                studyId={form?.studyId}
                fieldId={stateObj.selectedFieldId}
                handleClose={handleClose}
                type={"form"}
              />
            </Modal>

            <ConditionalFieldLink
              formFields={formFields}
              show={stateObj.isConditionalFieldsOpen}
              handleClose={handleClose}
              currentLinkId={stateObj.currentLinkId}
              form={form}
              currentOption={stateObj.currentOption}
              fieldChildrenIds={fieldChildrenIds}
              getFormDetails={getFormDetails}
              formId={formId}
            />
          </Stack>
}
        </Container>
      </FormContext.Provider>
    </>
  );
};
export default FormBuilder;
